/* eslint-disable indent */
import { autoInjectable } from 'tsyringe';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import VrObject3D from '../Three/VrObject3D';
import { Block, Text } from 'three-mesh-ui';

import { Color, TextureLoader } from 'three';
import IntersectionContainer from '../Controllers/IntersectionContainer';

import IconScrollUpWhite from '@assets/icons/icon-name-scroll-up-white-full.png';
import IconScrollDownWhite from '@assets/icons/icon-name-scroll-down-white-full.png';
import IconScrollLeftWhite from '@assets/icons/icon-name-scroll-left-white-full.png';
import defaultCourseIcon from '@assets/icons/defaultCourseIcon.png';

import FontJSON from '@assets/fonts/Roboto-Regular-msdf.json';
import FontImage from '@assets/fonts/Roboto-Regular.png';

import Utils from '../../Utils/Utils';
import CourseView from './CourseView';
import { MaskLoader } from './MaskLoader';
import Renderer from '../Renderer';

@autoInjectable()
export class Coursebook extends VrObject3D {
    public container: Block;

    public mask: boolean = true;
    public containerMask: MaskLoader;

    public constructor(
        public coursebookCollection: object,
        public httpClient?: AxiosHttpClient,
        public intersectionContainer?: IntersectionContainer,
        public renderer?: Renderer,
    ) {
        super();

        this.fetchView();
    }

    public removeObject(obj) {
        let allChild = [];

        obj.traverse(function (child) {
            if (child instanceof Block || VrObject3D) {
                allChild.push(child);
            }
        });

        let all = this.intersectionContainer.objectsToIntersect;
        all = all.filter(
            (one) => !allChild.find((two) => one.uuid == two.uuid),
        );
        this.intersectionContainer.objectsToIntersect = all;

        let parent = obj.parent;
        parent.remove(obj);
    }

    public disableShadows() {}

    public setDefaultState(block: Block, click?: Function) {
        let selectedAttribute: any = {
            state: 'selected',
            attributes: {
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.1,
            },
        };

        if (click) {
            selectedAttribute = {
                state: 'selected',
                attributes: {
                    backgroundColor: new Color(0xffffff),
                    backgroundOpacity: 0.1,
                },
                onSet: () => {
                    click();
                },
            };
        }

        //@ts-ignore
        block.setupState({
            state: 'idle',
            attributes: {
                backgroundOpacity: 0.001,
            },
        });
        //@ts-ignore
        block.setupState({
            state: 'hovered',
            attributes: {
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.1,
            },
        });

        //@ts-ignore
        block.setupState(selectedAttribute);
    }

    public async fetchView() {
        // this.courses = await this.httpClient.getCourses();
        // let collection
        //
        // if (this.mask) {
        //     const containerMask = new MaskLoader();
        //     containerMask.position.z = 0.15;
        //     this.add(containerMask);
        //     this.containerMask = containerMask;
        // }
        //
        // try {
        //     collection = await this.httpClient.getCourses();
        //     this.mask = false;
        // } catch (err) {
        //     console.log('Error w pobieraniu danych');
        // }
        //
        // if (!this.mask) {
        //     this.containerMask.removeMask();
        // }

        let borderWidth = 0.01;
        let borderColor = new Color(0xffffff);
        let borderOpacity = 0.8;
        let offsetActiveObj = 0.03;

        //@ts-ignore
        if (this.coursebookCollection.coursebookSet.length > 0) {
            //MAIN CONTAINER
            this.container = new Block({
                width: 4.4,
                height: 2.7,
                opacity: 1,
                fontFamily: FontJSON,
                fontTexture: FontImage,
                hiddenOverflow: true,
                backgroundOpacity: 0,
                borderRadius: 0,
                borderWidth: borderWidth,
                borderColor: borderColor,
                borderOpacity: 0,
                // padding: 0.015,
                justifyContent: 'center',
                alignItems: 'center',
                contentDirection: 'column',
            });

            this.container.userData.type = 'ui-board';
            this.container.userData.id = 'ui-board';
            this.intersectionContainer.addObjectToIntersect(
                this.container,
                true,
                false,
            );
            this.setDefaultState(this.container);

            const containerHeader = new Block({
                width: 4.2,
                height: 0.3,
                backgroundOpacity: 0,
                justifyContent: 'center',
                alignItems: 'start',
                contentDirection: 'row',
            });
            const containerHeaderButtonBack = new Block({
                width: 0.2,
                height: 0.2,
                backgroundOpacity: 0,
                borderWidth: borderWidth,
                borderColor: borderColor,
                borderOpacity: borderOpacity,
                // offset: offsetActiveObj,
                justifyContent: 'center',
                alignItems: 'center',
                offset: 0.0001,
            });

            new TextureLoader().load(IconScrollLeftWhite, (texture) => {
                containerHeaderButtonBack.add(
                    new Block({
                        width: 0.12,
                        height: 0.12,
                        backgroundOpacity: 1,
                        backgroundTexture: texture,
                        borderWidth: 0,
                    }),
                );
            });

            this.intersectionContainer.addObjectToIntersect(
                containerHeaderButtonBack,
                false,
            );
            this.setDefaultState(containerHeaderButtonBack, () => {
                this.removeObject(this);
            });

            const containerCoursebookTitle = new Block({
                // width: 7.4,
                width: 4.0,
                height: 0.2,
                contentDirection: 'row',
                backgroundOpacity: 0,
                borderOpacity: 0,
            }).add(
                new Block({
                    width: 0.2,
                    height: 0.2,
                    backgroundOpacity: 0,
                    borderOpacity: 0,
                }),
                new Block({
                    width: 3.8,
                    height: 0.2,
                    backgroundOpacity: 0,
                    borderOpacity: 0,
                    fontColor: new Color(0xffffff),
                    textAlign: 'left',
                    justifyContent: 'center',
                    // fontSize: 0.08,
                    fontSize: 0.09,
                    fontFamily: FontJSON,
                    fontTexture: FontImage,
                    offset: 0.0001, //
                }).add(
                    new Text({
                        // @ts-ignore
                        content: this.coursebookCollection.courseTitle,
                    }),
                ),
            );

            containerHeader.add(
                containerHeaderButtonBack,
                containerCoursebookTitle,
            );

            this.container.add(containerHeader);
            //--

            // wrapper for vertical scroll
            const containerWrapper = new Block({
                width: 4.2,
                // height: 2.5,
                height: 2.3,
                contentDirection: 'row',
                alignItems: 'start',
                hiddenOverflow: true,
                backgroundOpacity: 0,
                borderRadius: 0,
                borderWidth: 0,
                borderOpacity: 0,
            });
            this.container.add(containerWrapper);

            const mainContent = new Block({
                // width: 7.6,
                // height: 4.0,
                width: 4,
                // height: 2.5,
                height: 2.3,
                backgroundOpacity: 0,
                borderWidth: 0,
            });

            const verticalScrollWrapper = new Block({
                width: 0.2,
                // height: 4.0,
                height: 2.3,
                contentDirection: 'column',
                // alignItems: 'right',
                alignItems: 'right',
                backgroundOpacity: 0,
                borderWidth: 0,
            });

            containerWrapper.add(mainContent, verticalScrollWrapper);

            // btns for verticalScrollWrapper
            const btnScrollOptions = {
                width: 0.1,
                height: 0.1,
                // offset: offsetActiveObj,
                offset: 0.0001,
                backgroundOpacity: 0,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 0,
                borderWidth: borderWidth,
                borderColor: borderColor,
                borderOpacity: borderOpacity,
            };

            const btnScrollUp = new Block(btnScrollOptions);
            const btnScrollDown = new Block(btnScrollOptions);

            // IconScrollUpWhite
            new TextureLoader().load(IconScrollUpWhite, (texture) => {
                btnScrollUp.add(
                    new Block({
                        width: 0.08,
                        height: 0.08,
                        backgroundTexture: texture,
                        borderWidth: 0,
                    }),
                );
            });
            // IconScrollDownWhite
            new TextureLoader().load(IconScrollDownWhite, (texture) => {
                btnScrollDown.add(
                    new Block({
                        width: 0.08,
                        height: 0.08,
                        backgroundTexture: texture,
                        borderWidth: 0,
                    }),
                );
            });

            const panelScrollSpace = new Block({
                width: 0.2,
                height: 2.1,
                backgroundOpacity: 0,
                borderWidth: 0,
                borderOpacity: 0,
            });

            verticalScrollWrapper.add(
                btnScrollUp,
                panelScrollSpace,
                btnScrollDown,
            );

            this.setDefaultState(btnScrollUp, () => {
                // @ts-ignore
                mainContent.position.y === 0
                    ? (mainContent.position.y += 0)
                    : (mainContent.position.y -= 0.2);
            });
            this.setDefaultState(btnScrollDown, () => {
                //@ts-ignore
                mainContent.position.y >= mainContent.height
                    ? (mainContent.position.y += 0)
                    : (mainContent.position.y += 0.2);
            });

            this.intersectionContainer.addObjectToIntersect(
                btnScrollUp,
                false,
                true,
            );
            this.intersectionContainer.addObjectToIntersect(
                btnScrollDown,
                false,
                true,
            );

            //--

            //@ts-ignore
            this.coursebookCollection.coursebookSet.forEach((course) => {
                //blok z kursem
                const coursebookBlock = new Block({
                    // width: 7.6,
                    width: 4,
                    height: 0.3,
                    contentDirection: 'row',
                    backgroundOpacity: 0,
                    hiddenOverflow: true,
                    borderRadius: 0,
                    borderWidth: borderWidth,
                    borderColor: borderColor,
                    borderOpacity: borderOpacity,
                });

                const courseCover = new Block({
                    width: 0.6,
                    height: 0.3,
                    justifyContent: 'center',
                    backgroundOpacity: 0,
                    borderWidth: 0,
                    borderOpacity: 0,
                });

                new TextureLoader().load(defaultCourseIcon, (texture) => {
                    courseCover.add(
                        new Block({
                            width: 0.2,
                            height: 0.26,
                            backgroundTexture: texture,
                        }),
                    );
                });

                const courseTitle = new Block({
                    width: 3.2, //aaaa
                    height: 0.3,
                    fontSize: 0.08,
                    textAlign: 'left',
                    justifyContent: 'center',
                    fontFamily: FontJSON,
                    fontTexture: FontImage,
                    // bestFit: 'auto',
                    borderRadius: 0,
                    backgroundOpacity: 0,
                    borderWidth: 0,
                    borderOpacity: 0,
                });

                const courseTitleText = new Text({
                    content:
                        course.title === 'api.courses.unassigned_courses'
                            ? 'Unassigned CoursesPanel'
                            : Utils.stripNonAplhaNumericAndHtml(course.title),
                });

                courseTitle.add(courseTitleText);

                const space = new Block({
                    // width: 7.6,
                    width: 4,
                    height: 0.02,
                    borderWidth: 0,
                    borderOpacity: 0,
                    backgroundOpacity: 0,
                });

                coursebookBlock.add(courseCover, courseTitle);

                this.intersectionContainer.addObjectToIntersect(
                    coursebookBlock,
                    false,
                    true,
                );
                this.setDefaultState(coursebookBlock, () => {
                    const courseView = new CourseView(course);

                    // courseView.position.z = offsetActiveObj + 0.002;
                    courseView.position.z = offsetActiveObj + 0.001;

                    // @ts-ignore
                    this.add(courseView);
                });

                mainContent.add(coursebookBlock, space);
            });
        }

        this.add(this.container);

        this.container.traverse((block) => {
            if (block.name === 'MeshUI-Frame') {
                block.castShadow = false;
                block.receiveShadow = false;
            }
        });
    }
}
