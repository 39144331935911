import FirebaseClient from '../../Network/Firebase/FirebaseClient';
import { container } from 'tsyringe';
import Resources from '../../Resources';
import { useEffect, useState } from 'react';
import Application from '../../Application';
import VrScene from '../../Components/Three/VrScene';
import PrivateRoomScene from '../../Scenes/PrivateRoom/PrivateRoomScene';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import TranslationService from '../../Translations/TranslationService';

interface NotificationMessage {
    data: string;
    datetime: string;
    object_database_id: number;
    object_id: number;
    object_type: string;
    receiver: {
        firstName: string;
        id: number;
        lastName: string;
        photoURL: string;
        username: string;
    };
    receiver_id: number;
    sender: {
        firstName: string;
        id: number;
        lastName: string;
        photoURL: string;
        username: string;
    };
    sender_id: number;
    skip_on_bell: boolean;
    status: boolean;
    timestamp: number;
}

export function ShowInvite() {
    const firebaseClient = container.resolve(FirebaseClient);
    const resources = container.resolve(Resources);
    const httpClient = container.resolve(AxiosHttpClient);
    const [lastNotificationState, setLastNotificationState] = useState([]);

    const path = firebaseClient.getUniversalNotificationPath(
        resources.items.user.id,
    );

    useEffect(() => {
        firebaseClient.getData(path).subscribe((n) => {
            const values = n.val() || [];

            const newMessages = Object.keys(values).filter(
                (item: any) =>
                    values[item].object_type === 'NEW_MESSAGE' &&
                    !values[item].is_read,
            );

            const lastMessageIndex = newMessages.length - 1;
            const lastNotification = values[newMessages[lastMessageIndex]];

            const newMessagesValues = newMessages.map((item) => values[item]);

            setLastNotificationState(newMessagesValues.reverse());
        });
    }, []);

    const handleAccept = async (notification: NotificationMessage) => {
        const app = window['app'] as Application;

        (app.scene as VrScene).changeScene(
            new PrivateRoomScene(),
            Number(notification.sender.id),
            true,
        );

        firebaseClient
            .update(notification.object_id, { is_read: true })
            .subscribe(
                () => {},
                () => {},
                () => {},
            );
    };

    const handleDecline = (notification: NotificationMessage) => {
        firebaseClient
            .update(notification.object_id, { is_read: true })
            .subscribe(
                () => {},
                () => {},
                () => {},
            );
    };
    return (
        <div className="popup-wrapper-invite z-index-10000000000">
            <div className="popup-content-invite">
                {lastNotificationState.map((invite, index) => {
                    return (
                        <div
                            key={index}
                            className="popup-invite d-flex justify-content-around flex-column"
                        >
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-column">
                                    <span className="datetime-invite">
                                        {invite.datetime}
                                    </span>
                                    <p className="p-0 m-0">
                                        {TranslationService.translate(
                                            'vr.friends_invite_by',
                                        )}
                                        &nbsp;
                                        <span>{invite.sender?.username}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="accept-or-decline">
                                <button
                                    onClick={() => handleAccept(invite)}
                                    className="btn btn-primary"
                                >
                                    {TranslationService.translate(
                                        'vr.friends_accept',
                                    )}
                                </button>
                                <button
                                    onClick={() => handleDecline(invite)}
                                    className="btn btn-secondary"
                                >
                                    {TranslationService.translate(
                                        'vr.friends_decline',
                                    )}
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
