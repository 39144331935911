import React, { useEffect, useState } from 'react';
import { container } from 'tsyringe';
import DemoService from '../../DemoService';
import './main.css';
import TranslationService from '../../Translations/TranslationService';
import { MdClose } from 'react-icons/md';
import { GiPadlock } from 'react-icons/gi';
const DemoPopup = () => {
    const demoService = container.resolve(DemoService);

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const showPopup = () => {
            setIsVisible(true);
            setTimeout(() => {
                setIsVisible(false);
            }, 5000);
        };

        demoService.addEventListener('requestPopup', showPopup);

        return () => {
            demoService.removeEventListener('requestPopup', showPopup);
        };
    }, []);

    const handleClose = () => {
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className="demo-popup">
            <div className="demo-popup-container">
                <button onClick={handleClose}>
                    <MdClose size="2em" />
                </button>
                <div className="demo-popup-content">
                    <div className="demo-icon">
                        <GiPadlock />
                    </div>
                    <div className="demo-popup-right">
                        <h4>
                            {/*{TranslationService.translate('vr.demo_message')}*/}
                            Available in the full version of the software
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DemoPopup;
