import React, { useState, useEffect } from 'react';
import { JanusClient } from './rx/JanusClient';
import { container } from 'tsyringe';

const ShareScreenButton = () => {
    const janusClient = container.resolve(JanusClient);
    const [isScreenSharing, setIsScreenSharing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const publishingSubscription = janusClient.publishing$.subscribe(
            (isPublishing) => {
                //console.log('Publishing state changed:', isPublishing);
                if (!isPublishing) {
                    setIsScreenSharing(false);
                }
            },
        );

        const onScreenShareStarted = (event: any) => {
            if (event.detail?.success) {
                //console.log('Screen sharing started successfully');
                setIsScreenSharing(true);
            }
        };

        const onScreenShareStopped = () => {
            //console.log('Screen sharing stopped');
            setIsScreenSharing(false);
        };

        janusClient.addEventListener(
            'screenShareStarted',
            onScreenShareStarted,
        );
        janusClient.addEventListener(
            'screenShareStopped',
            onScreenShareStopped,
        );

        //@ts-ignore
        if (janusClient._screenShareActive) {
            setIsScreenSharing(true);
        }

        return () => {
            publishingSubscription.unsubscribe();
            janusClient.removeEventListener(
                'screenShareStarted',
                onScreenShareStarted,
            );
            janusClient.removeEventListener(
                'screenShareStopped',
                onScreenShareStopped,
            );
        };
    }, [janusClient]);

    const toggleScreenShare = async () => {
        if (isLoading) return;

        setIsLoading(true);
        try {
            if (!isScreenSharing) {
                //console.log('Starting screen sharing...');
                await janusClient.startScreenShare();
            } else {
                //console.log('Stopping screen sharing...');
                await janusClient.stopScreenShare();
            }
        } catch (error) {
            console.error('Error toggling screen share:', error);

            if (error.name === 'NotAllowedError') {
                //console.log('Screen sharing permission denied by user');
            } else {
                alert(
                    'Failed to ' +
                        (isScreenSharing ? 'stop' : 'start') +
                        ' screen sharing.',
                );
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="janus-buttons">
            <button
                className={`screen-share-button ${
                    isScreenSharing ? 'active' : ''
                } ${isLoading ? 'loading' : ''}`}
                onClick={toggleScreenShare}
                title={
                    isScreenSharing
                        ? 'Stop Screen Sharing'
                        : 'Start Screen Sharing'
                }
                disabled={isLoading}
            >
                <span className="material-symbols-outlined">
                    {isLoading
                        ? 'hourglass_empty'
                        : isScreenSharing
                        ? 'stop_screen_share'
                        : 'screen_share'}
                </span>
                {isScreenSharing ? 'Stop Sharing' : 'Share Screen'}
            </button>
        </div>
    );
};

export default ShareScreenButton;
