import { createContext, useState, useContext, useEffect } from 'react';
import { container } from 'tsyringe';
import TranslationService from '../../Translations/TranslationService';
//@ts-ignore
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const translationService = container.resolve(TranslationService);
    const [language, setLanguage] = useState(translationService.getLanguage());

    const changeLanguage = (lang) => {
        translationService.setLanguage(lang);
        setLanguage(lang);
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            setLanguage(savedLanguage);
        }
    }, []);

    return (
        //@ts-ignore
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);
