import './main.css';
import Renderer from '../../Components/Renderer';
import { container } from 'tsyringe';
import { useEffect, useState } from 'react';
import TranslationService from '../../Translations/TranslationService';

const RoomLabel = () => {
    const renderer = container.resolve(Renderer);

    const [sceneName, setSceneName] = useState('');

    useEffect(() => {
        if (renderer.scene.name === 'LobbyScene') {
            setSceneName(TranslationService.translate('vr.main_lobby_label'));
        }
        renderer.onSceneChange((scene) => {
            console.log(scene.sceneNameForLabel);

            if (scene.sceneNameForLabel) {
                setSceneName(scene.sceneNameForLabel);
            } else {
                //@ts-ignore
                setSceneName(renderer.scene.roomInfo.sceneName);
            }
        });
    }, []);

    return <div className="room-label">{sceneName}</div>;
};

export default RoomLabel;
