import {
    EventDispatcher,
    Object3D,
    Raycaster,
    Vector3,
    Quaternion,
    Matrix4,
    Sprite,
    Color,
    Clock,
    RingGeometry,
    Mesh,
    MeshBasicMaterial,
    Material,
    BufferGeometry,
} from 'three';
import { autoInjectable, singleton } from 'tsyringe';
import Renderer from '../Renderer';
import XrControllers from './XrControllers';
import linesHelper, { pointer } from './ControllersUtils';
import Config from '../../../Config';
import Camera from '../../Camera';
import { MeshBVH, SAH } from 'three-mesh-bvh';

export class GazeRing extends Mesh {
    public material: Material | Material[] = new MeshBasicMaterial({
        opacity: 0.5,
        transparent: true,
    });

    public geometry: BufferGeometry = new RingGeometry(0.02, 0.04, 32).translate(0, 0, -1);

    public constructor() {
        super();
        this.geometry = new RingGeometry(0.02, 0.04, 32).translate(0, 0, -1);
        this.material = new MeshBasicMaterial({
            opacity: 0.5,
            transparent: true,
            color: new Color('white'),
        });
    }

    public setDefault() {
        this.geometry.dispose();
        this.geometry = new RingGeometry(0.02, 0.04, 32).translate(0, 0, -1);
    }

    public updateProgress(progress: number) {
        const thetaLettingTime = Math.round(progress * 100) * ((Math.PI * 2) / 100);
        this.geometry.dispose();
        this.geometry = new RingGeometry(0.02, 0.04, 32, 32, 0, thetaLettingTime).translate(0, 0, -1);
    }
}

interface InteractiveObject {
    object: Object3D;
    priority: number;
    onHoverStart?: (controller: Object3D, intersection: IntersectionResult) => void;
    onHoverEnd?: (controller: Object3D, intersection: IntersectionResult) => void;
    onHover?: (controller: Object3D, intersection: IntersectionResult) => void;
    onSelectStart?: (controller: Object3D, intersection: IntersectionResult) => void;
    onSelectEnd?: (controller: Object3D, intersection: IntersectionResult) => void;
    onRotate?: (rotation: Quaternion, controller: Object3D, intersection: IntersectionResult) => void;
}

interface IntersectionResult {
    point: Vector3;
    distance: number;
    object: Object3D;
}

interface XRGamepadButton {
    pressed: boolean;
    touched: boolean;
    value: number;
}

interface XRGamepad {
    buttons: XRGamepadButton[];
    axes: number[];
    connected: boolean;
    mapping: string;
}

@singleton()
@autoInjectable()
export class InteractionManager extends EventDispatcher {
    private interactiveObjects: InteractiveObject[] = [];
    private hoveredObjects: Map<Object3D, InteractiveObject> = new Map();
    private selectedObjects: Map<Object3D, InteractiveObject> = new Map();
    private raycaster: Raycaster = new Raycaster();
    private tempMatrix = new Matrix4();
    public pointer: Sprite = pointer;
    private renderer?: Renderer = null;

    public controllers: Object3D[] = [];
    public controllerListeners = false;

    private gazeRing?: GazeRing;
    private intersectionClock: Clock;
    private readonly GAZE_SELECTION_TIME = 2;
    private isUsingGazeController = false;
    private gazeController: Object3D | null = null;

    private readonly handleSelectStart: (event: any) => void;
    private readonly handleSelectEnd: (event: any) => void;
    private readonly handleSceneChange: () => void;

    private activeController: Object3D | null = null;
    private readonly ACTIVE_COLOR = new Color('lightblue');
    private readonly INACTIVE_COLOR = new Color('white');
    private readonly INTERSECTION_COLOR = new Color('blue');

    private activeGamepads: Map<Object3D, XRGamepad> = new Map();
    private buttonStates: Map<Object3D, boolean[]> = new Map();
    private readonly AXIS_THRESHOLD = 0.1;

    private isHoldingObject: boolean = false;
    private heldObject: Object3D | null = null;

    private bvhManager: MeshBVH;

    private holdingController: Object3D | null = null;


    public constructor(public config?: Config, public camera?: Camera) {
        super();

        this.handleSelectStart = this.onSelectStart.bind(this);
        this.handleSelectEnd = this.onSelectEnd.bind(this);
        this.handleSceneChange = this.onSceneChange.bind(this);

        this.intersectionClock = new Clock(false);
        this.gazeRing = new GazeRing();
        this.gazeRing.visible = false;
        this.setupBVH();
    }

    private setupBVH() {
        // Generate BVH for all interactive objects
        // this.bvhManager = new MeshBVH(geometry, {
        //     maxLeafTris: 10,
        //     strategy: SAH,
        // });
    }

    public setRenderer(renderer: Renderer): void {
        this.renderer = renderer;

        if (!this.controllerListeners) {
            this.setupControllerListeners();
            this.controllerListeners = true;
        }

        this.renderer.addEventListener('sceneChange', this.handleSceneChange);
    }


    public getHoldingController(): Object3D | null {
        return this.holdingController;
    }

    private setupControllerListeners(): void {
        if (!this.renderer?.webGLRenderer) {
            console.error('WebGLRenderer not initialized');
            return;
        }

        const xrManager = this.renderer.webGLRenderer.xr;
        if (!xrManager) {
            console.error('XR not available');
            return;
        }

        this.controllers = [xrManager.getController(0), xrManager.getController(1)];

        this.controllers.forEach((controller) => {
            controller.addEventListener('connected', (event: any) => {
                const targetRayMode = event.data.targetRayMode;

                if (event.data.gamepad) {
                    this.initializeGamepad(controller, event.data.gamepad);
                }

                if (targetRayMode === 'gaze') {
                    this.isUsingGazeController = true;
                    this.gazeController = controller;
                    if (this.gazeRing) {
                        this.gazeRing.visible = true;
                        this.camera.instance.add(this.gazeRing);
                    }
                } else if (targetRayMode === 'tracked-pointer') {
                    this.isUsingGazeController = false;
                    if (this.gazeRing) {
                        this.gazeRing.visible = false;
                    }
                }
            });

            controller.userData.hoveredObject = null;
            controller.userData.isActive = false;

            const lineClone = linesHelper.clone();
            lineClone.material = lineClone.material.clone();
            controller.add(lineClone);
            controller.userData.line = lineClone;

            controller.addEventListener('selectstart', this.handleSelectStart);
            controller.addEventListener('selectend', this.handleSelectEnd);

            controller.addEventListener('squeezestart', () => {
                this.toggleControllerActive(controller);
            });

            controller.addEventListener('disconnected', () => {
                this.removeGamepad(controller);
            });
        });

        xrManager.addEventListener('sessionstart', () => {
            this.isUsingGazeController = false;
            if (this.gazeRing) {
                this.gazeRing.visible = false;
            }
        });

        xrManager.addEventListener('sessionend', () => {
            this.isUsingGazeController = false;
            if (this.gazeRing) {
                this.gazeRing.visible = false;
            }
        });

        this.setControllerActive(this.controllers[0]);
    }

    private initializeGamepad(controller: Object3D, gamepad: XRGamepad): void {
        if (gamepad.mapping !== 'xr-standard') {
            console.warn('Non-standard XR gamepad mapping detected');
        }

        this.activeGamepads.set(controller, gamepad);
        this.buttonStates.set(controller, new Array(gamepad.buttons.length).fill(false));
    }

    private removeGamepad(controller: Object3D): void {
        this.activeGamepads.delete(controller);
        this.buttonStates.delete(controller);
    }

    private updateButtonStates(controller: Object3D, gamepad: XRGamepad): void {
        const prevStates = this.buttonStates.get(controller);
        if (!prevStates) return;

        gamepad.buttons.forEach((button, index) => {
            const wasPressed = prevStates[index];
            const isPressed = button.pressed;

            if (isPressed !== wasPressed) {
                prevStates[index] = isPressed;

                let buttonType: string;
                switch (index) {
                    case 0:
                        buttonType = 'trigger';
                        break;
                    case 1:
                        buttonType = 'squeeze';
                        break;
                    case 2:
                        buttonType = 'touchpad';
                        break;
                    case 3:
                        buttonType = 'thumbstick';
                        break;
                    default:
                        return;
                }

                this.dispatchEvent({
                    type: 'gamepadButton',
                    button: buttonType,
                    action: isPressed ? 'start' : 'end',
                    controller,
                });
            }
        });
    }

    private updateAxes(controller: Object3D, gamepad: XRGamepad): void {
        if (gamepad.axes.length >= 4) {
            const thumbstickX = gamepad.axes[2];
            const thumbstickY = gamepad.axes[3];

            if (Math.abs(thumbstickX) > this.AXIS_THRESHOLD || Math.abs(thumbstickY) > this.AXIS_THRESHOLD) {
                this.dispatchEvent({
                    type: 'gamepadThumbstick',
                    x: thumbstickX,
                    y: thumbstickY,
                    controller,
                });
            }
        }
    }

    public isHolding(): boolean {
        return this.isHoldingObject;
    }

    public getHeldObject(): Object3D | null {
        return this.heldObject;
    }

    private onSceneChange(): void {
        this.clearInteractiveObjects();
        this.gazeRing?.setDefault();

        this.controllers.forEach((controller) => {
            setTimeout(() => {
                const pointerClone = pointer.clone();
                controller.userData.pointer = pointerClone;
                controller.userData.pointer.visible = false;
                this.renderer.scene.add(pointerClone);
            });
        });
    }

    public addInteractiveObject(
        object: Object3D,
        priority: number,
        callbacks: {
            onHoverStart?: (controller: Object3D, intersection: IntersectionResult) => void;
            onHoverEnd?: (controller: Object3D, intersection: IntersectionResult) => void;
            onHover?: (controller: Object3D, intersection: IntersectionResult) => void;
            onSelectStart?: (controller: Object3D, intersection: IntersectionResult) => void;
            onSelectEnd?: (controller: Object3D, intersection: IntersectionResult) => void;
            onRotate?: (rotation: Quaternion, controller: Object3D, intersection: IntersectionResult) => void;
        },
        addChildren: boolean = false,
    ) {
        this.interactiveObjects.push({ object, priority, ...callbacks });

        if (addChildren) {
            object.traverse((child: Object3D) => {
                if (child !== object) {
                    this.interactiveObjects.push({
                        object: child,
                        priority,
                        ...callbacks,
                    });
                }
            });
        }

        this.interactiveObjects.sort((a, b) => b.priority - a.priority);
    }
    private getPointedObject(controller: Object3D): any {
        this.tempMatrix.identity().extractRotation(controller.matrixWorld);
        this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
        this.raycaster.ray.direction.set(0, 0, -1).applyMatrix4(this.tempMatrix);

        const objectsToTest = this.interactiveObjects.map((io) => io.object);

        const intersections = this.raycaster.intersectObjects(objectsToTest, false);

        this.raycaster.firstHitOnly = true;
        this.raycaster.far = 10;

        if (intersections.length === 0) {
            return null;
        }

        for (const inter of intersections) {
            const foundIO = this.interactiveObjects.find((io) => {
                return (
                    inter.object === io.object ||
                    io.object === inter.object.parent ||
                    io.object === inter.object.parent?.parent
                );
            });

            if (foundIO) {
                return {
                    point: inter.point.clone(),
                    distance: inter.distance,
                    object: foundIO,
                    rawIntersection: inter,
                };
            }
        }

        return null;
    }
    private createIntersectionResult(intersection: any): IntersectionResult {
        return {
            point: intersection.point || new Vector3(),
            distance: intersection.distance || 0,
            object: intersection?.rawIntersection?.object || intersection?.object?.object || null,
        };
    }

    private setControllerActive(controller: Object3D): void {
        if (this.activeController && this.activeController !== controller) {
            this.activeController.userData.isActive = false;
            this.activeController.userData.line.material.color = this.INACTIVE_COLOR;
        }

        this.activeController = controller;
        controller.userData.isActive = true;
        controller.userData.line.material.color = this.ACTIVE_COLOR;

        this.dispatchEvent({
            type: 'controllerActivated',
            controller,
        });
    }

    private updateGazeInteraction() {
        if (!this.isUsingGazeController || !this.gazeRing || !this.gazeController) return;

        const intersection = this.getPointedObject(this.gazeController);

        if (this.gazeController.userData.hoveredObject?.object !== intersection?.object?.object) {
            if (this.gazeController.userData.hoveredObject) {
                const previousHovered = this.gazeController.userData.hoveredObject;
                previousHovered.onHoverEnd?.(
                    this.gazeController,
                    this.createIntersectionResult(
                        intersection || {
                            point: new Vector3(),
                            distance: 0,
                            object: previousHovered.object,
                            rawIntersection: null,
                        },
                    ),
                );
                this.intersectionClock.stop();
                this.gazeRing.setDefault();
            }

            if (intersection?.object) {
                const intersectionResult = this.createIntersectionResult(intersection);
                intersection.object.onHoverStart?.(this.gazeController, intersectionResult);
                this.intersectionClock.start();
            }

            this.gazeController.userData.hoveredObject = intersection?.object || null;
        }

        if (intersection?.object) {
            const elapsedTime = this.intersectionClock.getElapsedTime();

            if (elapsedTime <= this.GAZE_SELECTION_TIME) {
                const progress = elapsedTime / this.GAZE_SELECTION_TIME;
                this.gazeRing.updateProgress(progress);
                (this.gazeRing.material as MeshBasicMaterial).color = this.ACTIVE_COLOR;

                const intersectionResult = this.createIntersectionResult(intersection);
                intersection.object.onHover?.(this.gazeController, intersectionResult);
            }

            if (elapsedTime >= this.GAZE_SELECTION_TIME) {
                const event = { target: this.gazeController };
                this.onSelectStart(event);
                setTimeout(() => this.onSelectEnd(event), 100);

                (this.gazeRing.material as MeshBasicMaterial).color = this.INACTIVE_COLOR;
                this.gazeRing.setDefault();
                this.intersectionClock.stop();
            }
        }
    }

    public update() {
        this.activeGamepads.forEach((gamepad, controller) => {
            this.updateButtonStates(controller, gamepad);
            this.updateAxes(controller, gamepad);
        });

        let anyIntersection = false;

        this.controllers.forEach((controller) => {
            if (!this.renderer.webGLRenderer.xr.isPresenting) {
                return;
            }

            controller.userData.line.material.color = controller.userData.isActive
                ? this.ACTIVE_COLOR
                : this.INACTIVE_COLOR;

            const intersection = this.getPointedObject(controller);

            if (controller === this.activeController) {
                if (controller.userData.hoveredObject?.object !== intersection?.object?.object) {
                    const previousHovered = controller.userData.hoveredObject;
                    if (previousHovered) {
                        previousHovered.onHoverEnd?.(
                            controller,
                            this.createIntersectionResult(
                                intersection || {
                                    point: new Vector3(),
                                    distance: 0,
                                    object: previousHovered.object,
                                    rawIntersection: null,
                                },
                            ),
                        );
                    }

                    if (intersection?.object) {
                        const intersectionResult = this.createIntersectionResult(intersection);
                        intersection.object.onHoverStart?.(controller, intersectionResult);
                    }

                    controller.userData.hoveredObject = intersection?.object || null;
                }

                if (intersection?.object) {
                    const intersectionResult = this.createIntersectionResult(intersection);
                    intersection.object.onHover?.(controller, intersectionResult);
                }
            }

            if (intersection) {
                anyIntersection = true;
                if (controller.userData.isActive) {
                    controller.userData.line.material.color = this.INTERSECTION_COLOR;
                }

                if (controller.userData.pointer) {
                    controller.userData.pointer.visible = controller === this.activeController;
                    if (controller === this.activeController) {
                        controller.userData.pointer.position.copy(intersection.point);
                    }
                }
            } else {
                if (controller.userData.pointer) {
                    controller.userData.pointer.visible = false;
                }
            }
        });

        this.updateGazeInteraction();

        if (this.config) {
            this.config.skipLegacyIntersection = anyIntersection;
        }
    }

    public toggleControllerActive(controller: Object3D): void {
        if (controller === this.activeController) {
            this.deactivateController();
        } else {
            this.setControllerActive(controller);
        }
    }

    private onSelectStart(event: any) {
        const controller = event.target as Object3D;

        if (controller !== this.activeController && !this.isUsingGazeController) {
            this.setControllerActive(controller);
            return;
        }

        const hovered = controller.userData.hoveredObject;
        if (hovered) {
            this.selectedObjects.set(controller, hovered);
            const intersection = this.getPointedObject(controller);
            if (intersection) {
                const intersectionResult = this.createIntersectionResult(intersection);
                hovered.onSelectStart?.(controller, intersectionResult);
            } else {
                hovered.onSelectStart?.(controller);
            }
        }
    }

    private onSelectEnd(event: any) {
        const controller = event.target as Object3D;

        if (controller !== this.activeController && !this.isUsingGazeController) {
            return;
        }

        const selected = this.selectedObjects.get(controller);
        if (selected) {
            const intersection = this.getPointedObject(controller);
            const intersectionResult = intersection
                ? this.createIntersectionResult(intersection)
                : {
                      point: new Vector3(),
                      distance: 0,
                      object: selected.object,
                  };
            selected.onSelectEnd?.(controller, intersectionResult);
            this.selectedObjects.delete(controller);
        }
    }

    public removeInteractiveObject(object: Object3D) {
        const index = this.interactiveObjects.findIndex((io) => io.object === object);
        if (index !== -1) {
            this.controllers.forEach((controller) => {
                const hoveredObject = this.hoveredObjects.get(controller);
                if (hoveredObject?.object === object) {
                    const intersection = this.getPointedObject(controller);
                    const intersectionResult = intersection
                        ? this.createIntersectionResult(intersection)
                        : {
                              point: new Vector3(),
                              distance: 0,
                              object: hoveredObject.object,
                          };
                    hoveredObject.onHoverEnd?.(controller, intersectionResult);
                    this.hoveredObjects.delete(controller);
                }

                const selectedObject = this.selectedObjects.get(controller);
                if (selectedObject?.object === object) {
                    const intersection = this.getPointedObject(controller);
                    const intersectionResult = intersection
                        ? this.createIntersectionResult(intersection)
                        : {
                              point: new Vector3(),
                              distance: 0,
                              object: selectedObject.object,
                          };
                    selectedObject.onSelectEnd?.(controller, intersectionResult);
                    this.selectedObjects.delete(controller);
                }
            });

            this.interactiveObjects.splice(index, 1);
        }
    }

    public clearInteractiveObjects(): void {
        this.controllers.forEach((controller) => {
            const hovered = this.hoveredObjects.get(controller);
            if (hovered) {
                const intersection = this.getPointedObject(controller);
                const intersectionResult = intersection
                    ? this.createIntersectionResult(intersection)
                    : {
                          point: new Vector3(),
                          distance: 0,
                          object: hovered.object,
                      };
                hovered.onHoverEnd?.(controller, intersectionResult);
                this.hoveredObjects.delete(controller);
            }

            const selected = this.selectedObjects.get(controller);
            if (selected) {
                const intersection = this.getPointedObject(controller);
                const intersectionResult = intersection
                    ? this.createIntersectionResult(intersection)
                    : {
                          point: new Vector3(),
                          distance: 0,
                          object: selected.object,
                      };
                selected.onSelectEnd?.(controller, intersectionResult);
                this.selectedObjects.delete(controller);
            }
        });

        this.interactiveObjects = [];
        if (this.isUsingGazeController) {
            this.intersectionClock.stop();
            this.gazeRing?.setDefault();
        }
    }

    public getActiveController(): Object3D | null {
        return this.activeController;
    }

    public deactivateController(): void {
        if (this.activeController) {
            this.activeController.userData.isActive = false;
            this.activeController.userData.line.material.color = this.INACTIVE_COLOR;
            this.activeController = null;

            this.dispatchEvent({
                type: 'controllerDeactivated',
            });
        }
    }

    public requestHapticPulse(controller: Object3D, intensity: number = 1.0, duration: number = 100): void {
        const xrInputSource = (controller as any).inputSource;
        if (xrInputSource?.gamepad?.hapticActuators?.[0]) {
            xrInputSource.gamepad.hapticActuators[0].pulse(intensity, duration).catch((error: any) => {
                console.warn('Haptic pulse request failed:', error);
            });
        }
    }
}
