import axios, { AxiosRequestConfig } from 'axios';
import { Axios } from 'axios/index';
import { autoInjectable, singleton } from 'tsyringe';
import AxiosHttpClient from './AxiosHttpClient';
import Resources from '../Resources';
// import WaitForInitialization from './HTTP/Decorators.js'

function WaitForInitialization(
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor,
) {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args: any[]) {
        await this.waitForInitialization();
        return originalMethod.apply(this, args);
    };
}

@singleton()
@autoInjectable()
export default class AssignmentsHttpClient {
    public instance: Axios;
    private initialized: Promise<void>;
    private resolveInitialized: () => void;

    public constructor(
        public httpClient: AxiosHttpClient,
        public resources?: Resources,
    ) {
        this.instance = axios.create({
            baseURL: this.resources?.items.settings.assignments_url,
        });

        this.initialized = new Promise<void>((resolve) => {
            this.resolveInitialized = resolve;
        });

        this.init()
            .then(() => {
                console.log('Assignments initialized');
                this.resolveInitialized();
            })
            .catch((error) => {
                console.error('Assignments initialization error:', error);
            });
    }

    public async waitForInitialization() {
        await this.initialized;
    }

    public async init() {
        if (this.resources?.items.settings.assignments_active) {
            await this.setInterceptors();
        }
    }

    public async setInterceptors() {
        const tokenResponse = await this.getAssignmentsToken();
        localStorage.setItem('assignmentsToken', tokenResponse.data.token);

        this.instance.interceptors.request.use(
            (requestConfig: AxiosRequestConfig) => {
                requestConfig.headers.Authorization = `Bearer ${localStorage.getItem(
                    'assignmentsToken',
                )}`;
                return requestConfig;
            },
            (error) => {
                return Promise.reject(error);
            },
        );
    }

    public async getAssignmentsToken() {
        return this.httpClient.instance.post(
            'api/v2/auth/jwt/obtain/assignments',
        );
    }

    @WaitForInitialization
    public async getAssignmentsForTeacher(requestPayload) {
        return this.instance.post('/api/v3/assignments/search', requestPayload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    @WaitForInitialization
    public async getAssignmentsForStudent(requestPayload) {
        return this.instance.post('/api/v3/assignments/students_assignments/search', requestPayload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
}
