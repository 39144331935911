import React, { useState } from 'react';
import './main.css';

const Tabs = ({ children }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [key, setKey] = useState(0);

    const handleTabChange = (index) => {
        setActiveTab(index);
        setKey((prevKey) => prevKey + 1);
    };

    return (
        <div className="">
            <div className="tabs-buttons">
                {React.Children.map(children, (child, index) => (
                    <button
                        key={index}
                        className={`px-4 py-2 font-medium ${
                            activeTab === index ? 'active-tab' : ''
                        }`}
                        onClick={() => handleTabChange(index)}
                    >
                        {child.props.label}
                    </button>
                ))}
            </div>
            <div key={`${activeTab}-${key}`}>
                {React.Children.toArray(children)[activeTab]}
            </div>
        </div>
    );
};

const Tab = ({ children, label }) => children;

export { Tabs, Tab };
