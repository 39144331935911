import React, { useEffect, useState, useCallback } from 'react';
import { container } from 'tsyringe';
import Renderer from '../../Components/Renderer';
import TranslationService from '../../Translations/TranslationService';
import DemoService from '../../DemoService';

export const VrButton = () => {
    const renderer = container.resolve(Renderer);
    const translationService = container.resolve(TranslationService);
    const demoService = container.resolve(DemoService);
    const [isVrSupported, setIsVrSupported] = useState(false);
    const [isWebXRAvailable, setIsWebXRAvailable] = useState(true);
    const [session, setSession] = useState<XRSession | null>(null);

    const sessionInit = {
        optionalFeatures: [
            'local-floor',
            'bounded-floor',
            'hand-tracking',
            'layers',
        ],
    };

    useEffect(() => {
        const checkVrSupport = async () => {
            if ('xr' in navigator) {
                try {
                    const supported = await navigator.xr.isSessionSupported(
                        'immersive-vr',
                    );
                    setIsVrSupported(supported);
                } catch (error) {
                    console.error('Error checking VR support:', error);
                    setIsVrSupported(false);
                }
            } else {
                setIsVrSupported(false);
            }
        };

        checkVrSupport();
        setIsWebXRAvailable(demoService.isWebXRAvailable);

        const handleWebXRAvailabilityChanged = (event: any) => {
            setIsWebXRAvailable(event.isAvailable);
        };

        demoService.addEventListener(
            'webXRAvailabilityChanged',
            handleWebXRAvailabilityChanged,
        );

        return () => {
            demoService.removeEventListener(
                'webXRAvailabilityChanged',
                handleWebXRAvailabilityChanged,
            );
        };
    }, [demoService]);

    const endSession = useCallback(async () => {
        if (session) {
            await session.end();
            setSession(null);
            demoService.onWebXRSessionEnd();
        }
    }, [session, demoService]);

    useEffect(() => {
        const handleEndWebXRSession = () => {
            endSession();
        };

        demoService.addEventListener('endWebXRSession', handleEndWebXRSession);

        return () => {
            demoService.removeEventListener(
                'endWebXRSession',
                handleEndWebXRSession,
            );
        };
    }, [demoService, endSession]);

    const handleClick = async () => {
        if (session) {
            await endSession();
        } else if (isWebXRAvailable) {
            try {
                const newSession = await navigator.xr.requestSession(
                    'immersive-vr',
                    sessionInit,
                );
                await renderer.webGLRenderer.xr.setSession(newSession);
                setSession(newSession);
                demoService.onWebXRSessionStart();

                newSession.addEventListener('end', () => {
                    setSession(null);
                    demoService.onWebXRSessionEnd();
                });
            } catch (error) {
                console.error('WebXR session failed to start:', error);
            }
        } else {
            demoService.sendRequestPopup();
        }
    };

    if (!isVrSupported) {
        return null;
    }

    return (
        <button onClick={handleClick}>
            <span>
                {session
                    ? TranslationService.translate('vr.menu_exit_vr')
                    : isWebXRAvailable
                    ? TranslationService.translate('vr.menu_enter_vr')
                    : TranslationService.translate('vr.menu_enter_vr')}
            </span>
        </button>
    );
};
