import { autoInjectable } from 'tsyringe';
import { Room } from 'colyseus.js';

type RoomType = 'school_lobby' | 'private_space' | 'main_lobby';

@autoInjectable()
export default class RedirectService {
    private urlParams: URLSearchParams | null = null;
    private redirectFlag: RoomType | null = null;
    private courseId: string | null = null;

    public constructor() {
        this.urlParams = new URLSearchParams(window.location.search);
        this.redirectFlag = this.urlParams.get('room_type') as RoomType;
        this.courseId = this.urlParams.get('course_id');
    }

    public isRedirectFlagExists(): boolean {
        return !!this.urlParams.get('room_type');
    }

    public isCourseIdExists(): boolean {
        return !!this.courseId;
    }

    public saveRedirectFlag(roomType: RoomType) {
        localStorage.setItem('redirect_flag', roomType);
    }

    public saveCourseId(courseId: string) {
        localStorage.setItem('course_id', courseId);
    }

    public removeRedirectFlag() {
        localStorage.removeItem('redirect_flag');
    }

    public removeCourseId() {
        localStorage.removeItem('course_id');
    }

    public isSchoolRedirect() {
        return localStorage.getItem('redirect_flag') === 'school_lobby';
    }

    public getCourseId(): string | null {
        return localStorage.getItem('course_id');
    }

    public initialize() {
        if (this.isRedirectFlagExists()) {
            switch (this.redirectFlag) {
                case 'school_lobby':
                    this.saveRedirectFlag('school_lobby');
                    break;
                case 'main_lobby':
                    this.saveRedirectFlag('main_lobby');
                    break;
                case 'private_space':
                    this.saveRedirectFlag('private_space');
                    break;
            }
        }

        if (this.isCourseIdExists() && this.courseId) {
            console.log(this.courseId);
            this.saveCourseId(this.courseId);
        }
    }

    public cleanup() {
        this.removeRedirectFlag();
        this.removeCourseId();
    }
}
