import {
    CanvasTexture,
    Color,
    ExtrudeGeometry,
    Mesh,
    MeshBasicMaterial,
    MeshLambertMaterial,
    MeshPhongMaterial,
    MeshStandardMaterial,
    Object3D,
    Path,
    Shape,
} from 'three';

export default class CylinderHollow extends Object3D {
    public material: MeshLambertMaterial;
    public geometry: ExtrudeGeometry;

    public constructor() {
        super();

        const arcShape = new Shape();
        arcShape.absarc(0, 0, 0.1, 0, Math.PI * 2, false);

        const holePath = new Path();
        holePath.absarc(0, 0, 0.08, 0, Math.PI * 2, true);
        arcShape.holes.push(holePath);

        this.material = new MeshLambertMaterial({
            color: new Color('white'),
            emissive: new Color('white'),
            emissiveIntensity: 0.5,
        });

        this.geometry = new ExtrudeGeometry(arcShape, {
            depth: 0.02,
            steps: 1,
            bevelEnabled: false,
            curveSegments: 16,
        });
        this.add(new Mesh(this.geometry, this.material));
    }
}

export class CustomizableCylinderHollow extends Object3D {
    public material: MeshLambertMaterial;
    public geometry: ExtrudeGeometry;

    public constructor(
        outerRadius: number = 0.1,
        innerRadius: number = 0.08,
        depth: number = 0.02,
        emissiveIntensity: number = 0.5,
        curveSegments: number = 16,
    ) {
        super();

        const arcShape = new Shape();
        arcShape.absarc(0, 0, outerRadius, 0, Math.PI * 2, false);

        const holePath = new Path();
        holePath.absarc(0, 0, innerRadius, 0, Math.PI * 2, true);
        arcShape.holes.push(holePath);

        this.material = new MeshLambertMaterial({
            color: new Color('white'),
            transparent: true,
            opacity: 0.8,
        });

        this.geometry = new ExtrudeGeometry(arcShape, {
            depth: depth,
            steps: 1,
            bevelEnabled: false,
            curveSegments: curveSegments,
        });
        this.add(new Mesh(this.geometry, this.material));
    }

    public updateGeometry(
        outerRadius: number,
        innerRadius: number,
        depth: number,
        curveSegments: number,
    ): void {
        this.remove(this.children[0]);

        const arcShape = new Shape();
        arcShape.absarc(0, 0, outerRadius, 0, Math.PI * 2, false);

        const holePath = new Path();
        holePath.absarc(0, 0, innerRadius, 0, Math.PI * 2, true);
        arcShape.holes.push(holePath);

        this.geometry = new ExtrudeGeometry(arcShape, {
            depth: depth,
            steps: 1,
            bevelEnabled: false,
            curveSegments: curveSegments,
        });
        this.add(new Mesh(this.geometry, this.material));
    }

    public updateMaterial(emissiveIntensity: number): void {
        this.material.emissiveIntensity = emissiveIntensity;
    }
}
