import {
    Object3D,
    Vector2,
    Vector3,
    Euler,
    Quaternion,
    Raycaster,
    Camera,
    BoxGeometry,
    MeshBasicMaterial,
    Mesh,
    Color,
    Matrix4
} from 'three';

export class SimpleRotationControls {
    private object: Object3D | null = null;
    private domElement: HTMLElement;
    private camera: Camera;
    private isDragging = false;
    private previousMousePosition = new Vector2();
    private raycaster = new Raycaster();
    public hitbox: Mesh;
    public onRotationChange: ((rotation: Quaternion) => void) | null = null;
    public onRotationEnd: ((rotation: Quaternion) => void) | null = null;

    private cameraRightVector = new Vector3();
    private cameraUpVector = new Vector3();

    private deadzone = 0;

    public constructor(
        domElement: HTMLElement,
        camera: Camera,
    ) {
        this.domElement = domElement;
        this.camera = camera;
        this.addEventListeners();
    }

    public attach(object: Object3D): void {
        this.object = object;
        this.createHitbox();
    }

    private createHitbox(): void {
        if (!this.object) return;
        const geometry = new BoxGeometry(2, 4, 2);
        const material = new MeshBasicMaterial({
            color: new Color('green'),
            opacity: 0.3,
            transparent: true,
            visible: false,
        });
        this.hitbox = new Mesh(geometry, material);
        this.hitbox.name = 'Hitbox';
        this.object.add(this.hitbox);
    }

    public setHitboxVisibility(visible: boolean): void {
        if (this.hitbox) {
            this.hitbox.visible = true;
        }
    }

    private addEventListeners(): void {
        this.domElement.addEventListener('mousedown', this.onMouseDown);
        this.domElement.addEventListener('mousemove', this.onMouseMove);
        this.domElement.addEventListener('mouseup', this.onMouseUp);
    }

    private onMouseDown = (event: MouseEvent): void => {
        if (event.button !== 0) return;
        const intersects = this.checkIntersection(event);
        if (intersects.length > 0) {
            this.isDragging = true;
            this.previousMousePosition.set(event.clientX, event.clientY);
        }
    };

    private onMouseMove = (event: MouseEvent): void => {
        if (!this.isDragging || !this.object) return;

        const deltaX = event.clientX - this.previousMousePosition.x;
        const deltaY = event.clientY - this.previousMousePosition.y;

        const absDeltaX = Math.abs(deltaX);
        const absDeltaY = Math.abs(deltaY);

        if (absDeltaX < this.deadzone && absDeltaY < this.deadzone) {
            this.previousMousePosition.set(event.clientX, event.clientY);
            return;
        }

        this.updateCameraVectors();

        const rotationSpeed = 0.01;

        if (absDeltaX > absDeltaY) {
            const verticalAxis = new Vector3(0, 1, 0);

            const yRotation = new Quaternion().setFromAxisAngle(
                verticalAxis,
                -rotationSpeed * deltaX
            );
            this.object.quaternion.premultiply(yRotation);
        } else {
            const xRotation = new Quaternion().setFromAxisAngle(
                this.cameraRightVector,
                rotationSpeed * deltaY
            );
            this.object.quaternion.premultiply(xRotation);
        }

        this.object.updateMatrix();

        if (this.onRotationChange) {
            this.onRotationChange(this.object.quaternion);
        }

        this.previousMousePosition.set(event.clientX, event.clientY);
    };

    private updateCameraVectors(): void {
        const cameraMat = new Matrix4().makeRotationFromQuaternion(this.camera.quaternion);

        this.cameraRightVector.set(1, 0, 0).applyMatrix4(cameraMat);
        this.cameraUpVector.set(0, 1, 0).applyMatrix4(cameraMat);

        this.cameraRightVector.normalize();
        this.cameraUpVector.normalize();
    }

    private onMouseUp = (event: MouseEvent): void => {
        if (event.button !== 0) return;
        this.isDragging = false;
        if (this.onRotationEnd && this.object) {
            this.onRotationEnd(this.object.quaternion);
        }
    };

    private checkIntersection(event: MouseEvent): Object3D[] {
        const mouse = new Vector2(
            (event.clientX / this.domElement.clientWidth) * 2 - 1,
            -(event.clientY / this.domElement.clientHeight) * 2 + 1,
        );
        this.raycaster.setFromCamera(mouse, this.camera);
        //@ts-ignore
        return this.raycaster.intersectObject(this.hitbox, true);
    }

    public dispose(): void {
        this.domElement.removeEventListener('mousedown', this.onMouseDown);
        this.domElement.removeEventListener('mousemove', this.onMouseMove);
        this.domElement.removeEventListener('mouseup', this.onMouseUp);
        if (this.hitbox && this.object) {
            this.object.remove(this.hitbox);
        }
    }
}