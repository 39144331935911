import { autoInjectable, container, singleton } from 'tsyringe';
import VrObject3D from './Three/VrObject3D';
import { Object3D, Box3, Vector3, BoxGeometry, MeshBasicMaterial, Mesh, Quaternion, MeshStandardMaterial } from 'three';
import ColyseusClient from '../Network/ColyseusClient';
import envierments from '../../Environments/envierments';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import EventBus from '../Utils/EventBus';
import { LoadingCrircle } from './ChatBot';
import { CustomizableCylinderHollow } from './ZoomTo/CylinderHollow';
import Camera from '../Camera';
import { InteractionManager } from './Controllers/InteractionManager';
import { SimpleRotationControls } from './SimpleRotationControls';
import UserService from './UserService';
import { SimplifyModifier } from 'three/examples/jsm/modifiers/SimplifyModifier.js';
import { ModelOptimizer } from './ModelOptimizer';

@singleton()
@autoInjectable()
export default class Model3dViewer extends VrObject3D {
    private model3dContainer: Mesh | null = null;
    private model3dPlatform: Object3D;
    public actualModel: Object3D;
    private interactionCube: Mesh;
    private loadingCircle: LoadingCrircle;
    private interactionManager: InteractionManager;
    private rotationControls: SimpleRotationControls;
    private initialModelPosition = new Vector3();
    private initialModelScale = new Vector3();
    private initialModelParent: Object3D | null = null;

    private onModel3dAdded: (() => void) | null = null;
    private onModel3dRemoved: (() => void) | null = null;

    private rotationOn: boolean = false;
    private rotationSpeed: number = 0;
    private modelRotationDirection: 'x' | 'y' = 'y';
    private modelScale: number | null = null;
    private model3dContainerDimention: number = 2.5;

    private dracoLoader = new DRACOLoader();
    private gltfLoader = new GLTFLoader();

    private modelOptimizer = new ModelOptimizer();

    private attached = false;

    public constructor(
        private colyseusClient: ColyseusClient,
        private eventBus: EventBus,
        private camera: Camera,
        private user?: UserService,
    ) {
        super();

        this.setupLoaders();
        this.setupModel3dContainer();
        this.setupEventListeners();
        this.setupLoadingCircle();
        this.setupXRSessionEndListener();
    }

    private setupXRSessionEndListener() {
        if (this.renderer?.webGLRenderer.xr) {
            this.renderer.webGLRenderer.xr.addEventListener('sessionend', () => {
                this.resetModelPosition();
            });
        }
    }

    private simplifyForQuest(model: Object3D): Object3D {
        const modifier = new SimplifyModifier();

        const simplifiedModel = model.clone();

        simplifiedModel.traverse((child) => {
            if (child instanceof Mesh) {
                const geometry = child.geometry;
                const triangles = geometry.index ? geometry.index.count / 3 : geometry.attributes.position.count / 3;

                if (triangles > 5000) {
                    try {
                        const targetTriangles = Math.min(5000, Math.floor(triangles * 0.5));
                        const simplifiedGeometry = modifier.modify(geometry, targetTriangles);
                        child.geometry = simplifiedGeometry;
                    } catch (error) {
                        console.warn(`Failed to simplify mesh: ${error}`);
                    }
                }

                if (child.material) {
                    if (child.material instanceof MeshStandardMaterial) {
                        child.material = new MeshBasicMaterial({
                            map: child.material.map,
                            color: child.material.color,
                            transparent: child.material.transparent,
                            opacity: child.material.opacity,
                        });
                    }
                }
            }
        });

        return simplifiedModel;
    }

    private resetModelPosition() {
        this.initialModelParent.add(this.model3dContainer);

        this.model3dContainer.position.copy(this.initialModelPosition);
        this.model3dContainer.scale.copy(this.initialModelScale);
        this.model3dContainer.rotation.set(0, 0, 0);
        this.model3dContainer.updateMatrixWorld(true);

        this.model3dContainer.visible = true;

        console.log('Model został przywrócony na swoje miejsce');
    }

    private setupLoaders() {
        this.dracoLoader.setDecoderPath('draco/');
        this.dracoLoader.setDecoderConfig({ type: 'js', parallel: true });
        this.gltfLoader.setDRACOLoader(this.dracoLoader);
    }

    private setupModel3dContainer() {
        const boxGeometry = new BoxGeometry(3, 3, 3, 1, 1, 1);

        const material = new MeshBasicMaterial({
            wireframe: false,
            transparent: true,
            opacity: 0,
            depthWrite: false,
            depthTest: false,
        });

        this.model3dContainer = new Mesh(boxGeometry, material);
        this.model3dContainer.position.set(0, 0.5, 0);
        this.model3dContainer.scale.set(1, 1, 1);

        boxGeometry.computeBoundingSphere();
        boxGeometry.computeBoundingBox();

        this.add(this.model3dContainer);
    }

    private setupEventListeners() {
        this.colyseusClient.addEventListener('updateModel3dViewer', (data: any) => this.showModelFromUrl(data));
        this.colyseusClient.addEventListener('modelRotationUpdated', (event: any) =>
            this.updateModelRotation(event.quaternion),
        );
    }

    private setupLoadingCircle() {
        this.loadingCircle = new LoadingCrircle();
        this.loadingCircle.visible = false;
        this.loadingCircle.position.set(0, 0, 0);
        this.add(this.loadingCircle);
    }

    public cleanup() {
        this.rotationControls?.dispose();
    }

    private updateModelRotation(rotation: Quaternion) {
        if (this.actualModel) {
            this.actualModel.quaternion.copy(rotation);
            this.actualModel.updateMatrixWorld(true);
        }
    }

    public showModelFromUrl(data: any) {
        if (!data.changes || data.changes.length === 0) return;

        data.changes.forEach((change: any) => {
            switch (change.field) {
                case 'modelUrl':
                    this.eventBus.dispatchEvent({
                        type: 'model3dVisibilityChanged',
                    });
                    this.changeModel(change.value);
                    break;
                case 'isModelRotating':
                    this.setModelRotating(change.value);
                    break;
                case 'modelRotationSpeed':
                    this.rotationSpeed = change.value;
                    break;
                case 'modelScale':
                    this.modelScale = change.value;
                    this.setModelScale(change.value);
                    break;
                case 'modelRotationDirection':
                    this.modelRotationDirection = change.value === 1 ? 'y' : 'x';
                    break;
                case 'modelVisibility':
                    this.model3dContainer.visible = change.value;
                    break;
            }
        });
    }

    public setModelScale(scale: number) {
        this.model3dContainer.scale.x = scale;
        this.model3dContainer.scale.y = scale;
        this.model3dContainer.scale.z = scale;
    }

    public setCallbacks(callbacks: { onModel3dAdded?: () => void; onModel3dRemoved?: () => void }) {
        this.onModel3dAdded = callbacks.onModel3dAdded || null;
        this.onModel3dRemoved = callbacks.onModel3dRemoved || null;
    }

    private changeModel(url: string) {
        this.model3dContainer.clear();
        this.model3dContainer.rotation.set(0, 0, 0);
        this.loadingCircle.visible = true;

        if (url === 'none') {
            this.removeModelPlatform();
            this.loadingCircle.visible = false;
            this.eventBus.dispatchEvent({ type: 'model3dRemovedFromScene' });
            this.dispatchEvent({ type: 'model3dRemovedFromScene' });
            this.onModel3dRemoved?.();
            this.actualModel = null;
        } else if (url.length > 0) {
            this.loadModel(url);
        }
    }

    private removeModelPlatform() {
        const platformIndex = this.children.findIndex((child) => child.name === 'model-platform');
        if (platformIndex !== -1) {
            this.children[platformIndex].removeFromParent();
        }
    }

    public setupXrInteractions() {
        const interactionManager = container.resolve(InteractionManager);

        const bbox = new Box3();
        const sizeVector = new Vector3();

        if (this.model3dContainer) {
            this.model3dContainer.geometry.computeBoundingSphere();
            this.model3dContainer.geometry.computeBoundingBox();
        }

        let lastController: Object3D | null = null;
        let justAttached = false;

        interactionManager.addInteractiveObject(this.model3dContainer, 1, {
            onSelectStart: (controllerObject: Object3D) => {
                if (!this.attached) {
                    this.initialModelParent = this.model3dContainer.parent;
                    this.initialModelPosition.copy(this.model3dContainer.position);
                    this.initialModelScale.copy(this.model3dContainer.scale);

                    controllerObject.add(this.model3dContainer);
                    lastController = controllerObject;

                    bbox.setFromObject(this.model3dContainer);
                    bbox.getSize(sizeVector);
                    const maxDimension = Math.max(sizeVector.x, sizeVector.y, sizeVector.z);

                    const targetSize = 0.5;
                    const scaleFactor = Math.max(0.01, Math.min(targetSize / maxDimension || 1, 10));

                    this.model3dContainer.scale.multiplyScalar(scaleFactor);
                    this.model3dContainer.position.set(0, 0, -0.15);

                    this.attached = true;
                    justAttached = true;

                    setTimeout(() => {
                        justAttached = false;
                    }, 500);
                }
            },
        });

        const handleControllerSelect = (controller: Object3D) => {
            if (this.attached && lastController === controller && !justAttached) {
                this.resetModelPosition();
                this.attached = false;
                lastController = null;
            }
        };

        this.renderer.webGLRenderer.xr.getController(0).addEventListener('select', () => {
            handleControllerSelect(this.renderer.webGLRenderer.xr.getController(0));
        });

        this.renderer.webGLRenderer.xr.getController(1).addEventListener('select', () => {
            handleControllerSelect(this.renderer.webGLRenderer.xr.getController(1));
        });
    }

    private normalizeModel(model: Object3D) {
        model.position.set(0, 0, 0);
        model.rotation.set(0, 0, 0);
        model.scale.set(1, 1, 1);
        model.updateMatrixWorld(true);

        const bbox = new Box3().setFromObject(model);
        const size = bbox.getSize(new Vector3());
        const center = bbox.getCenter(new Vector3());

        const epsilon = 0.0001;
        if (size.x < epsilon || size.y < epsilon || size.z < epsilon) {
            console.warn('Model has near-zero dimensions, using safe values');
            size.x = Math.max(size.x, epsilon);
            size.y = Math.max(size.y, epsilon);
            size.z = Math.max(size.z, epsilon);
        }

        const maxDimension = Math.max(size.x, size.y, size.z);
        const targetSize = 2.5;
        let scale = targetSize / maxDimension;

        const MAX_SCALE = 1000;
        const MIN_SCALE = 0.001;

        if (scale > MAX_SCALE) {
            scale = MAX_SCALE;
        } else if (scale < MIN_SCALE) {
            scale = MIN_SCALE;
        }

        const yOffset = -8.0;
        model.position.sub(center).add(new Vector3(0, yOffset, 0));

        model.scale.set(scale, scale, scale);
        model.updateMatrixWorld(true);

        const finalBbox = new Box3().setFromObject(model);
        const finalSize = finalBbox.getSize(new Vector3());

        if (finalSize.length() < epsilon) {
            console.error('Model became too small after normalization!');
            model.scale.set(1, 1, 1);
            model.position.set(0, yOffset, 0);
            model.updateMatrixWorld(true);
        }

        return {
            originalSize: size,
            normalizedScale: scale,
            center: center,
            finalSize: finalSize,
        };
    }

    private loadModel(url: string) {
        const wholeUrl = envierments.baseURL + url.replace(/\/\/+/g, '/');
        this.eventBus.sendLoadingStart();
        try {
            this.gltfLoader.load(wholeUrl, (gltf) => {
                this.loadingCircle.visible = false;

                this.actualModel = gltf.scene;
                this.setupModelContainer();
                if (this.user.is_school_teacher || this.renderer.isPrivateScene) {
                    this.setupRotationControls();
                }
                this.setupXrInteractions();
                this.setupModelPlatform();
                this.eventBus.dispatchEvent({
                    type: 'model3dVisibilityChanged',
                });
                this.eventBus.dispatchEvent({ type: 'model3dAddedToScene' });
                this.dispatchEvent({ type: 'model3dAddedToScene' });
                this.eventBus.sendLoadingEnd();
                this.onModel3dAdded?.();
            });
        } catch (e) {
            this.eventBus.sendLoadingEnd();
            console.error(e);
        }
    }

    private centerModel(model: Object3D) {
        const bbox = new Box3().setFromObject(model);
        const center = bbox.getCenter(new Vector3());

        model.position.sub(center);

        model.updateMatrixWorld(true);

        const newBbox = new Box3().setFromObject(model);
        const newCenter = newBbox.getCenter(new Vector3());

        const epsilon = 0.0001;
        if (Math.abs(newCenter.x) > epsilon || Math.abs(newCenter.y) > epsilon || Math.abs(newCenter.z) > epsilon) {
            console.warn('Model not perfectly centered. Residual offset:', newCenter);
        }

        return {
            originalCenter: center,
            newCenter: newCenter,
            offset: model.position.clone(),
        };
    }

    private setupModelContainer = () => {
        this.normalizeModel(this.actualModel);
        this.centerModel(this.actualModel);

        this.model3dContainer.add(this.actualModel);
    };

    private setupRotationControls() {
        this.rotationControls?.dispose();
        this.rotationControls = new SimpleRotationControls(
            this.renderer.webGLRenderer.domElement,
            this.camera.instance,
        );
        this.rotationControls.attach(this.model3dContainer);
        this.rotationControls.onRotationChange = (rotation: Quaternion) => {
            this.colyseusClient.updateModelRotationQuaternion(rotation);

            //@ts-ignore
            this.camera.disableOrbitControls();
        };

        this.rotationControls.setHitboxVisibility(false);

        this.rotationControls.onRotationEnd = () => {
            //@ts-ignore
            this.camera.enableOrbitControls();
        };
    }

    private setupModelPlatform() {
        if (!this.model3dPlatform) {
            this.model3dPlatform = new CustomizableCylinderHollow(0.3, 0.25, 0.01, 0, 32);
            this.model3dPlatform.rotation.x = Math.PI / 2;
            this.model3dPlatform.position.set(0, -1.2, 0);
            this.model3dPlatform.scale.set(5, 5, 5);
            this.model3dPlatform.name = 'model-platform';
        }

        if (!this.children.some((child) => child.name === 'model-platform')) {
            this.add(this.model3dPlatform);
        }
    }

    private setModelRotating(value: boolean) {
        this.rotationOn = value;
    }

    public update() {
        this.loadingCircle.visible && this.loadingCircle.update();

        if (this.rotationOn && this.actualModel) {
            if (this.modelRotationDirection === 'x') {
                this.actualModel.rotation.x += this.rotationSpeed ? this.rotationSpeed : 0;
            } else {
                this.actualModel.rotation.y += this.rotationSpeed ? this.rotationSpeed : 0;
            }
        }
    }
}
