import { EventDispatcher } from 'three';
import { singleton } from 'tsyringe';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import {
    AssistantsResponse,
    SendMessageRequest,
    SendMessageResponse,
} from './types/assistants';
import envierments from '../../../Environments/envierments';

@singleton()
export default class OpenAiClient extends EventDispatcher {
    private assistantID: string | null = envierments.openAi.assistantId;

    public constructor(public httpClient?: AxiosHttpClient) {
        super();
    }

    public async init() {
        // const assistants =
        //     await this.httpClient.instance.get<AssistantsResponse>(
        //         'api/v2/openai/assistants',
        //     );
        //
        // this.assistantID = assistants.data.first_id;
    }

    public async transcriptAudio(audioBase64: string) {
        const cleanBase64 = audioBase64.replace(
            /^data:audio\/(wav|webm|ogg|mp3);base64,/,
            '',
        );

        return await this.httpClient.instance.post(
            'api/v2/openai/audio/transcriptions',
            {
                file: cleanBase64,
                model: 'whisper-1',
                language: 'pl',
            },
        );
    }

    public async textToSpeech(text: string) {
        return await this.httpClient.instance.post(
            'api/v2/openai/audio/speech',
            {
                text,
                model: 'tts-1',
                voice: 'alloy',
                response_format: 'mp3',
                speed: 1.0,
            },
            {
                responseType: 'arraybuffer', // Dodajemy to!
            },
        );
    }

    public async sendMessage(
        request: SendMessageRequest,
    ): Promise<SendMessageResponse> {
        if (!this.assistantID) {
            throw new Error('Assistant ID not initialized');
        }

        const response =
            await this.httpClient.instance.post<SendMessageResponse>(
                `api/v2/openai/assistants/${this.assistantID}/send_message`,
                request,
            );

        return response.data;
    }

    public async setAssistant(assistantId: string) {
        this.assistantID = assistantId;
    }

    public getAssistantId(): string | null {
        return this.assistantID;
    }

    public async sendAudioMessage(audioBase64: string, threadId?: string) {
        const transcribedText = await this.transcriptAudio(audioBase64);

        return await this.sendMessage({
            text: transcribedText.data,
        });
    }
}
