import { Loader } from 'three';
import AxiosHttpClient from '../Network/AxiosHttpClient';
import { Texture } from 'three/src/textures/Texture';

export default class HttpLoader extends Loader {
    public constructor(public httpClient: AxiosHttpClient) {
        super();
    }

    public load(
        url,
        onLoad?: (data: any) => void,
        onProgress?: (event: ProgressEvent) => void,
        onError?: (event: ErrorEvent) => void,
    ) {
        this.httpClient.instance
            .get<any>(url)
            .then((res) => {
                onLoad(res.data);
            })
            .catch((e) => {
                console.error(e);
            });
    }

    public loadAsync(
        url: string,
        onProgress?: (event: ProgressEvent) => void,
    ): Promise<any> {
        return this.httpClient.instance.get<any>(url);
    }

    public setCrossOrigin(crossOrigin: string): this {
        return this;
    }
    public setWithCredentials(value: boolean): this {
        return this;
    }
    public setPath(path: string): this {
        return this;
    }
    public setResourcePath(resourcePath: string): this {
        return this;
    }
    public setRequestHeader(requestHeader: { [header: string]: string }): this {
        return this;
    }
}
