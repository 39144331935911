import { Color, Object3D, Quaternion, Vector3 } from 'three';
import Resources from '../../Resources';
import DeviceDetector from 'device-detector-js';

export enum bodyTypes {
    default = 'default',
    teacher = 'teacher',
    student = 'student',
    computer = 'computer',
    vrHeadset = 'vrHeadset',
}

export enum headTypes {
    default = 'default',
    head1 = 'head1',
    vrHeadset = 'vrHeadset',
    computer = 'computer',
}
export enum handsTypes {
    default = 'default',
    vrHeadset = 'vrHeadset',
    computer = 'computer',
}

export interface PlayerAppearance {
    name: string;
    head: {
        type: headTypes;
        model: Object3D;
        part: part;
    };
    cheast: {
        type: bodyTypes;
        model: Object3D;
        part: part;
    };
    hands: {
        type: handsTypes;
        model: Object3D;
        part: part;
    };
    color: Color;
}

export interface part {
    visible: boolean;
    position: Vector3;
    scale: number;
    rotation: Quaternion;
    cameraQuaterion?: Quaternion;
    xr: {
        visible: boolean;
        headsetRotation: boolean;
        cameraPosition?: Vector3;
    };
}

export class PlayerSkins {
    private skins: PlayerAppearance[] = [];

    public constructor(private resources: Resources) {
        this.addSkin({
            name: 'default',
            head: {
                type: headTypes.default,
                model: this.resources.items.head.scene.clone(),
                part: {
                    visible: true,
                    position: new Vector3(0, -1.4, 0),
                    rotation: new Quaternion(),
                    scale: 0.01,
                    xr: {
                        visible: false,
                        headsetRotation: true,
                    },
                },
            },
            cheast: {
                type: bodyTypes.default,
                model: this.resources.items.body.scene.clone(),
                part: {
                    visible: true,
                    position: new Vector3(0, -1.9, 0),
                    rotation: new Quaternion(),
                    scale: 0.01,
                    xr: {
                        visible: true,
                        headsetRotation: true,
                    },
                },
            },
            hands: {
                type: handsTypes.default,
                model: this.resources.items.body.scene.clone(),
                part: {
                    visible: true,
                    position: new Vector3(0, 0, 0),
                    rotation: new Quaternion(),
                    scale: 0.02,
                    xr: {
                        visible: true,
                        headsetRotation: true,
                    },
                },
            },
            color: new Color(0x00ff00),
        });

        this.addSkin({
            name: 'computer',
            head: {
                type: headTypes.computer,
                model: this.resources.items.robot4head.scene,
                part: {
                    visible: true,
                    position: new Vector3(-0.03, -1.6, 0),
                    rotation: new Quaternion(),
                    scale: 3,
                    xr: {
                        visible: false,
                        headsetRotation: true,
                        cameraPosition: new Vector3(0, -1, 0),
                    },
                },
            },
            cheast: {
                type: bodyTypes.computer,
                model: this.resources.items.robot4body.scene,
                part: {
                    visible: true,
                    position: new Vector3(0.1, -2.5, 0),
                    rotation: new Quaternion(),
                    scale: 5,
                    xr: {
                        visible: false,
                        headsetRotation: true,
                    },
                },
            },
            hands: {
                type: handsTypes.computer,
                model: new Object3D(),
                part: {
                    visible: false,
                    position: new Vector3(0, -2, 0),
                    rotation: new Quaternion(),
                    scale: 5,
                    xr: {
                        visible: false,
                        headsetRotation: true,
                    },
                },
            },
            color: new Color('blue'),
        });

        this.addSkin({
            name: 'vrHeadset',
            head: {
                type: headTypes.vrHeadset,
                model: this.resources.items.robot2head.scene,
                part: {
                    visible: true,
                    position: new Vector3(0, -1.6, 0),
                    rotation: new Quaternion(),
                    cameraQuaterion: new Quaternion(),
                    scale: 3,
                    xr: {
                        visible: false,
                        headsetRotation: true,
                        cameraPosition: new Vector3(0, -1, 0),
                    },
                },
            },
            cheast: {
                type: bodyTypes.vrHeadset,
                model: this.resources.items.robot2body.scene,
                part: {
                    visible: true,
                    position: new Vector3(0, -2, 0),
                    rotation: new Quaternion(),
                    scale: 5,
                    xr: {
                        visible: false,
                        headsetRotation: true,
                    },
                },
            },
            hands: {
                type: handsTypes.default,
                model: this.resources.items.robotHands.scene,
                part: {
                    visible: true,
                    position: new Vector3(-0.45, 1, 0),
                    rotation: new Quaternion(),
                    scale: 0.05,
                    xr: {
                        visible: false,
                        headsetRotation: true,
                    },
                },
            },
            color: new Color('blue'),
        });
    }

    public addSkin(skin: PlayerAppearance) {
        this.skins.push(skin);
    }

    public getFromUserAgent(userAgent: any) {
        const deviceDetector = new DeviceDetector();
        const device = deviceDetector.parse(userAgent);

        if (device.client.name === 'Oculus Browser') {
            return this.getSkin('vrHeadset');
        }

        if (device.device.type === 'smartphone') {
            return this.getSkin('vrHeadset');
        }

        return this.getSkin('computer');
    }

    public getSkin(name: string): PlayerAppearance {
        for (const skin in this.skins) {
            if (this.skins[skin].name == name) {
                return this.skins[skin];
            }
        }
    }

    public getSkins(): PlayerAppearance[] {
        return this.skins;
    }
}
