import IScene from '../../Scenes/IScene';
import Time from '../../Utils/Time';
import { autoInjectable, container } from 'tsyringe';
import Renderer from '../Renderer';
import MainPlayer from '../Player/MainPlayer';
import { PlayerSkins } from '../Player/PlayerAppearance';
import Player from '../Player/Player';
import {
    AudioListener,
    Color,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Quaternion,
    Scene,
    SphereGeometry,
    Vector3,
    sRGBEncoding,
    MathUtils,
    AmbientLight,
    PointLight,
    Box3,
    Box3Helper,
    MeshStandardMaterial,
    VideoTexture,
    LinearFilter,
    PlaneGeometry,
} from 'three';
import ColyseusClient from '../../Network/ColyseusClient';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import Resources from '../../Resources';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import Camera from '../../Camera';
import TeleportationRing from '../Player/TeleportationRing';
import DeviceDetector from 'device-detector-js';
import { Room } from 'colyseus.js';
import DemoService from '../../DemoService';
import PrivateRoomScene from '../../Scenes/PrivateRoom/PrivateRoomScene';
import { JanusClient } from '../../react/Janus/rx/JanusClient';
import { InteractionManager } from '../Controllers/InteractionManager';
import { pointer as hoverRing } from '../Player/assets/components';
import { SRGBColorSpace } from 'three/src/constants';

export enum Rooms {
    Lobby = 1,
    GroupRoom = 3,
    Classroom = 4,
    SchoolLobby = 5,
    classroomChemistry = 6,
    classroomPhysics = 7,
    classroomMath = 8,
    privateRoom = 9,
}

declare global {
    interface Window {
        roomInfo: any;
    }
}

@autoInjectable()
export default class VrScene extends Scene implements IScene {
    public name = 'VrScene';

    public onUpdate: any;

    public roomId: number;

    public roomName: string;

    public mainPlayer: MainPlayer;

    public sphereBackground: Mesh;

    public ringGrid: Object3D = new Object3D();

    public room: Room;

    public screenSharing: boolean = false;

    public boundingBox: Box3 | null = null;
    public roomInfo: {
        sceneToGo: VrScene;
        roomId: number;
        multiplayer: boolean;
        sceneName?: string;
    };

    public interactionManager: InteractionManager;

    private _screenShareStopHandlers: ((event: any) => void)[] = [];

    public projector: Mesh;

    public sceneNameForLabel: string = null;

    public janusCommunicationService: JanusClient | null = null;

    public onUserSpeakingEvent: (event: any) => void;
    public onPublisherLeftEvent: (event: any) => void;

    private videoCheckInterval: number | null = null;
    private activeVideoElement: HTMLVideoElement | null = null;
    private lastVisibilityChange: number = 0;
    private pendingVisibilityChange: boolean | null = null;

    public constructor(
        public time?: Time,
        public renderer?: Renderer,
        public intersectionContainer?: IntersectionContainer,
        public resources?: Resources,
        public colyseusClient?: ColyseusClient,
        public httpClient?: AxiosHttpClient,
        public camera?: Camera,
        public demoService?: DemoService,
    ) {
        super();
        try {
            this.resources.items.envmap.encoding = sRGBEncoding;
        } catch (e) {
            console.error(e);
        }
        this.janusCommunicationService = container.resolve(JanusClient);

        const pointLight = new PointLight(0xffffff, 1000, 0);
        pointLight.position.set(0, 10, 0);
        pointLight.castShadow = false;
        this.add(pointLight);

        const ambientLight = new AmbientLight(0xffffff, 0.6);
        this.add(ambientLight);

        const geometry = new SphereGeometry(500, 60, 40);
        geometry.scale(-1, 1, 1);

        const material = new MeshStandardMaterial({
            map: this.resources.items.envmap,
            emissiveMap: this.resources.items.envmap,
            emissive: new Color(0xffffff),
            emissiveIntensity: 1,
            normalMap: this.resources.items.envmap,
            envMap: this.resources.items.envmap,
        });
        this.sphereBackground = new Mesh(geometry, material);

        this.interactionManager = container.resolve(InteractionManager);
        this.add(this.sphereBackground);

        this.setupVideoObserver();

        this.addEventListener('dispose', () => {});
    }

    public startLoop() {
        this.onUpdate = this.time.on('tick', (time) => this.update(time));
    }

    public start() {}

    public update(time?: any) {}

    public destroy() {
        this.cleanupVideoProjector();

        this.cleanupJanusRoom();
        this.cleanSketchboard();

        if (this.onUpdate) {
            this.time.remove(this.onUpdate);
        }
    }

    public xrSessionStart(event?) {}

    public xrSessionEnd(event) {}

    public xrUpdate() {}

    public onChange(changes) {}

    public onJoin(state: any) {}

    public async leave() {}

    protected addBoundingBox(object: Mesh, addHelper = false) {
        this.boundingBox = new Box3().setFromObject(object);
        const height = 5;
        this.boundingBox.max.y = object.position.y + height;

        if (addHelper) {
            const helper = new Box3Helper(this.boundingBox, new Color(0xffff00));
            this.add(helper);
        }
    }

    public movePlayerOutsideCenter(
        mainPlayer,
        moveCamera = false,
        spawnArea = {
            minX: 5,
            maxX: 9,
            minZ: -5,
            maxZ: 5,
            y: -2.5,
        },
    ) {
        const { minX, maxX, minZ, maxZ, y } = spawnArea;

        const randomInRange = (min, max) => Math.random() * (max - min) + min;

        const x = randomInRange(minX, maxX);
        const z = randomInRange(minZ, maxZ);

        mainPlayer.movePlayer(new Vector3(x, y, z), moveCamera);
    }

    public async joinRoom(
        roomId: number,
        multiplayer: boolean = false,
        vrScene: VrScene | null = null,
        janus: boolean = true,
    ) {
        console.log('!!!!!!!!!!!!!!!!!!!!!!!!! JOIN ROOM !!!!!!!!!!!!!!!!!!!!!!!!!!!');

        this.colyseusClient.isMultiplayer = multiplayer;

        if (this.janusCommunicationService && this.janusCommunicationService.getCurrentRoom() !== null) {
            try {
                await this.janusCommunicationService.leaveRoom();
            } catch (error) {
                console.error(error);
            }
        }

        const user = this.resources.items.user;
        const playerSkins = new PlayerSkins(this.resources);

        try {
            await this.janusCommunicationService.joinRoom(roomId, user.first_name, {
                audioSend: true,
                videoSend: false,
                audio: {
                    mandatory: {
                        echoCancellation: true,
                    },
                },
            });

            this.setupTalkingDetection();
        } catch (error) {
            console.error('Failed to initialize/join Janus:', error);
        }

        if (!this.mainPlayer) {
            this.mainPlayer = new MainPlayer(playerSkins.getFromUserAgent(navigator.userAgent));

            this.mainPlayer.name = user.first_name;
            this.mainPlayer.userData.id = user.id;
            this.mainPlayer.userData.username = user.first_name;

            this.mainPlayer.initUser();
            this.mainPlayer.start(this);
            this.add(this.mainPlayer);
        }

        if (multiplayer) {
            const roomExists = await this.colyseusClient.roomExists(roomId);
            if (!roomExists) {
                await this.colyseusClient.createRoom({
                    roomType: roomId.toString(),
                    username: user.first_name,
                    id: user.id,
                    color: this.setColor(),
                    userAgent: navigator.userAgent,
                    isTeacher: this.resources.items.user.is_school_teacher,
                });
            }

            if (roomExists) {
                await this.colyseusClient.joinById(roomId, {
                    username: user.first_name,
                    color: this.setColor(),
                    id: user.id,
                    userAgent: navigator.userAgent,
                    isTeacher: this.resources.items.user.is_school_teacher,
                });
            }

            this.onJoin(this.colyseusClient.room.state);

            if (vrScene && vrScene.name === 'classroom') {
                this.movePlayerOutsideCenter(this.mainPlayer, true);
            } else {
                this.mainPlayer.movePlayer(new Vector3(Math.random() * 2 - 1, -2.5, Math.random() * 2 - 1), false);
            }

            this.colyseusClient.room.state.onChange = (changes) => {
                this.onChange(changes);
            };

            setTimeout(() => {
                this.colyseusClient.room.state.sketchboard.ctxPaths.onAdd = (ctxPath) => {
                    this.drawPathToSketchBoards('sketchboard', ctxPath);
                };

                this.colyseusClient.room.state.sketchboard2.ctxPaths.onAdd = (ctxPath) => {
                    this.drawPathToSketchBoards('sketchboard2', ctxPath);
                };

                this.colyseusClient.room.state.sketchboard3.ctxPaths.onAdd = (ctxPath) => {
                    this.drawPathToSketchBoards('sketchboard3', ctxPath);
                };

                this.colyseusClient.room.state.sketchboard.ImageArray.onAdd = (image: any) => {
                    this.drawImagesToSketchBoards('sketchboard', image);
                };

                this.colyseusClient.room.state.sketchboard2.ImageArray.onAdd = (image: any) => {
                    this.drawImagesToSketchBoards('sketchboard2', image);
                };

                this.colyseusClient.room.state.sketchboard3.ImageArray.onAdd = (image: any) => {
                    this.drawImagesToSketchBoards('sketchboard3', image);
                };

                const combinedDrawables1 = [];
                const combinedDrawables2 = [];
                const combinedDrawables3 = [];

                this.colyseusClient.room.state.sketchboard.ImageArray.forEach((image) => {
                    combinedDrawables1.push({
                        type: 'image',
                        data: image,
                    });
                });
                this.colyseusClient.room.state.sketchboard.ctxPaths.forEach((ctxPath) => {
                    combinedDrawables1.push({
                        type: 'ctxPath',
                        data: ctxPath,
                    });
                });

                this.colyseusClient.room.state.sketchboard2.ImageArray.forEach((image) => {
                    combinedDrawables2.push({
                        type: 'image',
                        data: image,
                    });
                });

                this.colyseusClient.room.state.sketchboard2.ctxPaths.forEach((image) => {
                    combinedDrawables2.push({
                        type: 'ctxPath',
                        data: image,
                    });
                });

                this.colyseusClient.room.state.sketchboard3.ImageArray.forEach((image) => {
                    combinedDrawables3.push({
                        type: 'image',
                        data: image,
                    });
                });
                this.colyseusClient.room.state.sketchboard3.ctxPaths.forEach((ctxPath) => {
                    combinedDrawables3.push({
                        type: 'ctxPath',
                        data: ctxPath,
                    });
                });

                combinedDrawables1.sort((a, b) => a.data.time - b.data.time);
                combinedDrawables2.sort((a, b) => a.data.time - b.data.time);
                combinedDrawables3.sort((a, b) => a.data.time - b.data.time);

                this.drawDrawablesToSketchBoards('sketchboard', combinedDrawables1);
                this.drawDrawablesToSketchBoards('sketchboard2', combinedDrawables2);
                this.drawDrawablesToSketchBoards('sketchboard3', combinedDrawables3);
            }, 1000);

            if (this.colyseusClient.room.state.ModelViewer) {
                this.colyseusClient.room.state.ModelViewer.onChange = (changes) => {
                    this.colyseusClient.dispatchEvent({
                        type: 'updateModel3dViewer',
                        changes,
                    });
                };
            }

            const listener = new AudioListener();
            this.add(listener);

            this.colyseusClient.room.state.players.onAdd = async (player, sessionId) => {
                if (sessionId === this.colyseusClient.room.sessionId) {
                    return;
                }

                const playerSkin = playerSkins.getFromUserAgent(player.userAgent);

                const playerInstance = new Player(playerSkin, new Color(player.color), player.username, this.camera);

                playerInstance.name = player.username;
                playerInstance.userData.id = player.id;
                playerInstance.userData.username = player.username;

                if (player.isTeacher) {
                    playerInstance.addUsernameText(player.username, new Color('green'));

                    playerInstance.scale.set(1.2, 1.2, 1.2);
                } else {
                    playerInstance.addUsernameText(player.username);
                }

                this.add(playerInstance);
                playerInstance.initUser();

                player.onChange = () => {
                    playerInstance.position.set(player.x, player.y, player.z);

                    if (player.controllerX !== undefined) {
                        playerInstance
                            .getObjectByName('rightHand')
                            .position.set(player.controllerX, player.controllerY + 1.2, player.controllerZ);
                        const quaternion = JSON.parse(player.controllerRotation);

                        const rightHand = playerInstance.getObjectByName('rightHand');

                        rightHand.quaternion.set(quaternion.x, quaternion.y, quaternion.z, quaternion.w);

                        rightHand.rotation.z += MathUtils.degToRad(-180);
                        rightHand.rotation.x += MathUtils.degToRad(-180);

                        const leftHand = playerInstance.getObjectByName('leftHand');

                        leftHand.position.set(
                            player.controllerRightX,
                            player.controllerRightY + 1.2,
                            player.controllerRightZ,
                        );
                        const secondQuaternionJson = JSON.parse(player.controllerSecondRotation);
                        const secondQuaternion = new Quaternion(
                            secondQuaternionJson.x,
                            secondQuaternionJson.y,
                            secondQuaternionJson.z,
                            secondQuaternionJson.w,
                        );
                        secondQuaternion.invert();

                        leftHand.quaternion.set(
                            secondQuaternion.x,
                            secondQuaternion.y,
                            secondQuaternion.z,
                            secondQuaternion.w,
                        );
                    }

                    playerInstance.head.quaternion.set(player.headX, player.headY, player.headZ, player.headW);
                };

                player.onRemove = () => {
                    this.remove(playerInstance);
                };
            };
        }

        this.manageHtmlMenu(this.name);
        //@ts-ignore
        window.resetLessonSceneType();

        if (this.hasOwnProperty('skyboxChanger')) {
            //@ts-ignore
            this.skyboxChanger.addEventListener('customSkyboxLoaded', () => {
                this.changeSceneAnimationStop();
            });
        } else {
            this.changeSceneAnimationStop();
        }
    }

    public removeJanusEventListeners() {
        if (this.janusCommunicationService) {
            if (this.onUserSpeakingEvent) {
                this.janusCommunicationService.removeEventListener('userSpeaking', this.onUserSpeakingEvent);
            }
            if (this.onPublisherLeftEvent) {
                this.janusCommunicationService.removeEventListener('publisherLeft', this.onPublisherLeftEvent);
            }
        }
    }

    public setupTalkingDetection() {
        if (!this.janusCommunicationService) {
            console.error('Brak dostępu do serwisu Janus');
            return;
        }

        this.removeJanusEventListeners();

        this.onUserSpeakingEvent = (event) => {
            const { feedId, talking, displayName, userId } = event.detail;
            if (userId) {
                if (this.mainPlayer && this.mainPlayer.userData && this.mainPlayer.userData.id === userId) {
                    this.mainPlayer.setTalking(talking);
                    return;
                }

                let foundByUserId = false;
                this.traverse((object) => {
                    if (object instanceof Player && object.userData && object.userData.id === userId) {
                        object.setTalking(talking);
                        foundByUserId = true;
                    }
                });

                if (foundByUserId) return;
            }

            if (displayName) {
                if (this.mainPlayer && this.mainPlayer.name === displayName) {
                    this.mainPlayer.setTalking(talking);

                    return;
                }

                let foundByName = false;
                this.traverse((object) => {
                    if (object instanceof Player && object.name === displayName) {
                        object.setTalking(talking);
                        foundByName = true;
                    }
                });

                if (foundByName) return;
            }

            this.traverse((object) => {
                if (
                    object instanceof Player &&
                    object.userData &&
                    object.userData.username &&
                    displayName &&
                    object.userData.username === displayName
                ) {
                    object.setTalking(talking);
                }
            });

            if (!userId && !displayName) {
                this.traverse((object) => {
                    if (
                        object instanceof Player &&
                        object.userData &&
                        object.userData.id &&
                        object.userData.id.toString() === feedId.toString()
                    ) {
                        object.setTalking(talking);
                    }
                });
            }
        };

        this.onPublisherLeftEvent = (event) => {
            const { feedId, displayName, userId } = event.detail;

            if (userId) {
                this.traverse((object) => {
                    if (object instanceof Player && object.userData && object.userData.id === userId) {
                        if (object.setTalking) {
                            object.setTalking(false);
                        }
                    }
                });
            }

            if (displayName) {
                this.traverse((object) => {
                    if (object instanceof Player && object.name === displayName) {
                        if (object.setTalking) {
                            object.setTalking(false);
                        }
                    }
                });
            }

            this.traverse((object) => {
                if (
                    object instanceof Player &&
                    object.userData &&
                    object.userData.id &&
                    object.userData.id.toString() === feedId.toString()
                ) {
                    if (object.setTalking) {
                        object.setTalking(false);
                    }
                }
            });
        };

        this.janusCommunicationService.addEventListener('userSpeaking', this.onUserSpeakingEvent);
        this.janusCommunicationService.addEventListener('publisherLeft', this.onPublisherLeftEvent);
    }

    public drawDrawablesToSketchBoards(sketchboardName: string, allDrawables: any[]) {}

    public drawImagesToSketchBoards(sketchBoardName: string, imagesToDraw: any) {}

    public drawPathToSketchBoards(sketchBoardName: string, ctxPaths: any) {}

    public async changeScene(sceneToGo: VrScene, roomId?: number, multiplayer?: boolean, sceneName?: string) {
        this.destroy();
        this.onLeave();
        this.cleanSketchboard();
        this.changeSceneAnimationStart();

        await this.cleanupJanusRoom();

        this.roomInfo = {
            sceneToGo,
            roomId,
            multiplayer,
            sceneName,
        };
        //@ts-ignore
        window.hideAllPanelFromMenuHtml();

        //@ts-ignore
        window.unselectAllBtnFromMenuHtml();

        this.colyseusClient.isMultiplayer;

        if (this.colyseusClient.isMultiplayer) {
            this.colyseusClient.leaveRoom();
            this.remove();
            this.remove(...this.children);
            this.removeFromParent();
            this.camera.setDefaultCameraPosition();

            this.intersectionContainer.reset();

            this.renderer.changeRenderLoopScene(sceneToGo);

            sceneToGo.joinRoom(roomId, multiplayer, sceneToGo);
            sceneToGo.roomId = roomId;
            sceneToGo.roomName = sceneName;
            sceneToGo.roomInfo = {
                sceneToGo,
                roomId,
                sceneName,
                multiplayer,
            };

            if (
                sceneToGo.screenSharing &&
                (this.resources.items.user.is_school_teacher || this.resources.items.user.id === roomId)
            ) {
                document.querySelector('.janus-buttons').classList.add('active');
            } else {
                document.querySelector('.janus-buttons').classList.remove('active');
            }

            sceneToGo.start();

            this.addFloorInteractions(sceneToGo);

            sceneToGo.startLoop();

            window['app'].scene = sceneToGo;

            sceneToGo.mainPlayer = this.mainPlayer as MainPlayer;
            try {
                sceneToGo.mainPlayer.start(sceneToGo);
                sceneToGo.add(sceneToGo.mainPlayer);
                sceneToGo.mainPlayer.inviteReceiver.removeInviteBlock();

                if (this.renderer.webGLRenderer.xr.isPresenting) {
                    sceneToGo.mainPlayer.xrSessionStart();
                    sceneToGo.mainPlayer.movePlayerXr(new Vector3(0, -2.5, 0));
                } else {
                    sceneToGo.mainPlayer.movePlayer(new Vector3(0, -2.5, 0));
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    public addFloorInteractions(sceneToGo: VrScene) {
        const floor = sceneToGo.getObjectByName('floorGroup');

        const marker = hoverRing().clone();
        marker.visible = false;
        sceneToGo.add(marker);

        let isActiveControllerHovering = false;

        this.interactionManager.addInteractiveObject(
            floor,
            0,
            {
                onHoverStart: (controller) => {
                    if (controller === this.interactionManager.getActiveController()) {
                        isActiveControllerHovering = true;
                    }
                },
                onHover: (controller, intersection) => {
                    if (controller === this.interactionManager.getActiveController()) {
                        isActiveControllerHovering = true;
                        marker.visible = true;
                        marker.position.set(intersection.point.x, intersection.point.y, intersection.point.z);
                    }
                },
                onHoverEnd: (controller) => {
                    if (controller === this.interactionManager.getActiveController()) {
                        isActiveControllerHovering = false;
                        marker.visible = false;
                    }
                },
                onSelectEnd: (controller, intersection) => {
                    if (controller === this.interactionManager.getActiveController()) {
                        sceneToGo.mainPlayer.movePlayerXr(intersection.point);
                    }
                },
            },
            true,
        );

        this.interactionManager.addEventListener('controllerDeactivated', () => {
            marker.visible = false;
            isActiveControllerHovering = false;
        });
    }

    public manageHtmlMenu(sceneName) {
        const menuBtnNameIndex = {
            LobbyScene: 3,
            PrivateRoom: 4,
            SchoolLobby: 5,
            classroom: 6,
            Group: 7,
        };

        if (menuBtnNameIndex[sceneName]) {
            if (menuBtnNameIndex[sceneName] === 6 || menuBtnNameIndex[sceneName] === 7) {
                //@ts-ignore
                window.selectBtnLessonScene(menuBtnNameIndex[sceneName]);
            } else {
                //@ts-ignore
                window.selectBtnScene(menuBtnNameIndex[sceneName]);
            }
        }
    }

    public changeSceneAnimation(ms) {
        this.changeSceneAnimationStart();
    }

    public changeSceneAnimationStart() {
        const animationContainer = document.getElementById('view-change-scene-animation');
        animationContainer.style.opacity = '1';
        animationContainer.style.visibility = 'visible';
        animationContainer.style.display = 'block';

        animationContainer.oncontextmenu = () => {
            return false;
        };
    }

    public changeSceneAnimationStop() {
        const animationContainer = document.getElementById('view-change-scene-animation');
        animationContainer.style.opacity = '0';
        animationContainer.style.visibility = 'hidden';
    }

    public cleanSketchboard() {
        if (this.renderer.scene.name === 'classroom' || this.renderer.scene.name === 'Group') {
            let canvasWrapper = document.getElementById('canvas-elements');

            if (canvasWrapper.hasChildNodes()) {
                while (canvasWrapper.hasChildNodes()) {
                    canvasWrapper.removeChild(canvasWrapper.lastChild);
                }
            }
        }
    }

    public onLeave() {}

    public async cleanupJanusRoom(): Promise<void> {
        if (!this.janusCommunicationService) {
            return;
        }

        this.cleanupVideoProjector();

        this.traverse((object) => {
            if (object instanceof Player && object.setTalking) {
                object.setTalking(false);
            }
        });

        this.removeJanusEventListeners();

        if (this.janusCommunicationService.getCurrentRoom() !== null) {
            const currentRoom = this.janusCommunicationService.getCurrentRoom();

            try {
                if (this.janusCommunicationService.publisherHandle?.stream) {
                    const tracks = this.janusCommunicationService.publisherHandle.stream.getTracks();
                    for (const track of tracks) {
                        try {
                            track.stop();
                        } catch (e) {}
                    }
                }

                if (this.janusCommunicationService.publisherHandle?.plugin) {
                    const unpublishPromise = new Promise<void>((resolve) => {
                        try {
                            this.janusCommunicationService.publisherHandle.plugin.send({
                                message: { request: 'unpublish' },
                                success: () => {
                                    resolve();
                                },
                                error: (e: any) => {
                                    console.warn('Błąd podczas unpublish:', e);
                                    resolve();
                                },
                            });
                        } catch (e) {
                            console.warn('Wyjątek podczas unpublish:', e);
                            resolve();
                        }
                    });

                    await Promise.race([unpublishPromise, new Promise((r) => setTimeout(r, 1000))]);
                }

                await this.janusCommunicationService.leaveRoom();

                this.janusCommunicationService.dispatchEvent({
                    type: 'roomLeft',
                    detail: {
                        previousRoomId: currentRoom,
                    },
                });

                if (this.janusCommunicationService.publisherHandle?.plugin?.webrtcStuff?.pc) {
                    try {
                        const pc = this.janusCommunicationService.publisherHandle.plugin.webrtcStuff.pc;
                        pc.close();
                        console.log('PeerConnection closed');
                    } catch (e) {
                        console.warn('Error while closing PeerConnection:', e);
                    }
                }

                await new Promise((resolve) => setTimeout(resolve, 500));
            } catch (error) {
                try {
                    this.janusCommunicationService.disconnect();

                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    await this.janusCommunicationService.connect();
                } catch (e) {
                    console.error(e);
                }
            }
        } else {
            console.log('No active room to left');
        }
    }

    public setTpRings(xrow: number = 10, yrow: number = 10, position: Vector3 = new Vector3(-8, -2.45, -8)) {
        const deviceDetector = new DeviceDetector();
        const device = deviceDetector.parse(navigator.userAgent);

        const ringGrid = new Object3D();

        if (device.client.name === 'Oculus Browser') {
            return;
        }

        if (device.device.type === 'desktop') {
            return;
        }

        ringGrid.name = 'ringGrid';

        for (let x = 1; x < xrow; x++) {
            for (let y = 1; y < yrow; y++) {
                const ring = new TeleportationRing();
                ring.position.set(x + 3, 0, y + 3);
                this.intersectionContainer.addObjectToIntersect(ring, false, false);

                ringGrid.add(ring);
            }
        }

        ringGrid.position.copy(position);

        this.add(ringGrid);
    }

    public setColor = () => {
        const colors = ['red', 'blue', 'green', 'white', 'lightblue', 'grey', 'pink', 'brown'];

        return colors[Math.floor(Math.random() * (colors.length + 1))];
    };

    public setupVideoProjector(
        position = new Vector3(0, 2, -4),
        rotation = new Vector3(0, 0, 0),
        size = { width: 3, height: 1.7 },
    ) {
        if (this.projector) {
            this.remove(this.projector);
        }

        const geometry = new PlaneGeometry(size.width, size.height);
        const material = new MeshBasicMaterial({
            color: 0x000000,
            transparent: true,
            opacity: 0.9,
        });

        this.projector = new Mesh(geometry, material);
        this.projector.position.copy(position);
        this.projector.rotation.set(rotation.x, rotation.y, rotation.z);
        this.projector.name = 'videoProjector';
        this.projector.visible = false;

        this.add(this.projector);

        this.setupVideoObserver();

        return this.projector;
    }

    private setupVideoObserver(): void {
        if (this.videoCheckInterval !== null) {
            window.clearInterval(this.videoCheckInterval);
        }

        this.videoCheckInterval = window.setInterval(() => {
            this.updateProjectorWithAvailableVideo();
        }, 2000) as unknown as number;

        document.addEventListener(
            'play',
            (e) => {
                if (e.target instanceof HTMLVideoElement) {
                    setTimeout(() => this.updateProjectorWithAvailableVideo(), 500);
                }
            },
            true,
        );

        document.addEventListener(
            'pause',
            (e) => {
                if (e.target instanceof HTMLVideoElement && e.target === this.activeVideoElement) {
                    setTimeout(() => this.updateProjectorWithAvailableVideo(), 300);
                }
            },
            true,
        );

        document.addEventListener(
            'ended',
            (e) => {
                if (e.target instanceof HTMLVideoElement && e.target === this.activeVideoElement) {
                    setTimeout(() => this.updateProjectorWithAvailableVideo(), 300);
                }
            },
            true,
        );

        if (this.janusCommunicationService) {
            const handleScreenShareStop = (event) => {
                console.log('Screen share stop detected, updating projector visibility');

                setTimeout(() => {
                    if (this.activeVideoElement) {
                        this.activeVideoElement = null;

                        this.updateProjectorWithAvailableVideo();
                    }
                }, 300);

                setTimeout(() => {
                    this.updateProjectorWithAvailableVideo();
                }, 1000);
            };

            this.janusCommunicationService.addEventListener('beforeScreenShareStopped', handleScreenShareStop);
            this.janusCommunicationService.addEventListener('screenShareStopped', handleScreenShareStop);

            this._screenShareStopHandlers = [handleScreenShareStop];
        }

        document.addEventListener('visibilitychange', () => {
            const now = Date.now();

            if (now - this.lastVisibilityChange < 1000) {
                this.pendingVisibilityChange = document.visibilityState === 'visible';
                return;
            }

            this.lastVisibilityChange = now;
            this.pendingVisibilityChange = null;

            if (document.visibilityState === 'visible') {
                setTimeout(() => this.updateProjectorWithAvailableVideo(), 1000);
            }
        });

        setInterval(() => {
            if (this.pendingVisibilityChange !== null && Date.now() - this.lastVisibilityChange >= 1000) {
                this.lastVisibilityChange = Date.now();
                if (this.pendingVisibilityChange) {
                    this.updateProjectorWithAvailableVideo();
                }
                this.pendingVisibilityChange = null;
            }
        }, 1000);
    }

    private updateProjectorWithAvailableVideo(): void {
        if (!this.projector) {
            return;
        }

        if (this.activeVideoElement && this.isVideoElementActive(this.activeVideoElement)) {
            if (!this.projector.visible) {
                console.log('Restoring projector visibility for active video');
                this.projector.visible = true;
            }
            return;
        }

        let foundActiveVideo = false;
        const videoSelectors = ['video[data-remote-id]', 'video.janus-screen-share', 'video.user-video', 'video'];

        for (const selector of videoSelectors) {
            const elements = document.querySelectorAll<HTMLVideoElement>(selector);

            for (const element of Array.from(elements)) {
                if (this.isVideoElementActive(element)) {
                    console.log('Found new active video for projector:', element.id || 'unnamed');
                    this.activeVideoElement = element;

                    const videoTexture = new VideoTexture(element);
                    videoTexture.colorSpace = SRGBColorSpace;
                    videoTexture.minFilter = LinearFilter;
                    videoTexture.magFilter = LinearFilter;

                    if (this.projector.material instanceof MeshBasicMaterial && this.projector.material.map) {
                        this.projector.material.map.dispose();
                    }

                    this.projector.material = new MeshBasicMaterial({
                        map: videoTexture,
                    });

                    this.projector.visible = true;
                    foundActiveVideo = true;

                    console.log('Projector updated with new video source');
                    break;
                }
            }

            if (foundActiveVideo) break;
        }

        if (!foundActiveVideo) {
            console.log('No active videos found, hiding projector');
            this.projector.visible = false;
            this.activeVideoElement = null;

            if (this.projector.material instanceof MeshBasicMaterial && this.projector.material.map) {
                this.projector.material.map.dispose();
            }
        }
    }

    private isVideoElementActive(element: HTMLVideoElement): boolean {
        if (!element) return false;

        if (!document.body.contains(element)) return false;

        if (element.paused || element.ended) return false;

        if (!element.srcObject) return false;

        if (!(element.srcObject instanceof MediaStream)) return false;

        const videoTracks = element.srcObject.getVideoTracks();
        if (videoTracks.length === 0) return false;

        return videoTracks.some((track) => track.enabled && track.readyState === 'live');
    }

    public cleanupVideoProjector() {
        if (this.videoCheckInterval !== null) {
            window.clearInterval(this.videoCheckInterval);
            this.videoCheckInterval = null;
        }

        this.activeVideoElement = null;

        if (this.projector) {
            this.projector.visible = false;
            if (this.projector.material instanceof MeshBasicMaterial && this.projector.material.map) {
                this.projector.material.map.dispose();
            }
        }

        if (this.janusCommunicationService && this._screenShareStopHandlers) {
            this._screenShareStopHandlers.forEach((handler) => {
                this.janusCommunicationService.removeEventListener('beforeScreenShareStopped', handler);
                this.janusCommunicationService.removeEventListener('screenShareStopped', handler);
            });
            this._screenShareStopHandlers = [];
        }
    }
}
