import { autoInjectable } from 'tsyringe';
import VrObject3D from '../Three/VrObject3D';
import { Block } from 'three-mesh-ui';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import {
    CanvasTexture,
    Color,
    Mesh,
    PlaneGeometry,
    ShaderMaterial,
    sRGBEncoding,
    TextureLoader,
    CylinderGeometry,
    MeshBasicMaterial,
    MathUtils,
    MeshStandardMaterial,
    MeshPhongMaterial,
    DoubleSide,
} from 'three';
import FontJSON from '@assets/fonts/Roboto-Regular-msdf.json';
import FontImage from '@assets/fonts/Roboto-Regular.png';
import IconCloseWhite from '@assets/icons/icon-name-close-white.png';
import IconEraserWhite from '@assets/icons/icon-name-eraser.png';

import Controlls from '../Controllers/Controlls';
import XrControllers from '../Controllers/XrControllers';
import ColyseusClient from '../../Network/ColyseusClient';
import Renderer from '../Renderer';
import Camera from '../../Camera';
import { IImage } from '../../react/sketchboards/types';
//@ts-ignore
import roboto from '../../../../static/Roboto/Roboto-Regular.ttf';
import { Text as TText } from 'troika-three-text';
import TranslationService from '../../Translations/TranslationService';

export interface ICtxPath {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
    color: string;
    lineWidth: number;
    timestamp?: number;
}

export const REST_CANVAS: ICtxPath = {
    x1: -1000,
    x2: -1000,
    y1: -1000,
    y2: -1000,
    color: '#FFFFFF',
    lineWidth: 999999999999,
};

export const ERASER_LINE_WIDTH = 30;
export const DRAWING_LINE_WIDTH = 10;

export const RESET_CANVAS_LINE_WIDTH = 999999999999;

@autoInjectable()
export class SketchBoard extends VrObject3D {
    public container: Block;

    public canvasBoard: Mesh;

    public sketchBoardPanelContent: Block;

    public pointedObject;

    // public material: MeshLambertMaterial;
    public material: MeshStandardMaterial;
    public canvasDefaultToolColor: string = '#2a62c9';
    public canvasToolColor: string;

    public canvasAllBtnColor: [] = [];

    public canvasSelectedBtnColor: Block;

    public canvas: HTMLCanvasElement;
    public canvasCtx: CanvasRenderingContext2D;

    //drawing
    public isDrawing: boolean = false;
    public boardPositionX: number = 0;
    public boardPositionY: number = 0;

    //drawing VR
    public isDrawingVR: boolean = false;
    public boardPositionXVR: number = 0;
    public boardPositionYVR: number = 0;

    public colors = [
        new Color(0x000000),
        new Color(0x2a62c9),
        new Color(0xed7c31),
        new Color(0x71ad47),
        new Color(0xffc001),
        new Color(0xff0000),
        new Color(0x749cb8),
    ];
    private drawingMode: 'erase' | 'drawing' = 'drawing';

    public colorButtons: Block[] = [];

    public constructor(
        public httpClient?: AxiosHttpClient,
        public intersectionContainer?: IntersectionContainer,
        public controls?: Controlls,
        public xrControllers?: XrControllers,
        public colyseusClient?: ColyseusClient,
        public renderer?: Renderer,
        public camera?: Camera,
    ) {
        super();

        //-----------------------------------------------------
        //startDrawing
        this.renderer.webGLRenderer.domElement.addEventListener(
            'pointerdown',
            (e) => {
                if (e.pointerType !== 'mouse' || e.button !== 0) {
                    return;
                }

                if (
                    this.controls.pointedObject !== null &&
                    this.controls.pointedObject.object.name === 'canvasBoard'
                ) {
                    this.camera.disableOrbitControls();
                    this.startDrawingOnObject();
                }

                //select btn color
                // if (
                //     this.controls.pointedObject !== null &&
                //     this.controls.pointedObject.object.name === 'canvasBtnColor'
                // ) {
                //     this.selectCanvasBtnColor(
                //         this.controls.pointedObject.object,
                //     );
                // }
            },
        );

        //-----------------------------------------------------
        //Drawing
        this.renderer.webGLRenderer.domElement.addEventListener(
            'pointermove',
            (e) => {
                if (
                    this.controls.pointedObject !== null &&
                    this.controls.pointedObject.hasOwnProperty('object') &&
                    this.controls.pointedObject.object.name === 'canvasBoard' &&
                    this.isDrawing
                ) {
                    // this.camera.disableOrbitControls();
                    this.drawingOnObject();
                }
            },
        );

        //-----------------------------------------------------
        //stopDrawing
        this.renderer.webGLRenderer.domElement.addEventListener(
            'pointerup',
            (e) => {
                if (e.pointerType !== 'mouse' || e.button !== 0) {
                    return;
                }
                if (
                    this.controls.pointedObject !== null &&
                    this.controls.pointedObject.object.name === 'canvasBoard'
                ) {
                    this.stopDrawingOnObject();
                }
                this.camera.enableOrbitControls();
            },
        );

        //##############################################################################################################
        //-----------------------------------------------------
        //start drawing VR
        this.xrControllers.controllerGripRight.addEventListener(
            'selectstart',
            (e) => {
                if (
                    this.xrControllers.pointedObject !== null &&
                    this.xrControllers.pointedObject.object.name ===
                        'canvasBoard'
                ) {
                    this.startDrawingVROnObject();
                }
            },
        );

        //-----------------------------------------------------
        //Drawing VR
        this.xrControllers.addEventListener('currentIntersectionPoint', (e) => {
            if (
                this.xrControllers.pointedObject !== null &&
                this.xrControllers.pointedObject.object.name === 'canvasBoard'
            ) {
                if (this.isDrawingVR) {
                    this.drawingVROnObject(e);
                }
            }
        });

        //-----------------------------------------------------
        //stop drawing VR
        this.xrControllers.controllerGripRight.addEventListener(
            'selectend',
            (e) => {
                if (
                    this.xrControllers.pointedObject !== null &&
                    this.xrControllers.pointedObject.object.name ===
                        'canvasBoard'
                ) {
                    this.stopDrawingVROnObject();
                }
            },
        );

        //##############################################################################################################

        this.createSketchBoard();
        this.createCanvas();

        // this.selectCanvasBtnColor(this.canvasSelectedBtnColor);

        this.drawOnCanvas();

        this.pointedObject = this.controls.pointedObject;

        let ctxPaths: ICtxPath[] = [];

        this.addEventListener('drawingCanvasBoard', async (e) => {
            if (e.target.canvasBoard.uuid === e.data.intersection.object.uuid) {
                let x1, x2, y1, y2;
                x1 = e.data.points.x1;
                x2 = this.canvas.width * e.data.points.x2;
                y1 = e.data.points.y1;
                y2 = this.canvas.height - this.canvas.height * e.data.points.y2;

                ctxPaths.push({
                    x1,
                    x2,
                    y1,
                    y2,
                    color: this.canvasToolColor,
                    lineWidth:
                        this.drawingMode === 'drawing'
                            ? DRAWING_LINE_WIDTH
                            : ERASER_LINE_WIDTH,
                });

                this.drawingLineOnCanvas(x1, y1, x2, y2, this.canvasToolColor);

                if (e.data.type === 'stop') {
                    this.dispatchEvent({
                        type: 'updateSketchBoard',
                        data: ctxPaths,
                    });
                    ctxPaths = [];
                }
            }
        });

        this.addEventListener('drawingCanvasBoardVR', (e) => {
            if (
                e.target.canvasBoard.uuid ===
                e.target.xrControllers.pointedObjectCanvasBoard.object.uuid
            ) {
                let x1, x2, y1, y2;

                x1 = e.data.points.x1;
                x2 = e.data.points.x2;
                y1 = e.data.points.y1;
                y2 = e.data.points.y2;

                this.drawingLineOnCanvas(x1, y1, x2, y2, this.canvasToolColor);
                ctxPaths.push({
                    x1,
                    x2,
                    y1,
                    y2,
                    color: this.canvasToolColor,
                    lineWidth:
                        this.drawingMode === 'drawing'
                            ? DRAWING_LINE_WIDTH
                            : ERASER_LINE_WIDTH,
                });

                if (e.data.type === 'stop') {
                    this.dispatchEvent({
                        type: 'updateSketchBoard',
                        data: ctxPaths,
                    });

                    ctxPaths = [];
                }
            }
        });

        this.setLabel();
    }

    public setLabel() {
        const mainLobbyLabel = new TText();

        mainLobbyLabel.text = TranslationService.translate(
            'vr.sketchboard_label',
        );
        mainLobbyLabel.fontSize = 0.2;
        mainLobbyLabel.color = 0x7286a3;
        mainLobbyLabel.anchorX = 'left';
        mainLobbyLabel.anchorY = 'middle';
        mainLobbyLabel.depthOffset = 0.1;
        mainLobbyLabel.material = new MeshPhongMaterial({
            color: new Color(0x7286a3),
            emissive: new Color(0x7286a3),
            emissiveIntensity: 1,
            side: DoubleSide,
        });
        mainLobbyLabel.position.x = -2;
        mainLobbyLabel.position.y = 1.5;
        mainLobbyLabel.position.z = 0;

        mainLobbyLabel.font = roboto;

        this.add(mainLobbyLabel);
    }

    public reuseCanvasString(url) {
        let img = new Image();
        img.onload = () => {
            // Note: here img.naturalHeight & img.naturalWidth will be your original canvas size
            this.canvasCtx.drawImage(img, 0, 0);
        };
        img.src = url;

        // this.material.map.needsUpdate = true;
        // this.material.map.needsPMREMUpdate = true;
    }

    public setDefaultState(block: any, click?: Function) {
        let selectedAttribute: any = {
            state: 'selected',
            attributes: {
                borderOpacity: 1,
            },
        };

        if (click) {
            selectedAttribute = {
                state: 'selected',
                attributes: {
                    borderOpacity: 1,
                },
                onSet: () => {
                    click();
                },
            };
        }

        //@ts-ignore
        block.setupState({
            state: 'idle',
            attributes: {
                borderOpacity: 0,
            },
        });
        //@ts-ignore
        block.setupState({
            state: 'hovered',
            attributes: {
                borderOpacity: 0.5,
            },
        });

        //@ts-ignore
        block.setupState(selectedAttribute);
    }

    public createSketchBoard() {
        let borderWidth = 0.001;
        let borderColor = new Color(0xffffff);
        let borderOpacity = 0.5;
        let offsetActiveObj = 0.05;

        const container = new Block({
            width: 4,
            height: 2.5,
            contentDirection: 'column',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontColor: new Color(0xffffff),
            backgroundOpacity: 0.001,
            backgroundColor: new Color(0x749eb8),
            borderRadius: 0,
            borderWidth: borderWidth,
            borderColor: borderColor,
            borderOpacity: borderOpacity,
        });

        //@ts-ignore
        container.frame.material.side = 2;

        this.intersectionContainer.addObjectToIntersect(container, true, false);
        this.setDefaultState(container);

        //panel content

        //panel content header (bottom)
        const sketchBoardPanelHeader = new Block({
            width: 3.6,
            height: 0.4,
            contentDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundOpacity: 0,
            borderRadius: 0,
            borderWidth: 0,
            borderOpacity: 0,
        });

        const headerToolsWrapper = new Block({
            width: 3.4,
            height: 0.2,
            contentDirection: 'row',
            backgroundOpacity: 0,
            borderRadius: 0,
            borderWidth: 0,
            borderOpacity: 0,
        });

        const headerResetBtn = new Block({
            width: 0.2,
            height: 0.2,
            textAlign: 'center',
            justifyContent: 'center',
            backgroundOpacity: 0.3,
            borderWidth: 0.02,
            borderOpacity: 0,
            borderColor: new Color(0xffffff),
            offset: offsetActiveObj,
            borderRadius: 0.1,
            margin: 0.01,
        });

        new TextureLoader().load(IconCloseWhite, (texture) => {
            headerResetBtn.add(
                new Block({
                    width: 0.1,
                    height: 0.1,
                    backgroundTexture: texture,
                    borderRadius: 0,
                    borderOpacity: 0,
                }),
            );
        });

        this.intersectionContainer.addObjectToIntersect(
            headerResetBtn,
            false,
            false,
        );
        this.setDefaultState(headerResetBtn, () => {
            this.clearCanvasBoard();
        });

        sketchBoardPanelHeader.add(headerToolsWrapper, headerResetBtn);

        const headerBtnColorOptions = {
            width: 0.2,
            height: 0.2,
            borderWidth: 0.02,
            borderOpacity: 0,
            borderColor: new Color(0xffffff),
            offset: 0.025,
            margin: 0.01,
            borderRadius: 0.1,
            backgroundOpacity: 1,
            textAlign: 'center',
            justifyContent: 'center',
        };

        let headerBtnColorArray = [];

        // const btnCylinder
        const btnGeometry = new CylinderGeometry(0.08, 0.08, 0.0125, 20);
        const btnResetMaterial = new MeshBasicMaterial({ color: 0x749eb8 });
        const btnResetCylinder = new Mesh(btnGeometry, btnResetMaterial);
        btnResetCylinder.rotateY(MathUtils.DEG2RAD * 90);
        btnResetCylinder.rotateZ(MathUtils.DEG2RAD * 90);
        btnResetCylinder.position.z = -0.013;

        headerResetBtn.add(btnResetCylinder);

        // @ts-ignore
        const eraser = new Block(headerBtnColorOptions);

        //@ts-ignore
        eraser.setupState({
            state: 'nonSelected',
            attributes: {
                borderOpacity: 0,
            },
        });

        //@ts-ignore
        eraser.setupState({
            state: 'selected',
            attributes: {
                borderOpacity: 0.7,
            },
            onSet: () => {
                headerBtnColorArray.forEach((btn) => {
                    if (btn === eraser) {
                        this.setCanvasToolColor('#ffffff');

                        this.setDrawingMode('erase');

                        return;
                    } else {
                        btn.setState('nonSelected');
                    }
                });
            },
        });

        this.colorButtons['eraser'] = eraser;

        headerBtnColorArray.push(eraser);
        eraser.userData.selected = false;

        eraser.name = 'sketchBoardColorBtn';
        eraser.userData.eraser = true;

        //@ts-ignore
        // headerBtnColor6.backgroundColor = new Color(0xffffff);
        eraser.backgroundColor = new Color(0x749cb8);
        this.intersectionContainer.addObjectToIntersect(eraser);

        new TextureLoader().load(IconEraserWhite, (texture) => {
            eraser.add(
                new Block({
                    width: 0.1,
                    height: 0.1,
                    backgroundTexture: texture,
                    borderWidth: 0,
                    borderRadius: 0,
                    borderOpacity: 0,
                }),
            );
        });

        eraser.userData.setState = false;
        eraser.name = 'canvasBtnColor';

        // @ts-ignore
        this.canvasAllBtnColor.push(eraser);

        const btnMaterial6 = new MeshBasicMaterial({ color: 0x749cb8 });
        const btnCylinder6 = new Mesh(btnGeometry, btnMaterial6);
        btnCylinder6.rotateY(MathUtils.DEG2RAD * 90);
        btnCylinder6.rotateZ(MathUtils.DEG2RAD * 90);
        btnCylinder6.position.z = -0.013;

        eraser.add(btnCylinder6);

        //--------------------------

        const headerBtnExtraSpace = new Block({
            width: 0.2,
            height: 0.2,
            borderWidth: 0,
            borderOpacity: 0,
            offset: 0,
            margin: 0.01,
            borderRadius: 0,
            backgroundOpacity: 0,
        });

        this.colors.forEach((color: Color, index: number) => {
            const headerBtnColor = new Block(headerBtnColorOptions);
            headerBtnColorArray.push(headerBtnColor);

            //@ts-ignore
            headerBtnColor.setupState({
                state: 'nonSelected',
                attributes: {
                    borderOpacity: 0,
                },
            });

            //@ts-ignore
            headerBtnColor.setupState({
                state: 'selected',
                attributes: {
                    borderOpacity: 0.7,
                },
                onSet: () => {
                    headerBtnColorArray.forEach((btn) => {
                        if (btn === headerBtnColor) {
                            this.setDrawingMode('drawing');

                            this.setCanvasToolColor(
                                //@ts-ignore
                                '#' + color.getHexString(),
                            );
                            return;
                        } else {
                            btn.setState('nonSelected');
                        }
                    });
                },
            });

            this.colorButtons[color.getHexString()] = headerBtnColor;

            if (index === 0) {
                this.setCanvasToolColor('#' + color.getHexString());
                //@ts-ignore
                headerBtnColor.setState('selected');
            }

            this.canvasSelectedBtnColor = headerBtnColor;
            headerBtnColor.name = 'sketchBoardColorBtn';
            //@ts-ignore
            headerBtnColor.backgroundColor = color;
            this.intersectionContainer.addObjectToIntersect(headerBtnColor);

            headerBtnColor.userData.setState = false;
            headerBtnColor.name = 'canvasBtnColor';
            // @ts-ignore
            // this.canvasAllBtnColor.push(headerBtnColor);

            const btnMaterial1 = new MeshBasicMaterial({
                color: color,
            });
            const btnCylinder = new Mesh(btnGeometry, btnMaterial1);
            btnCylinder.rotateY(MathUtils.DEG2RAD * 90);
            btnCylinder.rotateZ(MathUtils.DEG2RAD * 90);
            btnCylinder.position.z = -0.013;
            headerBtnColor.add(btnCylinder);

            headerToolsWrapper.add(headerBtnColor);
        });

        headerToolsWrapper.add(headerBtnExtraSpace, eraser);

        //panel content    CANVAS FOR DRAWING
        this.sketchBoardPanelContent = new Block({
            width: 4,
            height: 2.1,
            backgroundOpacity: 0,
            borderOpacity: 0,
        });

        container.add(this.sketchBoardPanelContent, sketchBoardPanelHeader);

        this.add(container);
        this.container = container;
    }

    public createCanvas() {
        let drawingCanvasWrapper = document.getElementById('canvas-elements');
        this.canvas = document.createElement('canvas');
        this.canvas.id = 'sketchBoardDrawingCanvas-' + this.container.uuid;
        //@ts-ignore
        this.canvas.parent = this;

        this.canvas.className =
            'sketchBoard-scene#' +
            this.renderer.scene.name +
            ' sketchboard-canvas';
        this.canvas.width = 1920;
        this.canvas.height = 1080;

        drawingCanvasWrapper.appendChild(this.canvas);

        this.canvasCtx = this.canvas.getContext('2d');

        //set canvas as texture
        let canvasTexture = new CanvasTexture(this.canvas);
        canvasTexture.repeat.set(1, 1);
        canvasTexture.encoding = sRGBEncoding;
        // canvasTexture.needsUpdate = true;

        this.material = new MeshStandardMaterial({
            map: canvasTexture,
            transparent: true,
        });

        this.getObjectByProperty('uuid', this.material.uuid);

        let geometry = new PlaneGeometry(4, 2.2);

        this.canvasBoard = new Mesh(geometry, this.material);
        this.canvasBoard.position.z = 0.001;
        this.canvasBoard.name = 'canvasBoard';
        this.canvasBoard.userData.name = 'canvasBoard';
        this.canvasBoard.userData.isUI = true;
        this.canvasBoard.userData.setState = false;
        this.canvasBoard.userData.canvasId = this.canvas.id;

        this.sketchBoardPanelContent.add(this.canvasBoard);
        this.intersectionContainer.addObjectToIntersect(this.canvasBoard);
    }

    private getOffset(e: MouseEvent) {
        const rect = this.canvas.getBoundingClientRect();
        const scaleX = this.canvas.width / rect.width;
        const scaleY = this.canvas.height / rect.height;

        return {
            offsetX: (e.clientX - rect.left) * scaleX,
            offsetY: (e.clientY - rect.top) * scaleY,
        };
    }

    //events for html canvas - for testing
    public drawOnCanvas() {
        let isDrawing = false;
        let x = 0;
        let y = 0;
        let ctxPaths: ICtxPath[] = [];

        this.canvas.addEventListener('mousedown', (e) => {
            if (e.button === 0) {
                const { offsetX, offsetY } = this.getOffset(e);
                x = offsetX;
                y = offsetY;
                isDrawing = true;
            }
        });

        this.canvas.addEventListener('mousemove', (e) => {
            if (isDrawing) {
                const { offsetX, offsetY } = this.getOffset(e);
                this.drawLineOnCanvas(x, y, offsetX, offsetY);

                if (isDrawing) {
                    ctxPaths.push({
                        x1: x,
                        x2: offsetX,
                        y1: y,
                        y2: offsetY,
                        color: this.canvasToolColor,
                        lineWidth:
                            this.drawingMode === 'drawing'
                                ? DRAWING_LINE_WIDTH
                                : ERASER_LINE_WIDTH,
                    });
                }

                x = offsetX;
                y = offsetY;
            }
        });

        this.canvas.addEventListener('mouseup', (e) => {
            if (isDrawing) {
                const { offsetX, offsetY } = this.getOffset(e);
                this.drawLineOnCanvas(x, y, offsetX, offsetY);
                x = 0;
                y = 0;
                isDrawing = false;

                this.dispatchEvent({
                    type: 'updateSketchBoard',
                    data: ctxPaths,
                });
                ctxPaths = [];
            }
        });

        this.canvas.addEventListener('mouseleave', () => {
            isDrawing = false;
        });
    }

    public setCanvasToolColor(color) {
        this.canvasToolColor = color;
    }

    public drawLineOnCanvas(x1: number, y1: number, x2: number, y2: number) {
        this.canvasCtx.beginPath();
        this.canvasCtx.strokeStyle = this.canvasToolColor;
        this.canvasCtx.lineWidth =
            this.drawingMode === 'drawing'
                ? DRAWING_LINE_WIDTH
                : ERASER_LINE_WIDTH;
        this.canvasCtx.lineJoin = 'round'; //todo from tool set
        this.canvasCtx.moveTo(x1, y1);
        this.canvasCtx.lineTo(x2, y2);
        this.canvasCtx.closePath();
        this.canvasCtx.stroke();

        //@ts-ignore
        this.material.map.needsUpdate = true;
    }

    public drawingLineOnCanvas(
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        color: string,
    ) {
        this.canvasCtx.beginPath();
        this.canvasCtx.strokeStyle = color;
        this.canvasCtx.lineWidth =
            this.drawingMode === 'drawing'
                ? DRAWING_LINE_WIDTH
                : ERASER_LINE_WIDTH;
        this.canvasCtx.lineJoin = 'round'; //todo from tool set
        this.canvasCtx.moveTo(x1, y1);
        this.canvasCtx.lineTo(x2, y2);
        this.canvasCtx.closePath();
        this.canvasCtx.stroke();

        //@ts-ignore
        this.material.map.needsUpdate = true;
    }

    public async clearCanvasBoard() {
        //@ts-ignore
        this.material.map.needsUpdate = true;

        this.dispatchEvent({ type: 'resetSketchBoard' });

        this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        //TODO wysyłac czarny obraz bo canvas jest przezroczysty i dlatego się nie resetuje
        // await this.colyseusClient.updateSketchBoard(this.getDrawingAsString());
    }

    public startDrawingOnObject() {
        if (this.controls.selectState) {
            this.boardPositionX =
                this.canvas.width * this.controls.pointedObject.uv.x;
            this.boardPositionY =
                this.canvas.height -
                this.canvas.height * this.controls.pointedObject.uv.y;
            this.isDrawing = true;
        }
    }

    public drawingOnObject() {
        if (this.controls.selectState && this.isDrawing) {
            // if (this.boardPositionX !== this.pointedObject.uv.x || this.boardPositionY !== this.pointedObject.uv.y) {
            if (this.boardPositionX || this.boardPositionY) {
                this.dispatchEvent({
                    type: 'drawingCanvasBoard',
                    data: {
                        points: {
                            x1: this.boardPositionX,
                            x2: this.controls.pointedObject.uv.x,
                            y1: this.boardPositionY,
                            y2: this.controls.pointedObject.uv.y,
                        },
                        type: 'drawing',
                        intersection: this.controls.pointedObject,
                    },
                });

                this.boardPositionX =
                    this.canvas.width * this.controls.pointedObject.uv.x;
                this.boardPositionY =
                    this.canvas.height -
                    this.canvas.height * this.controls.pointedObject.uv.y;
            }
        }
    }

    public stopDrawingOnObject() {
        if (this.controls.selectState === false && this.isDrawing) {
            this.dispatchEvent({
                type: 'drawingCanvasBoard',
                data: {
                    points: {
                        x1: this.boardPositionX,
                        x2: this.controls.pointedObject.uv.x,
                        y1: this.boardPositionY,
                        y2: this.controls.pointedObject.uv.y,
                    },
                    type: 'stop',
                    intersection: this.controls.pointedObject,
                },
            });

            this.boardPositionX = 0;
            this.boardPositionY = 0;
            this.isDrawing = false;
        }
    }

    //##############################################################################################################
    // VR
    public startDrawingVROnObject() {
        if (this.xrControllers.pointedObject.object.name === 'canvasBoard') {
            this.boardPositionXVR =
                this.canvas.width *
                this.xrControllers.pointedObjectCanvasBoard.uv.x;
            this.boardPositionYVR =
                this.canvas.height -
                this.canvas.height *
                    this.xrControllers.pointedObjectCanvasBoard.uv.y;
            this.isDrawingVR = true;
        }
    }

    public drawingVROnObject(e) {
        let x1 = this.boardPositionXVR;
        let x2 = this.canvas.width * e.data.pointedObject.uv.x;
        let y1 = this.boardPositionYVR;
        let y2 =
            this.canvas.height - this.canvas.height * e.data.pointedObject.uv.y;

        this.dispatchEvent({
            type: 'drawingCanvasBoardVR',
            data: {
                points: {
                    x1: x1,
                    x2: x2,
                    y1: y1,
                    y2: y2,
                },
            },
        });

        this.boardPositionXVR = x2;
        this.boardPositionYVR = y2;
    }

    public stopDrawingVROnObject() {
        if (this.xrControllers.pointedObject.object.name === 'canvasBoard') {
            if (
                this.xrControllers.pointedObjectCanvasBoard &&
                this.xrControllers.isSelecting === false &&
                this.isDrawingVR
            ) {
                let x1 = this.boardPositionXVR;
                let x2 =
                    this.canvas.width *
                    this.xrControllers.pointedObjectCanvasBoard.uv.x;
                let y1 = this.boardPositionYVR;
                let y2 =
                    this.canvas.height -
                    this.canvas.height *
                        this.xrControllers.pointedObjectCanvasBoard.uv.y;

                this.dispatchEvent({
                    type: 'drawingCanvasBoardVR',
                    data: {
                        points: {
                            x1: x1,
                            x2: x2,
                            y1: y1,
                            y2: y2,
                        },
                        type: 'stop',
                    },
                });

                this.boardPositionXVR = 0;
                this.boardPositionYVR = 0;
                this.isDrawingVR = false;
            }
        }
    }

    public selectCanvasBtnColor(obj) {
        if (obj.userData.hasOwnProperty('eraser')) {
            this.setCanvasToolColor('#ffffff');
        } else {
            this.setCanvasToolColor(
                //@ts-ignore
                '#' + obj.backgroundColor.getHexString(),
            );
        }
    }

    public setDrawingMode(mode: 'erase' | 'drawing') {
        if (!this.canvas) {
            return;
        }
        if (mode === 'erase') {
            this.drawingMode = 'erase';
            this.canvasCtx.globalCompositeOperation = 'destination-out';
        } else {
            this.drawingMode = 'drawing';
            this.canvasCtx.globalCompositeOperation = 'source-over';
        }
    }

    public loadImageFromBase64(base64: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = base64;
            img.onload = () => resolve(img);
            img.onerror = (error) => reject(error);
        });
    }

    public async drawImage(image: IImage) {
        if (!image.image) {
            return;
        }

        this.setDrawingMode('drawing');

        const imageURL = await this.loadImageFromBase64(image.image);
        this.canvasCtx.drawImage(
            imageURL,
            image.dx,
            image.dy,
            image.dWidth,
            image.dHeight,
        );

        this.material.map.needsUpdate = true;
        this.material.map.needsPMREMUpdate = true;
    }
}
