import VrObject3D from '../Three/VrObject3D';
import { autoInjectable } from 'tsyringe';
import {
    CatmullRomCurve3,
    Color,
    ExtrudeGeometry,
    Mesh,
    MeshBasicMaterial,
    RingGeometry,
    TorusGeometry,
    Vector3,
} from 'three';
import { Block } from 'three-mesh-ui';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import Camera from '../../Camera';
import Resources from '../../Resources';
import Renderer from '../Renderer';
import VrScene from '../Three/VrScene';
import CylinderHollow from './CylinderHollow';

export enum AXIS {
    X,
    Y,
    Z,
}

@autoInjectable()
export class ZoomToView extends VrObject3D {
    private readonly target: Vector3;
    private readonly offset: number;
    private readonly axis: AXIS;
    private readonly viewPosition: Vector3;

    public constructor(
        options: {
            target: Vector3;
            axis: AXIS;
            offset: number;
            viewPosition?: Vector3;
        },
        public intersectionContainer?: IntersectionContainer,
        public camera?: Camera,
        public resources?: Resources,
        public renderer?: Renderer,
    ) {
        super();
        this.target = options.target;
        this.offset = options.offset;
        this.axis = options.axis;
        this.viewPosition = options.viewPosition;

        this.createView();
    }

    private createView() {
        const zoomBlock = new Block({
            width: 0.3,
            height: 0.3,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: new Color('gray'),
            backgroundOpacity: 0,
            borderRadius: 0.02,
        });

        const zoomIcon = new Block({
            width: 0.2,
            height: 0.2,
            backgroundTexture: this.resources.items.circleIcon,
        });

        // this.add(new CylinderHollow());
        // const geometry = new TorusGeometry(0.09, 0.02, 5, 21);
        // const torusMaterial = new MeshBasicMaterial({ color: 0xffffff });
        // const torus = new Mesh(geometry, torusMaterial);

        const cylinderHollow = new CylinderHollow();
        cylinderHollow.material.opacity = 0.9;
        cylinderHollow.material.transparent = true;
        zoomBlock.add(cylinderHollow);

        // zoomBlock.add(torus);
        // zoomBlock.add(zoomIcon);

        //@ts-ignore
        zoomBlock.setupState({
            state: 'idle',
            attributes: {
                backgroundOpacity: 0,
            },
            onSet: () => {
                cylinderHollow.material.color = new Color('white');
                cylinderHollow.material.opacity = 0.9;
            },
        });

        //@ts-ignore
        zoomBlock.setupState({
            state: 'hovered',
            attributes: {
                backgroundOpacity: 0,

                // backgroundColor: new Color('blue'),
            },
            onSet: () => {
                cylinderHollow.material.color = new Color('blue');
                cylinderHollow.material.opacity = 1;
            },
        });

        //@ts-ignore
        zoomBlock.setupState({
            state: 'selected',
            attributes: {},
            onSet: () => this.zoomToTarget(),
        });
        this.intersectionContainer.addObjectToIntersect(zoomBlock);
        this.add(zoomBlock);
    }

    private zoomToTarget = () => {
        const mainPlayer = (this.renderer.scene as VrScene).mainPlayer;
        mainPlayer.visible = false;

        const target = this.target.clone();

        this.camera.instance.lookAt(target);

        this.camera.orbitControls.target = target;

        if (this.viewPosition) {
            this.camera.instance.position.set(
                this.viewPosition.x,
                this.viewPosition.y,
                this.viewPosition.z,
            );
        } else {
            this.camera.instance.position.set(
                this.axis === AXIS.X ? target.x + this.offset : target.x,
                this.axis === AXIS.Y ? target.y + this.offset : target.y,
                this.axis === AXIS.Z ? target.z + this.offset : target.z,
            );
            return;
        }
    };
}
