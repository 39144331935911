import { useState, useRef } from 'react';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import { container } from 'tsyringe';
import { Base64 } from 'js-base64';
import Resources from '../../Resources';
import no_avatar from '../../../../static/assets/icons/no_avatar.png';
import FirebaseClient from '../../Network/Firebase/FirebaseClient';
import TranslationService from '../../Translations/TranslationService';

export function User({ id, username, firstName, lastName, photoUrl }) {
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isInviteSent, setIsInviteSent] = useState(false);
    const [timeoutCount, setTimeoutCount] = useState(15);
    const inviteTimeoutRef = useRef(null);
    const timeoutCountRef = useRef(null);
    const httpClient = container.resolve(AxiosHttpClient);
    const resources = container.resolve(Resources);

    const changeImgIfEmpty = (url) => {
        if (url === '/media/img/no_avatar.png') {
            return no_avatar;
        }
        return url;
    };

    const inviteHandler = () => {
        setIsButtonClicked(true);
        setIsInviteSent(false);

        timeoutCountRef.current = setInterval(() => {
            setTimeoutCount((prevCount) => {
                if (prevCount <= 1) {
                    clearInterval(timeoutCountRef.current);
                }
                return prevCount - 1;
            });
        }, 1000);

        inviteTimeoutRef.current = setTimeout(async () => {
            await httpClient.sendMessage({
                recipient: username,
                subject: TranslationService.translate(
                    'vr.friends_you_have_been_invited',
                    resources.items.user.id,
                ),
                body: Base64.btoa(resources.items.user.id),
                uploaded_audio_file_id: null,
                uploaded_file_id: null,
            });
            setIsInviteSent(true);
        }, 15000);
    };

    const cancelInviteHandler = () => {
        clearTimeout(inviteTimeoutRef.current);
        setIsButtonClicked(false);
        setIsInviteSent(false);
        setTimeoutCount(15);
        clearInterval(timeoutCountRef.current);
    };

    const Buttons = () => {
        if (isButtonClicked && !isInviteSent) {
            return (
                <button
                    className="btn btn-secondary"
                    onClick={cancelInviteHandler}
                >
                    {TranslationService.translate('vr.friends_cancel_invite')} (
                    {timeoutCount})
                </button>
            );
        }
        return (
            <button
                className={`btn btn-primary ${
                    isButtonClicked ? 'clicked' : ''
                }`}
                id={'invite-' + id}
                onClick={inviteHandler}
                disabled={isButtonClicked}
            >
                {TranslationService.translate('vr.friends_invite')}
            </button>
        );
    };

    return (
        <div className="user-searched">
            <div>
                <img
                    className="avatar-searched-user"
                    src={changeImgIfEmpty(photoUrl)}
                    alt={username + '-avatar'}
                />
            </div>
            <div className="d-flex flex-column ">
                <h6 className="user-search-h2">{username}</h6>
                <p className="pb-0 mb-0">
                    <span className="firstname">{firstName} </span>
                    <span className="lastname">{lastName}</span>
                </p>
            </div>
            <div>
                <Buttons />
            </div>
        </div>
    );
}
