import { EventDispatcher } from 'three';
import { autoInjectable, singleton } from 'tsyringe';
import { ISettingsRaw } from '../Models/settings';

import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import Resources from '../../Resources';
import {
    BehaviorSubject,
    catchError,
    filter,
    from,
    fromEvent,
    map,
    Observable,
    Observer,
    of as observableOf,
    switchMap,
    take,
    throwError,
} from 'rxjs';

const NOTIFICATIONS_PATH = '/notifications/';
const NOTIFICATIONS_UNIVERSAL_PATH = NOTIFICATIONS_PATH + 'receiverId/';
const USER_PERSISTANCE_PATH = '/users/persistance/';
const FIREBASE_PERSISTANCE_PATH = '.info/connected';

@singleton()
@autoInjectable()
export default class FirebaseClient {
    private isReadyObservable: Observable<boolean>;
    private isReadyObserver: Observer<boolean>;
    private isUIReady = false;

    private tokenObservable: Observable<any>;

    private auth: firebase.auth.Auth;
    private database: firebase.database.Database;
    private userLogged = false;
    private initialized: boolean = false;

    private firebaseConnectionSubject = new BehaviorSubject<boolean>(false);

    public constructor(public resources?: Resources) {
        this.removeAllFirebaseStorage();
        this.firebaseConnectionSubject.next(false);

        try {
            if (!this.resources.items.settings.is_firebase_disabled) {
                firebase.initializeApp(
                    this.resources.items.settings.firebase_client_settings,
                );

                this.auth = firebase.auth();
                this.database = firebase.database();
                if (!this.tokenObservable) {
                    this.signInWithToken().subscribe(() => {
                        this.initialized = true;
                        this.firebaseConnectionSubject.next(true);
                    });
                }
            }
        } catch (e) {}
    }
    private removeAllFirebaseStorage() {
        try {
            for (let i = localStorage.length - 1; i >= 0; i--) {
                const key = localStorage.key(i);
                if (key.startsWith('firebase:')) {
                    localStorage.removeItem(key);
                }
            }
        } catch (e) {}
    }

    private signInWithToken(): Observable<any> {
        return new Observable((subscriber) => {
            const token = this.resources.items.user.chat_token;
            this.auth
                .signInWithCustomToken(token)
                .then(() => {
                    this.userLogged = true;
                    this.initialized = true;
                    subscriber.next();
                    subscriber.complete();
                })
                .catch((error) => {
                    subscriber.error(error);
                });
        });
    }

    private setReady() {
        this.isUIReady = true;
        this.isReadyObserver.next(this.isUIReady);
        this.isReadyObserver.complete();
    }

    public isReady(): Observable<boolean> {
        if (this.isUIReady) {
            return observableOf(this.isUIReady);
        } else {
            return this.isReadyObservable;
        }
    }

    public updateByPath(
        path: string,
        objectId: string | number | boolean,
        data: Object,
    ): Observable<firebase.database.DataSnapshot> {
        if (!this.initialized) {
            console.log('Firebase not initialized');
            return new Observable((observer: Observer<any>) => {
                observer.error(new Error('Firebase not initialized'));
            });
        }

        const pathRef = this.database.ref(path);
        return Observable.create(
            (observer: Observer<firebase.database.DataSnapshot>) => {
                pathRef
                    .orderByChild('object_id')
                    .equalTo(objectId)
                    .on('child_added', (response) => {
                        response.ref.update(data);
                        observer.next(null);
                        observer.complete();
                    });
            },
        );
    }

    public update(objectId: number | string, data) {
        if (!this.initialized) {
            console.log('Firebase not initialized');
            return new Observable((observer: Observer<any>) => {
                observer.error(new Error('Firebase not initialized'));
            });
        }

        const path = this.getUniversalNotificationPath(
            this.resources.items.user.id,
        );
        return this.updateByPath(path, objectId, data);
    }

    public updateSomebodyMessage(objectId: number, userId: number) {
        const path = this.getUniversalNotificationPath(userId);

        return this.updateByPath(path, objectId, {});
    }
    public connectNewNotificationChildAddedHandler(
        path: string,
    ): Observable<firebase.database.DataSnapshot> {
        if (!this.initialized) {
            console.log('Firebase not initialized');
            return new Observable((observer: Observer<any>) => {
                observer.error(new Error('Firebase not initialized'));
            });
        }
        const pathRef = this.database.ref(path);

        return Observable.create(
            (observer: Observer<firebase.database.DataSnapshot>) => {
                pathRef
                    .orderByChild('status')
                    .equalTo(true)
                    .on('child_added', (data) => {
                        observer.next(data);
                    });
            },
        );
    }

    public getData(path: string): Observable<any> {
        return this.signInWithToken().pipe(
            switchMap(() => {
                if (!this.initialized) {
                    throw new Error('Firebase not initialized');
                }

                const pathRef = this.database.ref(path);
                return new Observable((observer: Observer<any>) => {
                    pathRef.on(
                        'value',
                        (snapshot) => {
                            observer.next(snapshot);
                        },
                        (error) => {
                            observer.error(error);
                        },
                    );
                });
            }),
            catchError((error) => {
                // Handle or propagate error
                console.error(error);
                return throwError(error);
            }),
        );
    }

    public getNotificationRef(path) {
        return this.database.ref(path);
    }

    public getDataOnce(path: string): Observable<any> {
        return this.firebaseConnectionSubject.pipe(
            filter((connected) => connected === true), // Filtruje tylko połączone stany
            take(1), // Bierze tylko pierwsze wystąpienie połączonego stanu
            switchMap(() => {
                return new Observable((subscriber) => {
                    const pathRef = this.database.ref(path);

                    pathRef
                        .once('value')
                        .then((snapshot) => {
                            subscriber.next(snapshot.val());
                            subscriber.complete();
                        })
                        .catch((error) => {
                            subscriber.error(error);
                        });
                });
            }),
        );
    }

    public async connectToNotifications(event: (data) => void) {
        const pathRef = this.database.ref(
            this.getUniversalNotificationPath(this.resources.items.user.id),
        );

        pathRef
            .orderByChild('status')
            .equalTo(true)
            .on('child_added', (data) => {
                event(data.val());
            });
    }

    public getUniversalNotificationPath(userId: number): string {
        return NOTIFICATIONS_UNIVERSAL_PATH + userId;
    }

    private waitForFirebaseConnection() {}
}
