import VrObject3D from '../Three/VrObject3D';
import { autoInjectable } from 'tsyringe';
import FirebaseClient from '../../Network/Firebase/FirebaseClient';
import Resources from '../../Resources';
import { Color } from 'three';
import Utils from '../../Utils/Utils';
import ThreeMeshUI, { Block, Text } from 'three-mesh-ui';
//@ts-ignore
import FontJSON from './assets/Roboto-msdf.json';
//@ts-ignore
import FontImage from './assets/Roboto-msdf.png';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import Application from '../../Application';
import TranslationService from '../../Translations/TranslationService';

interface NotificationMessage {
    data: string;
    datetime: string;
    object_database_id: number;
    object_id: number | string;
    object_type: string;
    receiver: {
        firstName: string;
        id: number;
        lastName: string;
        photoURL: string;
        username: string;
    };
    receiver_id: number;
    sender: {
        firstName: string;
        id: number;
        lastName: string;
        photoURL: string;
        username: string;
    };
    sender_id: number;
    skip_on_bell: boolean;
    status: boolean;
    timestamp: number;
}

@autoInjectable()
export default class InviteReceiver extends VrObject3D {
    public container: Block | null = null;

    public buttons: Block[] = [];
    public constructor(
        public firebaseClient?: FirebaseClient,
        public resources?: Resources,
        public intersectionContainer?: IntersectionContainer,
    ) {
        super();

        this.startListening();
    }

    public removeInviteBlock() {
        this.buttons.forEach((button) => {
            this.intersectionContainer.remove(button);
        });

        if (this.container !== null) {
            this.container.remove(...this.container.children);
        }
    }

    public createBlockForInvite(message: NotificationMessage) {
        this.removeInviteBlock();

        this.container = new Block({
            width: 1.5,
            height: 0.4,
            backgroundColor: new Color('black'),
            justifyContent: 'center',
            alignItems: 'center',
            backgroundOpacity: 0.6,
            contentDirection: 'column',
        });

        const textBlock = new Block({
            width: 1.5,
            height: 0.1,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            backgroundOpacity: 0,
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0.01,
        });

        textBlock.add(
            new Text({
                content: Utils.stripNonAplhaNumericAndHtml(
                    `${TranslationService.translate('vr.friends_invited_by')} ${
                        message.sender?.username
                    }`,
                ),
            }),
        );

        this.container.add(textBlock);
        const acceptOrDeclineBlock = new Block({
            width: 1.5,
            height: 0.2,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            contentDirection: 'row',
            justifyContent: 'space-around',
            backgroundOpacity: 0,
        });

        const acceptBlock = new Block({
            width: 0.5,
            height: 0.2,
            fontFamily: FontJSON,
            alignItems: 'center',
            justifyContent: 'center',
            fontTexture: FontImage,
            backgroundColor: new Color('blue'),
        });

        acceptBlock.add(
            new Text({
                content: 'Accept',
            }),
        );

        //@ts-ignore
        acceptBlock.setupState({
            state: 'selected',
            attributes: {
                backgroundOpacity: 0.2,
            },
            onSet: () => {
                const app = window['app'] as Application;

                app.changeScenePrivateRoom(Number(message.sender.id));

                try {
                    this.firebaseClient
                        .update(message.object_id, { is_read: true })
                        .subscribe(
                            () => {},
                            () => {},
                            () => {},
                        );
                } catch (e) {
                    console.warn(e);
                }

                this.container.removeFromParent();
                this.buttons.forEach((button) =>
                    this.intersectionContainer.remove(button),
                );
            },
        });

        //@ts-ignore
        acceptBlock.setupState({
            state: 'idle',
            attributes: {
                backgroundOpacity: 0.5,
            },
        });
        //@ts-ignore
        acceptBlock.setupState({
            state: 'hovered',
            attributes: {
                backgroundOpacity: 0.3,
            },
        });

        const declineBlock = new Block({
            width: 0.5,
            height: 0.2,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: new Color('grey'),
        });

        declineBlock.add(
            new Text({
                content: 'Decline',
            }),
        );

        //@ts-ignore
        declineBlock.setupState({
            state: 'selected',
            attributes: {
                backgroundOpacity: 0.2,
            },
            onSet: () => {
                this.firebaseClient
                    .update(message.object_id, { is_read: true })
                    .subscribe(
                        () => {},
                        () => {},
                        () => {},
                    );

                this.container.removeFromParent();
                this.buttons.forEach((button) =>
                    this.intersectionContainer.remove(button),
                );
            },
        });

        //@ts-ignore
        declineBlock.setupState({
            state: 'idle',
            attributes: {
                backgroundOpacity: 0.5,
            },
        });
        //@ts-ignore
        declineBlock.setupState({
            state: 'hovered',
            attributes: {
                backgroundOpacity: 0.3,
            },
        });

        this.buttons.push(declineBlock, acceptBlock);

        acceptOrDeclineBlock.add(acceptBlock, declineBlock);
        this.intersectionContainer.addObjectToIntersect(acceptBlock);
        this.intersectionContainer.addObjectToIntersect(declineBlock);
        this.container.add(acceptOrDeclineBlock);

        this.add(this.container);
    }

    public startListening() {
        const path = this.firebaseClient.getUniversalNotificationPath(
            this.resources.items.user.id,
        );

        this.firebaseClient.getData(path).subscribe((n) => {
            const values = n.val() || [];

            const newMessages = Object.keys(values).filter(
                (item: any) =>
                    values[item].object_type === 'NEW_MESSAGE' &&
                    !values[item].is_read,
            );

            const lastMessageIndex = newMessages.length - 1;
            const lastNotification: NotificationMessage =
                values[newMessages[lastMessageIndex]];
            if (lastNotification !== undefined) {
                this.createBlockForInvite(lastNotification);
            }
        });
    }
}
