import {
    EventDispatcher,
    Object3D,
    Raycaster,
    Vector3,
    Quaternion,
    Matrix4,
    Group,
    Sprite,
    SpriteMaterial,
    Texture,
} from 'three';

interface InteractiveObject {
    object: Object3D;
    priority: number;
    onHoverStart?: () => void;
    onHoverEnd?: () => void;
    onSelectStart?: () => void;
    onSelectEnd?: () => void;
    onRotate?: (rotation: Quaternion) => void;
}

export class InteractionManager extends EventDispatcher {
    private interactiveObjects: InteractiveObject[] = [];
    private hoveredObject: InteractiveObject | null = null;
    private selectedObject: InteractiveObject | null = null;
    private raycaster: Raycaster = new Raycaster();
    private tempMatrix = new Matrix4();
    private initialControllerQuaternion = new Quaternion();
    private initialObjectQuaternion = new Quaternion();
    private pointer: Sprite;

    public constructor(private controllers: Group[], scene: Object3D) {
        super();
        this.setupControllerListeners();
        this.createPointer();
        scene.add(this.pointer);
    }

    private setupControllerListeners() {
        this.controllers.forEach((controller) => {
            controller.addEventListener(
                'selectstart',
                this.onSelectStart.bind(this),
            );
            controller.addEventListener(
                'selectend',
                this.onSelectEnd.bind(this),
            );
        });
    }

    private createPointer() {
        const spriteMaterial = new SpriteMaterial({
            map: new Texture(this.generatePointerTexture()),
            sizeAttenuation: false,
        });
        this.pointer = new Sprite(spriteMaterial);
        this.pointer.scale.set(0.015, 0.015, 1);
        this.pointer.renderOrder = Infinity;
        this.pointer.visible = false;
    }

    private generatePointerTexture() {
        const canvas = document.createElement('canvas');
        canvas.width = 64;
        canvas.height = 64;

        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.beginPath();
            ctx.arc(32, 32, 29, 0, 2 * Math.PI);
            ctx.lineWidth = 5;
            ctx.stroke();
            ctx.fillStyle = 'white';
            ctx.fill();
        }

        return canvas;
    }

    public addInteractiveObject(
        object: Object3D,
        priority: number,
        callbacks: {
            onHoverStart?: () => void;
            onHoverEnd?: () => void;
            onSelectStart?: () => void;
            onSelectEnd?: () => void;
            onRotate?: (rotation: Quaternion) => void;
        },
    ) {
        this.interactiveObjects.push({ object, priority, ...callbacks });
        this.interactiveObjects.sort((a, b) => b.priority - a.priority);
    }

    public removeInteractiveObject(object: Object3D) {
        const index = this.interactiveObjects.findIndex(
            (io) => io.object === object,
        );
        if (index !== -1) {
            this.interactiveObjects.splice(index, 1);
        }
    }

    private isObjectOrAncestor(
        object: Object3D,
        potentialAncestor: Object3D,
    ): boolean {
        let currentObject: Object3D | null = object;
        while (currentObject !== null) {
            if (currentObject === potentialAncestor) {
                return true;
            }
            currentObject = currentObject.parent;
        }
        return false;
    }

    public update() {
        this.controllers.forEach((controller) => {
            this.tempMatrix.identity().extractRotation(controller.matrixWorld);
            this.raycaster.ray.origin.setFromMatrixPosition(
                controller.matrixWorld,
            );
            this.raycaster.ray.direction
                .set(0, 0, -1)
                .applyMatrix4(this.tempMatrix);

            const intersections = this.raycaster.intersectObjects(
                this.interactiveObjects.map((io) => io.object),
                true,
            );

            if (intersections.length > 0) {
                const newHoveredObject = this.interactiveObjects.find((io) =>
                    this.isObjectOrAncestor(intersections[0].object, io.object),
                );

                if (newHoveredObject !== this.hoveredObject) {
                    if (this.hoveredObject) {
                        this.hoveredObject.onHoverEnd?.();
                    }
                    this.hoveredObject = newHoveredObject || null;
                    this.hoveredObject?.onHoverStart?.();
                }

                // Show and position the pointer
                this.pointer.visible = true;
                this.pointer.position.copy(intersections[0].point);
            } else {
                if (this.hoveredObject) {
                    this.hoveredObject.onHoverEnd?.();
                    this.hoveredObject = null;
                }
                // Hide the pointer when not hovering over an object
                this.pointer.visible = false;
            }

            if (this.selectedObject) {
                this.updateRotation(controller);
            }
        });
    }

    private onSelectStart(event: any) {
        if (this.hoveredObject) {
            this.selectedObject = this.hoveredObject;
            this.selectedObject.onSelectStart?.();
            this.initialControllerQuaternion.copy(event.target.quaternion);
            this.initialObjectQuaternion.copy(
                this.selectedObject.object.quaternion,
            );
        }
    }

    private onSelectEnd(event: any) {
        if (this.selectedObject) {
            this.selectedObject.onSelectEnd?.();
            this.selectedObject = null;
        }
    }

    private updateRotation(controller: Group) {
        if (this.selectedObject && this.selectedObject.onRotate) {
            const currentControllerQuaternion = controller.quaternion;
            const rotationDifference = new Quaternion().multiplyQuaternions(
                currentControllerQuaternion,
                this.initialControllerQuaternion.invert(),
            );

            const newRotation = new Quaternion().multiplyQuaternions(
                rotationDifference,
                this.initialObjectQuaternion,
            );

            this.selectedObject.onRotate(newRotation);
        }
    }

    public removeAllInteractiveObjects() {
        if (this.hoveredObject) {
            this.hoveredObject.onHoverEnd?.();
            this.hoveredObject = null;
        }

        if (this.selectedObject) {
            this.selectedObject.onSelectEnd?.();
            this.selectedObject = null;
        }

        this.interactiveObjects = [];

        // Ukryj wskaźnik
        this.pointer.visible = false;
    }
}
