import { autoInjectable } from 'tsyringe';
import VrObject3D from '../Three/VrObject3D';
import {
    CircleGeometry,
    Color,
    DoubleSide,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    RingGeometry,
    Vector3,
} from 'three';

@autoInjectable()
export default class TeleportationRing extends VrObject3D {
    public rings: Object3D[] = [];

    public field: Object3D;

    public name = 'TeleportationRing';

    public userData: { [key: string]: any } = {
        interestable: false,
    };

    public constructor() {
        super();
        this.setRing();
    }

    public setRing() {
        const circleGeometry = new CircleGeometry(0.1, 32);

        const ringGeometry = new RingGeometry(0.1, 0.15, 16);
        const ringMaterial = new MeshBasicMaterial({
            color: new Color('red'),
            side: DoubleSide,
        });
        this.field = new Mesh(circleGeometry, ringMaterial);
        this.field.name = 'TeleportationRing';

        this.field.quaternion.setFromAxisAngle(
            new Vector3(1, 0, 0),
            -Math.PI / 2,
        );

        const ring = new Mesh(
            ringGeometry,
            new MeshBasicMaterial({
                color: new Color('white'),
                side: DoubleSide,
            }),
        );
        ring.quaternion.setFromAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2);

        // if (this.debug) {
        //     const box = new Box3().setFromObject(this.field);

        //     const helper = new Box3Helper(box, new Color('blue'));
        //     this.add(helper);
        // }
        this.add(ring);
        this.field.visible = false;
        this.add(this.field);
    }
}
