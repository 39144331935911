// @ts-ignore
import Stats from 'stats.js';
import EventEmitter from './EventEmitter';
import { container, singleton } from 'tsyringe';
import { Clock } from 'three';

@singleton()
export default class Time extends EventEmitter {
    public start: number;
    public current: any;
    public elapsed: number;
    public delta: number;
    public stats: any;
    public ticker: number;

    private static clock: Clock = new Clock(false);
    private static lastCallTime: number = 0;
    private static interval: number;
    public constructor() {
        super();

        this.start = Date.now();
        this.current = this.start;
        this.elapsed = 0;
        this.delta = 16;

        this.setStats();

        this.tick = this.tick.bind(this);
    }

    public setStats() {
        this.stats = new Stats();
        this.stats.showPanel(0);
        if (window.location.hash === '#debug') {
            document.body.appendChild(this.stats.dom);
        }
    }

    public tick() {
        this.stats.begin();
        const current = Date.now();

        this.delta = current - this.current;
        this.elapsed = this.current - this.start;
        this.current = current;

        if (this.delta > 60) {
            this.delta = 60;
        }

        this.trigger('tick');
        this.stats.end();
    }

    public stop() {
        window.cancelAnimationFrame(this.ticker);
    }

    public static limitFrames(callback: () => void, fps: number): void {
        if (!this.clock.running) {
            this.clock.start();
            this.lastCallTime = this.clock.getElapsedTime();
        }

        const currentTime = this.clock.getElapsedTime();
        const deltaTime = (currentTime - this.lastCallTime) * 1000; // zamiana sekund na milisekundy
        this.interval = 1000 / fps;

        if (deltaTime > this.interval) {
            callback();
            this.lastCallTime =
                currentTime - ((deltaTime / 1000) % (this.interval / 1000));
        }
    }
}
