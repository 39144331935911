import Renderer from '../Renderer';
import { autoInjectable, container, singleton } from 'tsyringe';
import IntersectionContainer from './IntersectionContainer';

interface AxesEvent {
    x: number;
    y: number;
    type: string;
}

@singleton()
@autoInjectable()
export class MovementService {
    public controllers?: any = null;

    private rotateTriggered = false;
    private moveTriggered = false;

    public static FORWARD = 'forward';
    public static BACKWARD = 'backward';
    public static RIGHT = 'right';
    public static LEFT = 'left';

    public initialSettings = {
        leftHanded: false,
    };

    public constructor(
        public renderer?: Renderer,
        public intersectionContainer?: IntersectionContainer,
    ) {
        this.setupInitialSettings();
    }

    private setupInitialSettings() {
        const settings = localStorage.getItem('initialSettings');
        this.initialSettings = settings
            ? JSON.parse(settings)
            : this.initialSettings;
        if (!settings) {
            localStorage.setItem(
                'initialSettings',
                JSON.stringify(this.initialSettings),
            );
        }
    }

    public static isInRange(value: number, min: number, max: number): boolean {
        return value >= min && value <= max;
    }

    public update() {}

    public onMoveForwardOrBackwards(
        event: AxesEvent,
        callback: (direction: string) => void,
    ) {
        const { y } = event;
        if (y <= 1 && y >= 0.6 && !this.moveTriggered) {
            this.moveTriggered = true;
            callback(MovementService.BACKWARD);
        }

        if (y >= -1 && y <= -0.6 && !this.moveTriggered) {
            this.moveTriggered = true;
            callback(MovementService.FORWARD);
        }

        if (y <= 0.5 && y >= 0) {
            this.moveTriggered = false;
        }
        if (y >= -0.5 && y <= 0) {
            this.moveTriggered = false;
        }
    }
    public onRotateCamera(
        event: AxesEvent,
        callback: (direction: string) => void,
    ) {
        if (event.x <= 1 && event.x >= 0.6 && !this.rotateTriggered) {
            callback(MovementService.LEFT);

            this.rotateTriggered = true;
        }

        if (event.x >= -1 && event.x <= -0.6 && !this.rotateTriggered) {
            callback(MovementService.RIGHT);
            this.rotateTriggered = true;
        }

        if (event.x <= 0.5 && event.x >= 0) {
            this.rotateTriggered = false;
        }
        if (event.x >= -0.5 && event.x <= 0) {
            this.rotateTriggered = false;
        }
    }
}
