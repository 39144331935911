import { inject, singleton } from 'tsyringe';
import AxiosHttpClient from '../AxiosHttpClient';

export interface IUser {
    id: number;
    is_school_teacher: boolean;
    is_school_student: boolean;
    school_name: string;
    email: string;
    username: string;
    is_school_admin: boolean;
    school_id: number;
}

@singleton()
export class User implements IUser {
    public id: number;
    public is_school_teacher: boolean;
    public is_school_student: boolean;
    public school_name: string;
    public email: string;
    public username: string;
    public is_school_admin: boolean;
    public school_id: number;

    public constructor(public httpClient?: AxiosHttpClient) {
        const data = this.httpClient.getUser();

        data.then((res) => {
            this.id = res.data.id;
            this.is_school_teacher = res.data.is_school_teacher;
            this.is_school_student = res.data.is_school_student;
            this.school_name = res.data.school_name;
            this.email = res.data.email;
            this.username = res.data.username;
            this.is_school_admin = res.data.is_school_admin;
        });
    }
}
