import React, { useEffect, useRef } from 'react';

interface PublisherAudioPlayerProps {
    stream: MediaStream;
    username: string;
    displayName?: string;
    isLocal?: boolean;
}

export const PublisherAudioPlayer: React.FC<PublisherAudioPlayerProps> = ({
    stream,
    username,
    displayName,
    isLocal = false,
}) => {
    const audioRef = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        if (audioRef.current && stream) {
            try {
                if (audioRef.current.srcObject) {
                    const oldStream = audioRef.current.srcObject as MediaStream;
                    oldStream.getTracks().forEach((track) => {
                        try {
                            track.stop();
                        } catch (e) {
                            console.warn('Error stopping track:', e);
                        }
                    });
                }

                audioRef.current.srcObject = stream;

                audioRef.current.play().catch((err) => {
                    console.error(`Error playing audio for ${username}:`, err);
                });
            } catch (e) {
                console.error(`Error setting audio source for ${username}:`, e);
            }
        }

        return () => {
            try {
                if (audioRef.current?.srcObject) {
                    const oldStream = audioRef.current.srcObject as MediaStream;
                    oldStream.getTracks().forEach((track) => track.stop());
                }
            } catch (e) {
                console.warn('Error in audio cleanup:', e);
            }
        };
    }, [stream, username]);

    return (
        <audio
            ref={audioRef}
            autoPlay
            playsInline
            muted={isLocal}
            id={`audio-${username}`}
            data-username={username}
            data-display-name={displayName}
        />
    );
};

export default PublisherAudioPlayer;
