export function checkRoomConnection(
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor,
) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
        if (
            this.room &&
            this.room.connection &&
            this.room.connection.transport.ws &&
            this.room.connection.transport.ws.readyState === WebSocket.OPEN
        ) {
            return originalMethod.apply(this, args);
        } else {
            console.error(
                'WebSocket is not in OPEN state. Current state: ' +
                    this.room?.connection?.transport?.ws?.readyState,
            );
        }
    };

    return descriptor;
}
