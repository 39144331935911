import React, { useEffect, useState } from 'react';
import { container } from 'tsyringe';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import defaultCourseIcon from '@assets/icons/defaultCourseIcon.svg';
import envierments from '../../../Environments/envierments';
import { ClipLoader } from 'react-spinners';
import CourseDetails from './CourseDetails';

const CoursesView = () => {
    const httpClient = container.resolve(AxiosHttpClient);

    const [allCollections, setAllCollections] = useState([]);
    const [courses, setCourses] = useState([]);
    const [showMask, setShowMask] = useState(true);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [courseDetails, setCourseDetails] = useState(null);

    useEffect(() => {
        fetchCollections();
    }, []);

    const fetchCollections = async () => {
        try {
            const cachedCollections = localStorage.getItem('courseCollections');
            const cachedCourses = localStorage.getItem('courses');

            if (cachedCollections && cachedCourses) {
                setAllCollections(JSON.parse(cachedCollections));
                setCourses(JSON.parse(cachedCourses));
                setShowMask(false);
            } else {
                let collection = await httpClient.getCourseCollection();
                let _courses = [];

                if (collection.data.length > 0) {
                    setAllCollections(collection.data);
                    localStorage.setItem(
                        'courseCollections',
                        JSON.stringify(collection.data),
                    );

                    for (let cCourses of collection.data) {
                        for (let course of cCourses.collections) {
                            _courses.push(course);
                        }
                    }
                    setCourses(_courses);
                    localStorage.setItem('courses', JSON.stringify(_courses));
                }
            }
            setShowMask(false);
        } catch (error) {
            console.error('Error fetching collections:', error);
            setShowMask(false);
        }
    };

    const handleShowCourseDetails = async (courseId) => {
        setShowMask(true);
        try {
            setSelectedCourse(courseId);

            const cachedCourseDetails = localStorage.getItem(
                `courseDetails_${courseId}`,
            );

            if (cachedCourseDetails) {
                setCourseDetails(JSON.parse(cachedCourseDetails));
            } else {
                const response = await httpClient.getCollection(courseId);
                setCourseDetails(response.data);
                localStorage.setItem(
                    `courseDetails_${courseId}`,
                    JSON.stringify(response.data),
                );
            }
        } catch (error) {
            console.error('Error fetching course details:', error);
        }
        setShowMask(false);
    };

    const Loader = () =>
        showMask && (
            <div className="spinner-clip">
                <ClipLoader
                    color={'#FFF'}
                    loading={showMask}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        );

    const CourseList = () => (
        <div className="collections-model-viewer">
            {courses.map((course) => (
                <div
                    className="model3d-course"
                    key={course.id}
                    onClick={() => handleShowCourseDetails(course.id)}
                >
                    <img
                        className="skybox-icon"
                        src={
                            course.icon_url ===
                            '/media/img/default_course_icon.svg'
                                ? defaultCourseIcon
                                : `${envierments.baseURL}/file/serve/${course.icon_url}`
                        }
                        alt={course.title}
                    />
                    <div className="model3d-course-title">
                        <p>{course.title}</p>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <>
            <Loader />
            {selectedCourse === null ? (
                <CourseList />
            ) : (
                <CourseDetails course={courseDetails} />
            )}
        </>
    );
};

export default CoursesView;
