import { autoInjectable } from 'tsyringe';
import VrObject3D from '../Three/VrObject3D';
import ThreeMeshUI, { Block, Text } from 'three-mesh-ui';
import IntersectionContainer from '../Controllers/IntersectionContainer';
//@ts-ignore
import FontJSON from './assets/Roboto-msdf.json';
//@ts-ignore
import FontImage from './assets/Roboto-msdf.png';
import Utils from '../../Utils/Utils';
import { Color } from 'three';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import { useState } from 'react';
import { Base64 } from 'js-base64';
import Resources from '../../Resources';
import TranslationService from '../../Translations/TranslationService';

interface User {
    first_name: string;
    id: number;
    last_name: string;
    photo_thumbnail_url: string;
    photo_url: string;
    profile_type: number;
    school_classes: any[];
    username: string;
}

@autoInjectable()
export default class PlayerInviter extends VrObject3D {
    public input: HTMLInputElement | null = null;
    public inputValue: string = '';
    public inputTimer: any;
    public enabled: boolean = false;

    public searchBlockText = new Text({
        content: TranslationService.translate('vr.find_friends_to_invite'),
    });

    public searchResultBlock: Block | null = null;

    public usersInviteBlocks: Block[] = [];

    public container: Block | null = null;

    public constructor(
        public intersectionContainer?: IntersectionContainer,
        public httpClient?: AxiosHttpClient,
        public resources?: Resources,
    ) {
        super();
        this.input = document.createElement('input');
        this.input.type = 'text';
        this.input.classList.add('friends-search-input-vr');
        this.input.classList.add('hidden');
        this.makeSearchBlock();
        this.hidde();

        this.input.onchange = this.onInput;
    }

    public makeSearchBlock() {
        this.container = new Block({
            width: 2,
            height: 2.5,
        });

        setInterval(() => {
            //@ts-ignore
            this.container.update();
        }, 1000);

        const searchBox = new Block({
            width: 1.9,
            height: 0.2,
            margin: 0.1,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            backgroundColor: new Color('#333333'),
            justifyContent: 'center',
            borderRadius: 0.1,
            bestFit: 'auto',
        });
        //@ts-ignore
        searchBox.setupState({
            state: 'selected',
            attributes: {
                // backgroundColor: new Color(0x6A6C6C),
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.1,
            },
            onSet: () => {
                document.body.appendChild(this.input);

                this.input.focus();
                console.log('focus');
            },
        });

        //@ts-ignore
        searchBox.setupState({
            state: 'idle',
            attributes: {
                backgroundColor: new Color('#ffffff'),
                backgroundOpacity: 0.3,
            },
        });
        //@ts-ignore
        searchBox.setupState({
            state: 'hovered',
            attributes: {
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.1,
            },
        });

        this.searchResultBlock = new ThreeMeshUI.Block({
            width: 1.9,
            height: 1.5,
            contentDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundOpacity: 0,
        });

        this.intersectionContainer.addObjectToIntersect(searchBox);

        searchBox.add(this.searchBlockText);
        this.container.add(searchBox);
        this.container.add(this.searchResultBlock);

        this.add(this.container);
    }

    public onInput = async (e) => {
        const inputValue = this.input.value;

        const delayedSearch = async () => {
            if (inputValue.length >= 3) {
                const users = await this.httpClient.findTeachersOrTeachers(
                    Utils.stripNonAplhaNumericAndHtml(inputValue),
                );
                if (users.data.length > 0) {
                    const topFiveUsers = users.data.slice(0, 5);

                    this.handleUsersChange(topFiveUsers);
                }
            }
        };
        //@ts-ignore
        this.searchBlockText.set({
            content: Utils.stripNonAplhaNumericAndHtml(inputValue),
        });

        if (this.inputTimer) {
            clearTimeout(this.inputTimer);
        }

        this.inputTimer = setTimeout(delayedSearch, 1000);
    };

    public handleUsersChange(users: User[]) {
        this.searchResultBlock.remove(...this.searchResultBlock.children);

        this.usersInviteBlocks.forEach((block) => {
            this.intersectionContainer.remove(block);
        });

        users.forEach((user) => this.createUserBlock(user));

        setTimeout(() => {
            //@ts-ignore
            this.searchBlockText.set({
                //@ts-ignore
                content: this.searchBlockText.content + ' ',
            });

            this.usersInviteBlocks.forEach((block) => {
                //@ts-ignore
                block.set({});
                //@ts-ignore
                block.update();
            });
            //@ts-ignore
            this.searchResultBlock.update();
        }, 0);
    }

    public createUserBlock(user: User) {
        const block = new ThreeMeshUI.Block({
            width: 1.5,
            height: 0.2,
            backgroundColor: new Color('red'),
            backgroundOpacity: 0,
            borderWidth: 0.005,
            borderColor: new Color('white'),
            contentDirection: 'row',
            justifyContent: 'start',
        });

        const inviteBlock = new Block({
            width: 0.5,
            height: 0.2,
            padding: 0.05,
            backgroundColor: new Color('white'),
            borderWidth: 0,
            backgroundOpacity: 0,
            borderColor: new Color('white'),
            fontFamily: FontJSON,
            fontTexture: FontImage,
            alignItems: 'center',
            justifyContent: 'center',
        });

        inviteBlock.userData.clicked = false;

        //@ts-ignore
        inviteBlock.setupState({
            state: 'selected',
            attributes: {
                // backgroundColor: new Color(0x6A6C6C),
                backgroundOpacity: 0.1,
            },
            onSet: async () => {
                if (inviteBlock.userData.clicked) {
                    return;
                }

                //@ts-ignore
                inviteBlock.set({
                    backgroundColor: new Color('grey'),
                });

                //@ts-ignore
                inviteText.set({
                    content: TranslationService.translate('vr.friends_invited'),
                });
                this.intersectionContainer.removeFromIntersect(inviteBlock);
                await this.httpClient.sendMessage({
                    recipient: user.username,
                    subject: TranslationService.translate(
                        `You have been invited into {username} private space`,
                        this.resources.items.user.username,
                    ),
                    body: Base64.btoa(this.resources.items.user.id),
                    uploaded_audio_file_id: null,
                    uploaded_file_id: null,
                });

                if (inviteBlock.userData.clicked === false) {
                    inviteBlock.userData.clicked = true;
                }
            },
        });

        //@ts-ignore
        inviteBlock.setupState({
            state: 'idle',
            attributes: {
                backgroundOpacity: 0.5,
            },
        });
        //@ts-ignore
        inviteBlock.setupState({
            state: 'hovered',
            attributes: {
                backgroundOpacity: 0.3,
            },
        });

        const inviteText = new Text({
            content: 'invite',
            textAlign: 'center',
        });

        inviteBlock.add(inviteText);

        this.usersInviteBlocks.push(inviteBlock);

        block.add(
            new ThreeMeshUI.Block({
                width: 1,
                height: 0.2,
                fontFamily: FontJSON,
                fontTexture: FontImage,
                borderWidth: 0,
                backgroundOpacity: 0,
                alignItems: 'center',
                justifyContent: 'center',
            }).add(
                new ThreeMeshUI.Text({
                    content: Utils.stripNonAplhaNumericAndHtml(user.username),
                }),
            ),
        );

        block.add(inviteBlock);
        this.intersectionContainer.addObjectToIntersect(inviteBlock);

        //@ts-ignore
        this.searchResultBlock.update();
        ThreeMeshUI.update();
        this.searchResultBlock.add(block);
    }

    public show() {
        this.container.visible = true;
        this.usersInviteBlocks.forEach((block) =>
            this.intersectionContainer.addObjectToIntersect(block),
        );
        this.enabled = true;
    }

    public hidde() {
        this.enabled = false;
        this.container.visible = false;
        this.usersInviteBlocks.forEach((block) =>
            this.intersectionContainer.remove(block),
        );
    }
}
