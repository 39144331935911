import React, { createContext, useContext, ReactNode } from 'react';
import { useLoadedModels } from './useLoadedModels';

interface LoadedModel {
    id: number;
    url: string;
    lessonName: string;
    icon: string;
}

interface LoadedModelsContextType {
    loadedModels: LoadedModel[];
    addLoadedModel: (model: LoadedModel) => void;
    removeModel: (modelId: number) => void;
    resetAllModels: () => void;
    handleChangeModel: (model: LoadedModel) => void;
    handleLoadModels: () => void;
    totalSlots: number;
    currentModelIndex: number;
    setCurrentModelIndex: (index: number) => void;
}
const LoadedModelsContext = createContext<LoadedModelsContextType | undefined>(
    undefined,
);

interface LoadedModelsProviderProps {
    children: ReactNode;
}

export const LoadedModelsProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const loadedModelsState = useLoadedModels();

    return (
        <LoadedModelsContext.Provider value={loadedModelsState}>
            {children}
        </LoadedModelsContext.Provider>
    );
};

export const useLoadedModelsContext = (): LoadedModelsContextType => {
    const context = useContext(LoadedModelsContext);
    if (context === undefined) {
        throw new Error(
            'useLoadedModelsContext must be used within a LoadedModelsProvider',
        );
    }
    return context;
};
