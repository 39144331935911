import { useEffect, useState } from 'react';
import { container } from 'tsyringe';
import DoorService from '../../Components/Doors/Door.service';
import { ListElement } from './list-element';

export const Classes = ({ hidden, clickLessonRoomType, lessonSceneType }) => {
    const doorService = container.resolve(DoorService);

    const [classes, setClasses] = useState([]);

    useEffect(() => {
        doorService.getClassesSorted().then((res) => {
            setClasses(res);
        });
    }, []);

    return (
        <div className={'menu-list ' + (hidden ? 'hidden' : '')}>
            {classes.map((classes, key) => {
                return (
                    <ListElement
                        roomId={classes.subjectId}
                        isGroup={false}
                        key={key}
                        sceneName={
                            classes.className + ' - ' + classes.subjectName
                        }
                        clickLessonRoomType={lessonSceneType}
                    >
                        {classes.className} - {classes.subjectName}
                    </ListElement>
                );
            })}

            {classes.length === 0 ? (
                <div className={'not-assigned-menu'}>
                    <p className="text-center text-white pb-0 mb-0">
                        You are not assigned to any class
                    </p>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
