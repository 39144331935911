import React, { useState, useEffect, useRef } from 'react';
import { FaCheck, FaTrashAlt } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';

const TextToDrag = ({
    canvasWrapperRef,
    sketchboardsRef,
    selectedSketchboard,
    onSuccess,
    onCancle,
}) => {
    const [width, setWidth] = useState(400);
    const [height, setHeight] = useState(100);

    const textarea = useRef<HTMLTextAreaElement>();
    const [position, setPosition] = useState({
        x: window.screen.width / 2 - 200,
        y: window.screen.height / 2 - 150,
    });
    const isDragging = useRef(false);
    const [isSelected, setIsSelected] = useState(false);

    const dragStart = useRef({
        x: window.screen.width / 2 - 200,
        y: window.screen.height / 2 - 150,
    });

    const handler = (mouseDownEvent) => {
        const startWidth = width;
        const startHeight = height;
        const startPosition = {
            x: mouseDownEvent.pageX,
            y: mouseDownEvent.pageY,
        };

        const onMouseMove = (mouseMoveEvent) => {
            const newWidth =
                startWidth - startPosition.x + mouseMoveEvent.pageX;
            const newHeight =
                startHeight - startPosition.y + mouseMoveEvent.pageY;

            setHeight(newHeight);
            setWidth(newWidth);
        };

        function onMouseUp() {
            document.body.removeEventListener('mousemove', onMouseMove);
        }

        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onMouseUp, { once: true });
    };

    const handleMouseDown = (event) => {
        if (event.target.id !== 'draghandle') {
            setIsSelected(true);
            dragStart.current = {
                x: event.clientX - position.x,
                y: event.clientY - position.y,
            };
            isDragging.current = true;
        }
    };

    const handleMouseMove = (event) => {
        if (isDragging.current) {
            const newX = event.clientX - dragStart.current.x;
            const newY = event.clientY - dragStart.current.y;
            setPosition({ x: newX, y: newY });
        }
    };

    const handleMouseUp = () => {
        isDragging.current = false;
    };

    const handleDrawText = (event) => {
        const canvasElements = sketchboardsRef.current as HTMLElement;
        const canvas = canvasElements.childNodes[
            selectedSketchboard
        ] as HTMLCanvasElement;

        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        const text = textarea.current.value;

        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = textarea.current.clientWidth;
        tempCanvas.height = textarea.current.clientHeight;

        const tempContext = tempCanvas.getContext('2d');

        const lineHeight = 24; // Musi być zgodne z używaną czcionką
        tempContext.font = `${lineHeight}px Arial`;
        tempContext.textAlign = 'left';
        tempContext.textBaseline = 'top';

        const lines = text.split('\n');

        lines.forEach((line, index) => {
            tempContext.fillText(line, 0, index * lineHeight);
        });

        const dataURL = tempCanvas.toDataURL('image/png');
        const img = new Image();
        img.src = dataURL;

        img.onload = () => {
            const textareaRect = textarea.current.getBoundingClientRect();
            const canvasRect = canvas.getBoundingClientRect();

            const relativeX = textareaRect.left - canvasRect.left;
            const relativeY = textareaRect.top - canvasRect.top;
            //
            // canvas
            //     .getContext('2d')
            //     .drawImage(
            //         img,
            //         relativeX * scaleX,
            //         relativeY * scaleY,
            //         tempCanvas.width * scaleX,
            //         tempCanvas.height * scaleY,
            //     );
            onSuccess({
                image: dataURL, // przekazanie base64 zamiast obrazu
                dx: relativeX * scaleX,
                dy: relativeY * scaleY,
                dWidth: tempCanvas.width * scaleX,
                dHeight: tempCanvas.height * scaleY,
            });
        };
    };

    const handleCancel = () => {
        onCancle();
    };

    useEffect(() => {
        const canvas = canvasWrapperRef.current;

        if (canvas) {
            canvas.addEventListener('mousemove', handleMouseMove);
            canvas.addEventListener('pointerup', handleMouseUp);
            canvas.addEventListener('mouseleave', handleMouseUp);
        }

        return () => {
            if (canvas) {
                canvas.removeEventListener('mousemove', handleMouseMove);
                canvas.removeEventListener('pointerup', handleMouseUp);
                canvas.removeEventListener('mouseleave', handleMouseUp);
            }
        };
    }, []);

    return (
        <div
            className={`sketchboard-text ${isSelected ? 'selected' : ''}`}
            style={{
                position: 'absolute',
                left: `${position.x}px`,
                top: `${position.y}px`,
                width: `${width}px`,
                height: `${height}px`,
                cursor: isDragging.current ? 'grabbing' : 'grab',
            }}
            onMouseDown={handleMouseDown}
        >
            <div
                id="container"
                className="skecthboard-text-container"
                style={{ width: '100%', height: '100%' }}
            >
                <textarea className="textarea-sketchboard" ref={textarea} />
                <button
                    id="draghandle"
                    type="button"
                    onMouseDown={handler}
                ></button>

                <div className="d-flex flex-row">
                    <button className="place-image" onClick={handleDrawText}>
                        <FaCheck />
                    </button>
                    <button className="delete-image" onClick={handleCancel}>
                        <FaTrashAlt />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TextToDrag;
