export default {
    baseURL: 'https://mcourser-dev.com/',
    janus: 'https://janus.lncdev.pl/janus',
    colyseus: 'wss://colyseusdev.lncdev.pl',
    colyseusHttp: 'https://colyseusdev.lncdev.pl',
    openAi: {
        assistantId: 'asst_8hxo3hLxcRlV6Ku2S1w5eOpr',
    },
    users: {
        sTKRASS1001: {
            username: 'sTKRASS1001',
            password: 's',
        },
        tTKRASS0001: {
            username: 'tTKRASS0001',
            password: 't',
        },
        VRstudent01: {
            username: 'VRstudent01',
            password: 's',
        },
        VRstudent02: {
            username: 'VRstudent02',
            password: 's',
        },
        VRstudent03: {
            username: 'VRstudent03',
            password: 's',
        },
        VRstudent04: {
            username: 'VRstudent04',
            password: 's',
        },
        VRstudent05: {
            username: 'VRstudent05',
            password: 's',
        },
        VRteacher01: {
            username: 'VRteacher01',
            password: 't',
        },
        TeacherPublisher: {
            username: 'ap_teacher01',
            password: 't',
        },
        without_school: {
            username: 'testingvr01',
            password: 's',
        },
    }
};
