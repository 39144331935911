import {
    EventDispatcher,
    Frustum,
    Matrix,
    Matrix4,
    Mesh,
    Raycaster,
    Vector2,
} from 'three';
import { autoInjectable, singleton } from 'tsyringe';
import Renderer from '../Renderer';
import Camera from '../../Camera';
import IntersectionContainer from './IntersectionContainer';
import { acceleratedRaycast } from 'three-mesh-bvh';

@singleton()
@autoInjectable()
export default class Controlls extends EventDispatcher {
    private raycaster = new Raycaster();
    private objectsToIntersect: any[] = [];
    private pointer = new Vector2();
    public intersection: any;
    public selectState = false;
    public pointedObject = null;

    private clickedObject: any = null;

    public constructor(
        public renderer: Renderer,
        public camera: Camera,
        public intersectionContainer: IntersectionContainer,
    ) {
        super();

        this.raycaster.firstHitOnly = true;

        this.renderer.webGLRenderer.domElement.addEventListener(
            'pointermove',
            (e) => {
                this.mouseMove(e);
            },
        );

        this.renderer.webGLRenderer.domElement.addEventListener(
            'pointerdown',
            (e) => {
                if (e.button === 0) {
                    this.selectState = true;
                    this.handlePointerDown(e);
                }
            },
        );

        this.renderer.webGLRenderer.domElement.addEventListener(
            'pointerup',
            (e) => {
                this.selectState = false;
                this.clickedObject = null;
            },
        );
        this.renderer.webGLRenderer.domElement.addEventListener(
            'touchstart',
            (e) => (this.selectState = true),
        );
        this.renderer.webGLRenderer.domElement.addEventListener(
            'touchend',
            (e) => (this.selectState = false),
        );
    }

    public getAllIntersectObjects() {
        return this.raycaster.intersectObjects(
            this.intersectionContainer.objectsToIntersect,
            true,
        );
    }

    public addObjectsToIntersect(objects: any[]) {
        this.objectsToIntersect = this.objectsToIntersect.concat(objects);
    }

    private mouseMove(e) {
        const rect =
            this.renderer.webGLRenderer.domElement.getBoundingClientRect();
        this.pointer.x =
            ((e.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
        this.pointer.y =
            -((e.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
    }

    private raycast() {
        let all = this.intersectionContainer.objectsToIntersect;
        let allIntersectedObjects = this.raycaster.intersectObjects(all, true);

        let pointedObject = this.intersectionContainer
            .getUiObjects()
            .reduce((closestIntersection, obj) => {
                this.intersection = this.raycaster.intersectObject(obj, true);

                if (!this.intersection[0]) return closestIntersection;

                if (
                    !closestIntersection ||
                    this.intersection[0].distance < closestIntersection.distance
                ) {
                    this.intersection[0].object = obj;

                    return this.intersection[0];
                }
                return closestIntersection;
            }, null);

        pointedObject = this.checkPointedObjectType(pointedObject);
        this.pointedObject = pointedObject;

        return pointedObject;
    }

    public update() {
        let intersection;

        if (this.pointer.x !== null && this.pointer.y !== null) {
            this.raycaster.setFromCamera(this.pointer, this.camera.instance);

            if (this.raycast !== null) {
                intersection = this.raycast();

                this.updateIntersectionState(intersection);
            }
        }
    }

    public updateIntersectionState(intersection) {
        if (this.renderer.webGLRenderer.xr.isPresenting) {
            return;
        }

        if (intersection && intersection.object.isUI) {
            if (
                intersection.object.userData.hasOwnProperty('clickable') &&
                !intersection.object.userData.clickable
            ) {
                return;
            }

            if (
                intersection.object.userData.hasOwnProperty('intersectable') &&
                !intersection.object.userData.intersectable
            ) {
                return;
            }

            if (
                this.selectState &&
                intersection.object === this.clickedObject
            ) {
                intersection.object.setState('selected');
            } else if (intersection.object.states.hovered) {
                intersection.object.setState('hovered');
            }
        }

        this.intersectionContainer.objectsToIntersect.forEach((obj) => {
            if (!intersection || obj !== intersection.object) {
                if (obj.isUI && obj.states.idle) {
                    obj.setState('idle');
                }
            }
        });
    }

    private handlePointerDown(e: PointerEvent) {
        const rect =
            this.renderer.webGLRenderer.domElement.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
        const y = -((e.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

        this.raycaster.setFromCamera(new Vector2(x, y), this.camera.instance);
        const intersects = this.raycast();

        if (intersects) {
            this.clickedObject = intersects.object;
        } else {
            this.clickedObject = null;
        }
    }

    public disableInteractivity(pointedObject) {
        // Set clickable for object in parent with hidden overflow:
        // 1 - check, if clicked object has property userData.type 'ui-board-active-item',
        // 2 - next check if intersection goes through objects with set overflowHidden
        //     (userData.type 'ui-board-row-wrapper', userData.type'ui-board').

        let parentBoardId = pointedObject.object.userData.parentBoardId;
        let isOnRow = false;
        let isOnBoard = false;

        let allIntersectedObjects = this.getAllIntersectObjects();

        for (let i = 0; i < allIntersectedObjects.length; i++) {
            let intersectedObject = allIntersectedObjects[i];

            if (
                intersectedObject.object.parent.userData.type ===
                    'ui-board-row-wrapper' &&
                intersectedObject.object.parent.userData.parentBoardId ===
                    parentBoardId
            ) {
                isOnRow = true;
                break;
            }
        }

        for (let i = 0; i < allIntersectedObjects.length; i++) {
            let intersectedObject = allIntersectedObjects[i];

            if (
                intersectedObject.object.parent.userData.type === 'ui-board' &&
                intersectedObject.object.parent.userData.id === parentBoardId
            ) {
                isOnBoard = true;
                break;
            }
        }

        if (!isOnRow || !isOnBoard) {
            pointedObject = null;
        }

        return pointedObject;
    }

    checkPointedObjectType(pointedObject) {
        //overflow hidden on parent
        if (
            pointedObject !== null &&
            pointedObject.object.userData.type === 'ui-board-active-item'
        ) {
            pointedObject = this.disableInteractivity(pointedObject);
        }

        //sketchBoard
        if (
            pointedObject !== null &&
            pointedObject.object.name === 'canvasBoard'
        ) {
            // console.log('canvasBoard')
        }

        return pointedObject;
    }
}
