import { Block, Text } from 'three-mesh-ui';
import VrObject3D from '../Three/VrObject3D';
import { autoInjectable, injectable } from 'tsyringe';
import { Color, RepeatWrapping, TextureLoader } from 'three';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import Renderer from '../Renderer';
import envierments from '../../../Environments/envierments';
import FontJSON from '@assets/fonts/Roboto-Regular-msdf.json';
import FontImage from '@assets/fonts/Roboto-Regular.png';

import IconScrollLeftWhite from '../../../../static/assets/icons/icon-name-scroll-left-white-full.png';
import IconScrollRightWhite from '../../../../static/assets/icons/icon-name-scroll-right-white-full.png';
import IconScrollUpWhite from '../../../../static/assets/icons/icon-name-scroll-up-white-full.png';
import IconScrollDownWhite from '../../../../static/assets/icons/icon-name-scroll-down-white-full.png';
import IconCourseCover from '../../../../static/assets/icons/icon-name-book.png';

import Utils from '../../Utils/Utils';
import defaultCourseIcon from '../../../../static/assets/icons/defaultCourseIcon.png';
import { MaskLoader } from './MaskLoader';
@autoInjectable()
export default class CourseView extends VrObject3D {
    public container: Block;

    public mask: boolean = true;
    public containerMask: MaskLoader;

    public constructor(
        public collection: object,
        public httpClient?: AxiosHttpClient,
        public intersectionContainer?: IntersectionContainer,
        public renderer?: Renderer,
    ) {
        super();

        this.fetchView();
    }

    public removeObject(obj) {
        let allChild = [];

        obj.traverse(function (child) {
            if (child instanceof Block || VrObject3D) {
                allChild.push(child);
            }
        });

        let all = this.intersectionContainer.objectsToIntersect;
        all = all.filter(
            (one) => !allChild.find((two) => one.uuid == two.uuid),
        );
        this.intersectionContainer.objectsToIntersect = all;

        let parent = obj.parent;
        parent.remove(obj);
    }

    public setDefaultState(block: Block, click?: Function) {
        let selectedAttribute: any = {
            state: 'selected',
            attributes: {
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.1,
            },
        };

        if (click) {
            selectedAttribute = {
                state: 'selected',
                attributes: {
                    backgroundColor: new Color(0xffffff),
                    backgroundOpacity: 0.1,
                },
                onSet: () => {
                    click();
                },
            };
        }

        //@ts-ignore
        block.setupState({
            state: 'idle',
            attributes: {
                backgroundOpacity: 0.001,
            },
        });
        //@ts-ignore
        block.setupState({
            state: 'hovered',
            attributes: {
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.1,
            },
        });

        //@ts-ignore
        block.setupState(selectedAttribute);
    }

    mapChildren(data) {
        //set the output with all results having no parent ids by default
        let output = data.filter((x) => !x.parent_id);

        //add children to existing results
        output = data.reduce((result, current) => {
            const { parent_id } = current;

            if (!parent_id) {
                return result;
            }

            const existingRecord = result.find((x) => x.id === parent_id);

            if (existingRecord) {
                if (!existingRecord.children) {
                    existingRecord.children = [];
                }

                existingRecord.children.push({ ...current });
            }

            return result;
        }, output);

        return output;
    }

    public async fetchView() {
        let collection;

        if (this.mask) {
            const containerMask = new MaskLoader();
            //@ts-ignore
            containerMask.position.z = 0.15;
            //@ts-ignore
            containerMask.container.width = 4.4;
            //@ts-ignore
            containerMask.container.height = 2.7;
            this.add(containerMask);
            this.containerMask = containerMask;
        }

        try {
            // @ts-ignore
            collection = await this.httpClient.getCollection(
                // @ts-ignore
                this.collection.id,
            );

            this.mask = false;
        } catch (err) {
            console.log('Error w pobieraniu danych');
        }

        if (!this.mask) {
            this.containerMask.removeMask();
        }

        const chapters = collection.data.chapters;

        const chaptersWithoutParent = this.mapChildren(chapters);

        let borderWidth = 0.01; //0.005
        let borderColor = new Color(0xffffff);
        let borderOpacity = 0.8;
        let offsetActiveObj = 0.03;

        //MAIN CONTAINER
        const container = new Block({
            width: 4.4,
            height: 2.7,
            opacity: 1,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            hiddenOverflow: true,
            backgroundOpacity: 0,
            // backgroundOpacity: 1,
            // backgroundColor: new Color('red'),
            borderRadius: 0,
            borderWidth: borderWidth,
            borderColor: borderColor,
            borderOpacity: 0,
            // padding: 0.025,
            justifyContent: 'center',
            alignItems: 'center',
            contentDirection: 'column',
        });

        container.userData.type = 'ui-board';
        container.userData.id = 'ui-board';

        this.intersectionContainer.addObjectToIntersect(container, true);
        this.setDefaultState(container);

        //main container header with close btn
        const containerHeader = new Block({
            width: 4.2,
            height: 0.3,
            backgroundOpacity: 0,
            justifyContent: 'center',
            alignItems: 'start',
            contentDirection: 'row',
            offset: 0,
        });
        const containerHeaderButtonBack = new Block({
            width: 0.2,
            height: 0.2,
            backgroundOpacity: 0,
            borderWidth: borderWidth,
            borderColor: borderColor,
            borderOpacity: borderOpacity,
            justifyContent: 'center',
            alignItems: 'center',
            offset: 0.03,
        });

        new TextureLoader().load(IconScrollLeftWhite, (texture) => {
            containerHeaderButtonBack.add(
                new Block({
                    width: 0.12,
                    height: 0.12,
                    backgroundOpacity: 1,
                    backgroundTexture: texture,
                    borderWidth: 0,
                }),
            );
        });

        this.intersectionContainer.addObjectToIntersect(
            containerHeaderButtonBack,
            false,
        );

        this.setDefaultState(containerHeaderButtonBack, () => {
            this.removeObject(this);
            this.intersectionContainer.objectsToIntersect.forEach((obj) => {
                obj.userData.clickable = false;
                setTimeout(() => {
                    obj.userData.clickable = true;
                }, 100);
            });
        });

        // widok przy guziku cofnij
        const containerCourseviewTitle = new Block({
            // width: 7.4,
            width: 4.0,
            height: 0.2,
            contentDirection: 'row',
            backgroundOpacity: 0,
            borderOpacity: 0,
        }).add(
            new Block({
                width: 0.2,
                height: 0.2,
                backgroundOpacity: 0,
                borderOpacity: 0,
            }),
            new Block({
                width: 3.8,
                height: 0.2,
                backgroundOpacity: 0,
                borderOpacity: 0,
                fontColor: new Color(0xffffff),
                textAlign: 'left',
                justifyContent: 'center',
                // fontSize: 0.08,
                fontSize: 0.1,
                fontFamily: FontJSON,
                fontTexture: FontImage,
            }).add(
                new Text({
                    content: Utils.stripNonAplhaNumericAndHtml(
                        // @ts-ignore
                        this.collection.title,
                    ),
                }),
            ),
        );

        containerHeader.add(
            containerHeaderButtonBack,
            containerCourseviewTitle,
        );
        container.add(containerHeader);
        //--

        // wrapper for vertical scroll
        const containerWrapper = new Block({
            width: 4.2,
            // height: 2.5,
            height: 2.3,
            contentDirection: 'row',
            alignItems: 'start',
            hiddenOverflow: true,
            backgroundOpacity: 0,
            borderRadius: 0,
            borderWidth: 0,
            borderOpacity: 0,
            offset: 0,
        });
        container.add(containerWrapper);

        // containerWrapper.userData.type = 'ui-board-row-wrapper';
        // containerWrapper.userData.parentBoardId = 'ui-board';

        const lessonMainContent = new Block({
            width: 4,
            height: 2.3,
            backgroundOpacity: 0,
            borderWidth: 0,
            contentDirection: 'column', //**
        });

        const verticalScrollWrapper = new Block({
            width: 0.2,
            height: 2.3,
            contentDirection: 'column',
            // alignItems: 'right',
            alignItems: 'right',
            backgroundOpacity: 0,
            borderWidth: 0,
        });

        containerWrapper.add(lessonMainContent, verticalScrollWrapper);

        // btns for verticalScrollWrapper
        const btnScrollOptions = {
            width: 0.1,
            height: 0.1,
            offset: offsetActiveObj,
            backgroundOpacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 0,
            borderWidth: borderWidth,
            borderColor: borderColor,
            borderOpacity: borderOpacity,
        };

        const btnScrollUp = new Block(btnScrollOptions);
        const btnScrollDown = new Block(btnScrollOptions);

        // IconScrollUpWhite
        new TextureLoader().load(IconScrollUpWhite, (texture) => {
            btnScrollUp.add(
                new Block({
                    width: 0.08,
                    height: 0.08,
                    backgroundTexture: texture,
                    borderWidth: 0,
                }),
            );
        });
        // IconScrollDownWhite
        new TextureLoader().load(IconScrollDownWhite, (texture) => {
            btnScrollDown.add(
                new Block({
                    width: 0.08,
                    height: 0.08,
                    backgroundTexture: texture,
                    borderWidth: 0,
                }),
            );
        });

        const panelScrollSpace = new Block({
            width: 0.2,
            height: 2.05,
            backgroundOpacity: 0,
            borderWidth: 0,
            borderOpacity: 0,
        });

        verticalScrollWrapper.add(btnScrollUp, panelScrollSpace, btnScrollDown);

        this.setDefaultState(btnScrollUp, () => {
            //@ts-ignore
            lessonMainContent.position.y <= -lessonMainContent.size.y ||
            lessonMainContent.position.y <= 0
                ? (lessonMainContent.position.y -= 0)
                : (lessonMainContent.position.y -= 0.1);
        });
        this.setDefaultState(btnScrollDown, () => {
            // @ts-ignore
            let _maxScroll = 0;
            // @ts-ignore
            for (let i = 0; i < lessonMainContent.childrenBoxes.length; i++) {
                // @ts-ignore
                _maxScroll += lessonMainContent.childrenBoxes[i].height;
            }

            lessonMainContent.position.y < _maxScroll - 0.4
                ? (lessonMainContent.position.y += 0.1)
                : (lessonMainContent.position.y += 0);
        });

        this.intersectionContainer.addObjectToIntersect(
            btnScrollUp,
            false,
            true,
        );
        this.intersectionContainer.addObjectToIntersect(
            btnScrollDown,
            false,
            true,
        );

        //##########################################
        //content for lesson
        // CHAPTER TITLE
        chaptersWithoutParent.forEach((chapter) => {
            const chapterBlockTitleWrapper = new Block({
                width: 4,
                height: 0.3,
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.3,
                borderWidth: borderWidth,
                borderColor: borderColor,
                borderOpacity: borderOpacity,
                contentDirection: 'row',
            }).add(
                new Block({
                    width: 0.2,
                    height: 0.3,
                    borderWidth: 0,
                    borderOpacity: 0,
                    backgroundOpacity: 0,
                }),
                new Block({
                    // width: 7.4,
                    width: 4,
                    height: 0.3,
                    borderWidth: 0,
                    borderOpacity: 0,
                    backgroundOpacity: 0,
                    textAlign: 'left',
                    justifyContent: 'center',
                    // fontSize: 0.08,
                    fontSize: 0.1,
                    fontFamily: FontJSON,
                    fontTexture: FontImage,
                }).add(
                    new Text({
                        content: Utils.stripNonAplhaNumericAndHtml(
                            chapter.title,
                        ),
                    }),
                ),
            );

            this.intersectionContainer.addObjectToIntersect(
                chapterBlockTitleWrapper,
                true,
            );

            const chapterTitleSpace = new Block({
                width: 4,
                height: 0.03,
                borderWidth: 0,
                borderOpacity: 0,
                backgroundOpacity: 0,
            });

            lessonMainContent.add(chapterBlockTitleWrapper, chapterTitleSpace);

            //-
            if (chapter.hasOwnProperty('children')) {
                chapter.children.forEach((child) => {
                    const resources = collection.data.resources?.filter(
                        (resource) => resource.parent_chapter === child.id,
                    );

                    //##########################################
                    //CHAPTER SUBTITLE
                    const chapterSubtitle = new Block({
                        width: 4,
                        height: 0.3,
                        fontSize: 0.08,
                        backgroundColor: new Color(0xffffff),
                        backgroundOpacity: 0.15,
                        borderWidth: borderWidth,
                        borderColor: borderColor,
                        borderOpacity: borderOpacity,
                        contentDirection: 'row',
                    }).add(
                        new Block({
                            width: 0.2,
                            height: 0.3,
                            borderWidth: 0,
                            borderOpacity: 0,
                            backgroundOpacity: 0,
                        }),
                        new Block({
                            // width: 7.4,
                            width: 4,
                            height: 0.3,
                            borderWidth: 0,
                            borderOpacity: 0,
                            backgroundOpacity: 0,
                            textAlign: 'left',
                            justifyContent: 'center',
                            fontSize: 0.09,
                            fontFamily: FontJSON,
                            fontTexture: FontImage,
                        }).add(
                            new Text({
                                content: Utils.stripNonAplhaNumericAndHtml(
                                    child.title,
                                ),
                            }),
                        ),
                    );

                    this.intersectionContainer.addObjectToIntersect(
                        chapterSubtitle,
                        true,
                    );

                    const chapterSubtitleSpace = new Block({
                        width: 4,
                        height: 0.03,
                        borderWidth: 0,
                        borderOpacity: 0,
                        backgroundOpacity: 0,
                    });

                    lessonMainContent.add(
                        chapterSubtitle,
                        chapterSubtitleSpace,
                    );
                    //--

                    //poziomy 'div' w którym są horyzostalnie kursy + strzałki do scrola
                    const chapterBlockWrapper = new Block({
                        width: 4,
                        height: 1.2,
                        backgroundOpacity: 0,
                        contentDirection: 'column',
                        justifyContent: 'center',
                        hiddenOverflow: true,
                    });

                    this.intersectionContainer.addObjectToIntersect(
                        chapterBlockWrapper,
                        false,
                    );
                    this.setDefaultState(chapterBlockWrapper);
                    // chapterBlockWrapper.userData.type = 'ui-board-row-wrapper';
                    // chapterBlockWrapper.userData.parentBoardId = 'ui-board';

                    const chapterResourcesSorted = resources.sort((a, b) =>
                        a.title
                            .replace(/\d+/g, (n) => +n + 100000)
                            .localeCompare(
                                b.title.replace(/\d+/g, (n) => +n + 100000),
                            ),
                    );

                    //ONE LESSON BOX (clickable)
                    // resourceBlock - blok z jedną lekcją

                    chapterResourcesSorted.forEach((resource) => {
                        const resourceBlock = new Block({
                            // width: 7.6,
                            width: 4,
                            height: 0.3,
                            contentDirection: 'row',
                            borderRadius: 0,
                            backgroundOpacity: 0.001,
                            borderWidth: borderWidth,
                            borderColor: borderColor,
                            borderOpacity: borderOpacity,
                        });

                        // resourceBlock.userData.type = 'ui-board-active-item';
                        // resourceBlock.userData.parentBoardId = 'ui-board';

                        //course info: name, icon
                        const resourceBlockInfoWrapper = new Block({
                            // width: 6.6, //
                            width: 3,
                            height: 0.3,
                            backgroundOpacity: 0,
                            borderWidth: 0,
                            contentDirection: 'row',
                        });

                        resourceBlockInfoWrapper.add(
                            //course name:
                            new Block({
                                // width: 7.4,
                                width: 4,
                                height: 0.3,
                                textAlign: 'left',
                                justifyContent: 'center',
                                backgroundOpacity: 0,
                                borderWidth: 0,
                                fontSize: 0.08,
                                borderOpacity: borderOpacity,
                                fontFamily: FontJSON,
                                fontTexture: FontImage,
                            }).add(
                                new Text({
                                    content: Utils.stripNonAplhaNumericAndHtml(
                                        resource.title,
                                    ),
                                }),
                            ),
                        );

                        const resourceBlockInfoIcon = new Block({
                            width: 0.6,
                            height: 0.3,
                            backgroundOpacity: 0,
                            borderWidth: 0,
                            justifyContent: 'center',
                        });

                        //todo: icon url from api
                        new TextureLoader().load(IconCourseCover, (texture) => {
                            texture.wrapS = RepeatWrapping;
                            texture.wrapT = RepeatWrapping;

                            resourceBlockInfoIcon.add(
                                new Block({
                                    width: 0.16,
                                    height: 0.16,
                                    backgroundTexture: texture,
                                    borderWidth: 0,
                                    backgroundSize: 'contain',
                                }),
                            );
                        });

                        // box z ikona i nazwą lekcji
                        resourceBlock.add(
                            resourceBlockInfoIcon,
                            resourceBlockInfoWrapper,
                        );

                        this.intersectionContainer.addObjectToIntersect(
                            resourceBlock,
                            false,
                            true,
                        );
                        this.setDefaultState(resourceBlock);

                        //@ts-ignore
                        resourceBlock.setupState({
                            state: 'selected',

                            onSet: async () => {
                                const token =
                                    await this.httpClient.getDeeplinkRefreshToken();

                                const deeplink = `${envierments.baseURL}/deeplinks/vr/resolve/course/${collection.data.id}/lesson/${resource.resource_id}?refresh_token=${token.data.refresh_token}`;

                                const iframe: HTMLIFrameElement =
                                    document.querySelector(
                                        '.iframe-wrapper iframe',
                                    );
                                iframe.setAttribute('src', deeplink);

                                const iframeWrapper =
                                    document.querySelector('.iframe-wrapper');
                                iframeWrapper.classList.add('active');

                                if (
                                    this.renderer.webGLRenderer.xr.isPresenting
                                ) {
                                    this.renderer.webGLRenderer.xr
                                        .getSession()
                                        .end();
                                }
                            },
                        });

                        const space = new Block({
                            // width: 7.6,
                            width: 4,
                            height: 0.03,
                            borderWidth: 0,
                            borderOpacity: 0,
                            backgroundOpacity: 0,
                        });

                        lessonMainContent.add(resourceBlock, space);
                    });
                });
            } else {
                const resources = collection.data.resources?.filter(
                    (resource) => resource.parent_chapter === chapter.id,
                );

                //##########################################
                //CHAPTER SUBTITLE
                const chapterSubtitle = new Block({
                    width: 4,
                    height: 0.3,
                    fontSize: 0.08,
                    backgroundColor: new Color(0xffffff),
                    backgroundOpacity: 0.15,
                    borderWidth: borderWidth,
                    borderColor: borderColor,
                    borderOpacity: borderOpacity,
                    contentDirection: 'row',
                }).add(
                    new Block({
                        width: 0.2,
                        height: 0.3,
                        borderWidth: 0,
                        borderOpacity: 0,
                        backgroundOpacity: 0,
                    }),
                    new Block({
                        width: 4,
                        height: 0.3,
                        borderWidth: 0,
                        borderOpacity: 0,
                        backgroundOpacity: 0,
                        textAlign: 'left',
                        justifyContent: 'center',
                        fontSize: 0.08,
                        fontFamily: FontJSON,
                        fontTexture: FontImage,
                    }).add(
                        new Text({
                            content: Utils.stripNonAplhaNumericAndHtml(
                                chapter.title,
                            ),
                        }),
                    ),
                );

                this.intersectionContainer.addObjectToIntersect(
                    chapterSubtitle,
                    true,
                );

                const chapterSubtitleSpace = new Block({
                    width: 4,
                    height: 0.03,
                    borderWidth: 0,
                    borderOpacity: 0,
                    backgroundOpacity: 0,
                });

                lessonMainContent.add(chapterSubtitle, chapterSubtitleSpace);
                //--

                //poziomy 'div' w którym są horyzostalnie kursy + strzałki do scrola
                const chapterBlockWrapper = new Block({
                    width: 4,
                    height: 1.2,
                    backgroundOpacity: 0,
                    contentDirection: 'column',
                    justifyContent: 'center',
                    hiddenOverflow: true,
                });

                this.intersectionContainer.addObjectToIntersect(
                    chapterBlockWrapper,
                    true,
                );
                this.setDefaultState(chapterBlockWrapper);
                // chapterBlockWrapper.userData.type = 'ui-board-row-wrapper';
                // chapterBlockWrapper.userData.parentBoardId = 'ui-board';

                const chapterResourcesSorted = resources.sort((a, b) =>
                    a.title
                        .replace(/\d+/g, (n) => +n + 100000)
                        .localeCompare(
                            b.title.replace(/\d+/g, (n) => +n + 100000),
                        ),
                );

                //ONE LESSON BOX (clickable)
                // resourceBlock - blok z jedną lekcją

                chapterResourcesSorted.forEach((resource) => {
                    const resourceBlock = new Block({
                        width: 4,
                        height: 0.3,
                        contentDirection: 'row',
                        borderRadius: 0,
                        backgroundOpacity: 0,
                        borderWidth: borderWidth,
                        borderColor: borderColor,
                        borderOpacity: borderOpacity,
                    });

                    // resourceBlock.userData.type = 'ui-board-active-item';
                    // resourceBlock.userData.parentBoardId = 'ui-board';

                    //course info: name, icon
                    const resourceBlockInfoWrapper = new Block({
                        // width: 6.6, //
                        width: 3,
                        height: 0.3,
                        backgroundOpacity: 0,
                        borderWidth: 0,
                        contentDirection: 'row',
                    });

                    resourceBlockInfoWrapper.add(
                        //course name:
                        new Block({
                            width: 2,
                            height: 0.3,
                            textAlign: 'left',
                            justifyContent: 'center',
                            backgroundOpacity: 0,
                            borderWidth: 0,
                            fontSize: 0.08,
                            borderOpacity: borderOpacity,
                            fontFamily: FontJSON,
                            fontTexture: FontImage,
                        }).add(
                            new Text({
                                content: Utils.stripNonAplhaNumericAndHtml(
                                    resource.title,
                                ),
                            }),
                        ),
                    );

                    const resourceBlockInfoIcon = new Block({
                        width: 0.6,
                        height: 0.3,
                        backgroundOpacity: 0,
                        borderWidth: 0,
                        justifyContent: 'center',
                    });

                    //todo: icon url from api
                    new TextureLoader().load(IconCourseCover, (texture) => {
                        texture.wrapS = RepeatWrapping;
                        texture.wrapT = RepeatWrapping;

                        resourceBlockInfoIcon.add(
                            new Block({
                                width: 0.2,
                                height: 0.26,
                                backgroundTexture: texture,
                                borderWidth: 0,
                                backgroundSize: 'contain',
                            }),
                        );
                    });

                    // box z ikona i nazwą lekcji
                    resourceBlock.add(
                        resourceBlockInfoIcon,
                        resourceBlockInfoWrapper,
                    );

                    this.intersectionContainer.addObjectToIntersect(
                        resourceBlock,
                        false,
                        true,
                    );
                    this.setDefaultState(resourceBlock);

                    //@ts-ignore
                    resourceBlock.setupState({
                        state: 'selected',

                        onSet: async () => {
                            const token =
                                await this.httpClient.getDeeplinkRefreshToken();

                            const deeplink = `${envierments.baseURL}/deeplinks/vr/resolve/course/${collection.data.id}/lesson/${resource.resource_id}?refresh_token=${token.data.refresh_token}`;

                            const iframe: HTMLIFrameElement =
                                document.querySelector(
                                    '.iframe-wrapper iframe',
                                );
                            iframe.setAttribute('src', deeplink);

                            const iframeWrapper =
                                document.querySelector('.iframe-wrapper');
                            iframeWrapper.classList.add('active');

                            if (this.renderer.webGLRenderer.xr.isPresenting) {
                                this.renderer.webGLRenderer.xr
                                    .getSession()
                                    .end();
                            }
                        },
                    });

                    const space = new Block({
                        width: 4,
                        height: 0.03,
                        borderWidth: 0,
                        borderOpacity: 0,
                        backgroundOpacity: 0,
                    });

                    lessonMainContent.add(resourceBlock, space);
                });
            }
        });

        chapters.sort((a, b) =>
            a.title
                .replace(/\d+/g, (n) => +n + 100000)
                .localeCompare(b.title.replace(/\d+/g, (n) => +n + 100000)),
        );

        this.add(container);
    }
}
