import VrObject3D from '../Three/VrObject3D';
import { autoInjectable, singleton } from 'tsyringe';
import Renderer from '../Renderer';
import {
    BufferGeometry,
    Clock,
    Color,
    EventDispatcher,
    Group,
    Material,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Raycaster,
    RingGeometry,
    Sprite,
} from 'three';

import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { XRHandModelFactory } from 'three/examples/jsm/webxr/XRHandModelFactory';

import Camera from '../../Camera';
import { pointer as hoverRing } from '../Player/assets/components';
import IntersectionContainer from './IntersectionContainer';
import ColyseusClient from '../../Network/ColyseusClient';
import linesHelper, { pointer } from './ControllersUtils';
import { Block } from 'three-mesh-ui';
import Time from '../../Utils/Time';
import { MovementService } from './MovementService';

/*
 * XRController Gamepad buttons mapping
 * https://www.w3.org/TR/webxr-gamepads-module-1/
 * */
export const GAMEPAD_BUTTONS = {
    0: '0_BUTTON_PRESSED',
    1: '1_BUTTON_PRESSED',
    2: '2_BUTTON_PRESSED',
    3: '3_BUTTON_PRESSED',
    4: '4_BUTTON_PRESSED',
    5: '5_BUTTON_PRESSED',
};

export const GAMEPAD_BUTTONS_ENB = {
    0: '0_BUTTON_END',
    1: '1_BUTTON_END',
    2: '2_BUTTON_END',
    3: '3_BUTTON_END',
    4: '4_BUTTON_END',
    5: '5_BUTTON_END',
};

export class VrGamepad extends EventDispatcher {
    private readonly gamepad: any;

    private previousButtonStates = [];

    public static BUTTON_END_B = '5_BUTTON_END';
    public static BUTTON_END_A = '4_BUTTON_END';

    public static BUTTON_END_Y = '5_BUTTON_END';
    public static BUTTON_END_X = '4_BUTTON_END';

    public constructor(gamepad: any) {
        super();

        this.gamepad = gamepad;
    }

    public pulse(): void {
        if (this.gamepad === null) {
            return;
        }

        //@ts-ignore
        if (this.gamepad.hapticActuators && this.gamepad.hapticActuators[0]) {
            //@ts-ignore
            this.gamepad.hapticActuators[0].pulse(0.8, 100);
        }
    }

    public update(): void {
        if (this.gamepad === null) {
            return;
        }

        this.updateButtons();
        this.updateAxes();
    }

    public updateAxes(): void {
        if (
            this.gamepad.axes[2] > 0 ||
            this.gamepad.axes[2] < 0 ||
            this.gamepad.axes[3] > 0 ||
            this.gamepad.axes[3] < 0
        ) {
            this.dispatchEvent({
                type: 'axes',
                x: this.gamepad.axes[2],
                y: this.gamepad.axes[3],
            });
        }
    }

    public updateButtons(): void {
        this.gamepad.buttons.forEach((button, index) => {
            const buttonState = button.pressed;

            if (button.pressed === true) {
                if (button.value === 1) {
                    this.dispatchEvent({
                        type: `${index}_BUTTON_PRESSED`,
                        button: button,
                    });
                }
            }

            if (this.previousButtonStates[index] && !buttonState) {
                this.dispatchEvent({
                    type: `${index}_BUTTON_END`,
                    button: button,
                });
            }

            if (!this.previousButtonStates[index] && buttonState) {
                this.dispatchEvent({
                    type: `${index}_BUTTON_START`,
                    button: button,
                });
            }

            this.previousButtonStates[index] = buttonState;
        });
    }
}

@singleton()
@autoInjectable()
export default class XrControllers extends VrObject3D {
    public controllerLeft: Group;
    public controllerRight: Group;

    public controllerGripLeft: Group;
    public controllerGripRight: Group;

    public raycaster: Raycaster = new Raycaster();
    public isSelecting: boolean = false;
    public marker = hoverRing();
    public pointer: Sprite = pointer;
    public intersection: any;
    private tempMatrix: Matrix4 = new Matrix4();
    public controllerType: 'tracked-pointer' | 'gaze' | null = null;

    public hands = [];

    public line: Mesh;
    private triggered: boolean = false;
    public gazeRing: GazeRing = new GazeRing();

    public previousIntersected: Object3D | Block | VrObject3D;

    public intersectionClock: Clock = new Clock();
    //---
    public pointedObjectCanvasBoard;
    public pointedObject;

    public constructor(
        public renderer?: Renderer,
        public camera?: Camera,
        public intersectionContainer?: IntersectionContainer,
        public colyseusClient?: ColyseusClient,
        private movementService?: MovementService,
    ) {
        super();
        this.marker.visible = false;
        this.pointer.visible = false;
        this.setLine();

        this.raycaster.firstHitOnly = true;

        this.intersectionContainer.xrControllers = this;

        this.setupControllers();

        const controllerModelFactory = new XRControllerModelFactory();

        this.controllerLeft.addEventListener('connected', (event) => {
            this.controllerLeft.userData.gamepad = new VrGamepad(
                event.data.gamepad,
            );

            this.addGamepadButtonReleaseListeners(this.controllerLeft);

            this.controllerGripLeft.add(
                controllerModelFactory.createControllerModel(
                    this.controllerGripLeft,
                ),
            );
        });

        this.controllerRight.addEventListener('connected', (event) => {
            this.controllerType = event.data.targetRayMode;

            this.controllerRight.add(this.buildController(event.data));

            this.controllerGripRight.add(
                controllerModelFactory.createControllerModel(
                    this.controllerGripRight,
                ),
            );
        });
    }

    private addGamepadButtonReleaseListeners(controller: any) {
        for (const button in GAMEPAD_BUTTONS_ENB) {
            controller.userData.gamepad.addEventListener(
                `${button}_BUTTON_END`,
                (data) => {
                    controller.dispatchEvent({
                        type: `${button}_BUTTON_END`,
                        data,
                    });
                },
            );
        }
    }

    private setupControllers() {
        if (this.movementService.initialSettings.leftHanded) {
            this.controllerLeft =
                this.renderer.webGLRenderer.xr.getController(1);
            this.controllerRight =
                this.renderer.webGLRenderer.xr.getController(0);
            this.controllerGripLeft =
                this.renderer.webGLRenderer.xr.getControllerGrip(1);
            this.controllerGripRight =
                this.renderer.webGLRenderer.xr.getControllerGrip(0);
        } else {
            this.controllerLeft =
                this.renderer.webGLRenderer.xr.getController(0);
            this.controllerRight =
                this.renderer.webGLRenderer.xr.getController(1);
            this.controllerGripLeft =
                this.renderer.webGLRenderer.xr.getControllerGrip(0);
            this.controllerGripRight =
                this.renderer.webGLRenderer.xr.getControllerGrip(1);
        }
    }

    public setLine(): void {
        this.line = linesHelper;
        this.line.name = 'line';
        this.line.visible = true;
        this.line.renderOrder = Infinity;
        this.line.position.y = -0.01;
    }

    public hapticResponse(controller: any) {
        if (
            controller.userData.gamepad === null ||
            this.controllerRight.userData.gamepad === undefined
        ) {
            return;
        }
        if (controller.userData.gamepad === undefined) {
            return;
        }

        controller.userData.gamepad.pulse();
    }

    public createHands() {
        const handModelFactory = new XRHandModelFactory();

        this.hands.push(this.renderer.webGLRenderer.xr.getHand(0));
        this.hands[0].add(
            //@ts-ignore
            handModelFactory.createHandModel(this.hands[0], 'mesh'),
        );

        this.hands.push(this.renderer.webGLRenderer.xr.getHand(1));
        this.hands[1].add(
            //@ts-ignore
            handModelFactory.createHandModel(this.hands[1], 'mesh'),
        );
    }
    public init() {
        this.createHands();

        this.controllerRight.addEventListener('connected', (event) => {
            this.controllerRight.userData.gamepad = new VrGamepad(
                event.data.gamepad,
            );
        });
        // Event listener for controller to detect when axes.x is -1 or 1

        this.controllerRight.addEventListener('connected', (event) => {
            //@ts-ignore
            this.controllerRight.gamepad = event.data.gamepad;

            this.controllerRight.userData.gamepad = new VrGamepad(
                event.data.gamepad,
            );

            this.controllerRight.userData.gamepad.addEventListener(
                'axes',
                (event) => {
                    // this.rotateEvent(event);
                    this.movementService.onRotateCamera(event, (direction) => {
                        if (direction === MovementService.LEFT) {
                            this.dispatchEvent({
                                type: 'rotate',
                                direction: MovementService.LEFT,
                            });
                        } else {
                            this.dispatchEvent({
                                type: 'rotate',
                                direction: MovementService.RIGHT,
                            });
                        }
                    });
                    this.movementService.onMoveForwardOrBackwards(
                        event,
                        (direction) => {
                            if (direction === MovementService.FORWARD) {
                                this.dispatchEvent({
                                    type: MovementService.FORWARD,
                                });
                            } else {
                                this.dispatchEvent({
                                    type: MovementService.BACKWARD,
                                });
                            }
                        },
                    );
                },
            );
        });

        this.controllerGripRight.addEventListener('selectstart', (e) =>
            this.onSelectStart(e),
        );
        this.controllerGripRight.addEventListener('selectend', (e) =>
            this.onSelectEnd(e),
        );
    }
    public xrSessionStart(event) {
        this.pointer.visible = true;
        this.camera?.cameraGroup.add(this.controllerGripLeft);
        this.camera?.cameraGroup.add(this.controllerGripRight);
        this.camera?.cameraGroup.add(this.controllerLeft);
        this.camera?.cameraGroup.add(this.controllerRight);
        this.camera?.cameraGroup.add(this.hands[0]);
        this.camera?.cameraGroup.add(this.hands[1]);
    }

    public buttonPressed(event) {}

    public xrSessionEnd(_event: any): void {
        this.marker.visible = false;
        this.pointer.visible = false;

        this.camera.cameraGroup.remove(this.controllerGripLeft);
        this.camera.cameraGroup.remove(this.controllerGripRight);
    }

    public buildController(data) {
        switch (data.targetRayMode) {
            case 'tracked-pointer':
                this.marker.visible = true;
                return this.line;
            case 'gaze':
                this.marker.visible = false;
                return this.gazeRing;
        }
    }

    public onSelectStart(event) {
        this.isSelecting = true;

        this.line.visible = true;

        //@ts-ignore
        this.line.material.color = new Color('blue');
    }

    public setLineBlue() {
        this.line.visible = true;

        //@ts-ignore
        this.line.material.color = new Color('blue');
    }

    public setDefaultLineColor() {
        this.line.visible = true;

        //@ts-ignore
        this.line.material.color = new Color('white');
    }

    public onSelectEnd(event) {
        this.isSelecting = false;

        //@ts-ignore
        this.line.material.color = new Color('white');

        if (
            this.intersection &&
            this.intersection.object !== null &&
            this.intersection.object.name !== 'MeshUI-Frame' &&
            this.intersection.object.name !== 'canvasBoard'
        ) {
            this.marker.visible = false;
            this.dispatchEvent({
                type: 'selectWithIntersection',
                intersection: this.intersection,
            });
        }
    }

    public update() {
        if (this.controllerLeft.userData.gamepad !== undefined) {
            this.controllerLeft.userData.gamepad.update();
        }

        if (this.controllerRight.userData.gamepad !== undefined) {
            this.controllerRight.userData.gamepad.update();
        }
    }

    public setFromController(controller) {
        this.tempMatrix.identity().extractRotation(controller.matrixWorld);
        this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
        this.raycaster.ray.direction
            .set(0, 0, -1)
            .applyMatrix4(this.tempMatrix);
    }

    public getIntersections() {
        this.intersection = null;
        this.setFromController(this.controllerRight);

        const intersects = this.raycast(
            this.intersectionContainer.objectsToIntersect,
        );

        if (intersects !== null) {
            this.onIntersect(intersects.object, intersects);
            const object = intersects.object;

            if (this.previousIntersected !== object) {
                if (this.previousIntersected) {
                    this.previousIntersected.userData.isIntersected = false;
                    this.onIntersectOut(this.previousIntersected, intersects);
                }

                this.previousIntersected = object;
                object.userData.isIntersected = true;
                this.onIntersectEnter(object, intersects);
            }
        } else {
            if (this.previousIntersected) {
                this.previousIntersected.userData.isIntersected = false;
                this.onIntersectOut(this.previousIntersected, intersects);
                this.previousIntersected = null;
            }
        }
    }

    public onIntersect(object: Object3D, intersection: any): void {
        this.intersection = intersection;
        this.line.visible = true;
        if (
            //@ts-ignore
            object.isUI ||
            object.name === 'MeshUI-Frame' ||
            object.name === 'canvasBoard'
        ) {
            this.marker.visible = false;
            this.pointer.visible = true;
        } else {
            this.pointer.visible = false;
            this.marker.visible = true;
        }
        this.marker.position.copy(intersection.point);
        this.pointer.position.copy(intersection.point);

        //@ts-ignore
        this.line.material.color = new Color('blue');

        if (!this.isSelecting) {
            //@ts-ignore
            this.line.material.color = new Color('white');
        }

        if (this.controllerType === 'gaze') {
            if (this.intersectionClock.getElapsedTime() <= 5) {
                const thetaLettingTime =
                    Math.round(
                        (this.intersectionClock.getElapsedTime() / 5) * 100,
                    ) *
                    ((Math.PI * 2) / 100);

                const ringBufferGeometry = new RingGeometry(
                    0.02,
                    0.04,
                    32,
                    32,
                    0,
                    thetaLettingTime,
                ).translate(0, 0, -1);
                this.gazeRing.geometry.dispose();
                this.gazeRing.geometry = ringBufferGeometry;
                //@ts-ignore
                this.gazeRing.material.color = new Color('Blue');
            }

            if (this.intersectionClock.getElapsedTime() >= 5) {
                this.dispatchEvent({
                    type: 'selectWithIntersection',
                    intersection: this.intersection,
                });
                //@ts-ignore
                this.gazeRing.material.color = new Color('white');
                this.gazeRing.geometry = new RingGeometry(
                    0.02,
                    0.04,
                    32,
                ).translate(0, 0, -1);
            }
        }
    }

    public onIntersectEnter(object: Object3D, intersection: any): void {
        //@ts-ignore
        if (object.isUI) {
            if (object.userData.hasOwnProperty('clickable')) {
                if (!object.userData.clickable) {
                    return;
                }
            }

            if (object.userData.hasOwnProperty('intersectable')) {
                if (!intersection.object.userData.intersectable) {
                    return;
                }
            }

            //@ts-ignore
            object.setState('hovered');
            this.hapticResponse(this.controllerRight);
        }

        if (this.controllerType === 'gaze') {
            this.intersectionClock.start();
        }
    }

    public onIntersectOut<T extends Object3D>(
        object: T,
        intersection: any,
    ): void {
        //@ts-ignore
        this.gazeRing.material.color = new Color('white');
        this.gazeRing.setDefault();
        this.marker.visible = false;
        this.line.visible = false;
        this.pointer.visible = false;

        if (this.controllerType === 'gaze') {
            this.intersectionClock = new Clock();
        }

        //@ts-ignore
        if (object.isUI) {
            //@ts-ignore
            object.setState('idle');
        }
    }

    public async xrUpdate() {
        Time.limitFrames(async () => {
            if (this.colyseusClient.isMultiplayer && this.colyseusClient.room) {
                if (
                    this.controllerType === 'tracked-pointer' &&
                    this.colyseusClient.room
                ) {
                    await this.colyseusClient.updateController(
                        this.controllerGripLeft.position,
                    );
                    await this.colyseusClient.updateControllerRotation(
                        this.controllerGripLeft.quaternion,
                    );

                    await this.colyseusClient.updateRightController(
                        this.controllerGripRight.position,
                    );
                    await this.colyseusClient.updateControllerSecondRotation(
                        this.controllerGripRight.quaternion,
                    );
                }
            }
        }, 24);

        this.getIntersections();
    }

    public raycast(objects) {
        let pointedObject = objects.reduce((closestIntersection, obj) => {
            this.intersection = this.raycaster.intersectObject(obj, true);

            if (!this.intersection[0]) return closestIntersection;

            if (
                !closestIntersection ||
                this.intersection[0].distance < closestIntersection.distance
            ) {
                this.intersection[0].object = obj;

                return this.intersection[0];
            }
            return closestIntersection;
        }, null);

        pointedObject = this.checkPointedObjectType(pointedObject);

        this.pointedObject = pointedObject;

        return pointedObject;
    }

    public checkPointedObjectType(pointedObject) {
        //overflow hidden on parent
        if (
            pointedObject !== null &&
            pointedObject.object.userData.type === 'ui-board-active-item'
        ) {
            pointedObject = this.disableInteractivity(pointedObject);
        }

        //sketchBoard
        if (
            pointedObject !== null &&
            pointedObject.object.name === 'canvasBoard'
        ) {
            this.pointedObjectCanvasBoard = pointedObject;
            // console.log(this.pointedObjectCanvasBoard);
            this.dispatchEvent({
                type: 'currentIntersectionPoint',
                data: {
                    pointedObject: this.pointedObjectCanvasBoard,
                },
            });
        }

        return pointedObject;
    }

    public disableInteractivity(pointedObject) {
        let parentBoardId = pointedObject.object.userData.parentBoardId;
        let isOnRow = false;
        let isOnBoard = false;

        let allIntersectedObjects = this.getAllIntersectObjects();

        //if horizontal scroll
        for (let i = 0; i < allIntersectedObjects.length; i++) {
            let intersectedObject = allIntersectedObjects[i];

            if (
                intersectedObject.object.parent.userData.type ===
                    'ui-board-row-wrapper' &&
                intersectedObject.object.parent.userData.parentBoardId ===
                    parentBoardId
            ) {
                isOnRow = true;
                break;
            }
        }

        //if vertical scroll
        for (let i = 0; i < allIntersectedObjects.length; i++) {
            let intersectedObject = allIntersectedObjects[i];

            if (
                intersectedObject.object.parent.userData.type === 'ui-board' &&
                intersectedObject.object.parent.userData.id === parentBoardId
            ) {
                isOnBoard = true;
                break;
            }
        }

        if (!isOnRow || !isOnBoard) {
            pointedObject = null;
        }

        return pointedObject;
    }

    public getAllIntersectObjects() {
        return this.raycaster.intersectObjects(
            this.intersectionContainer.objectsToIntersect,
            true,
        );
    }
}

export class GazeRing extends Mesh {
    public material: Material | Material[] = new MeshBasicMaterial({
        opacity: 0.5,
        transparent: true,
    });

    public geometry: BufferGeometry = new RingGeometry(
        0.02,
        0.04,
        32,
    ).translate(0, 0, -1);

    public constructor() {
        super();
    }

    public setDefault() {
        this.geometry = new RingGeometry(0.02, 0.04, 32).translate(0, 0, -1);
    }
}
