import { useState } from 'react';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import { container } from 'tsyringe';
import { User } from './user';
import { MdClose, MdPersonSearch } from 'react-icons/md';
import TranslationService from '../../Translations/TranslationService';

interface User {
    first_name: string;
    id: number;
    last_name: string;
    photo_thumbnail_url: string;
    photo_url: string;
    profile_type: number;
    school_classes: any[];
    username: string;
}

export function FriendsSendInvitePopup({ handleClose, handleUnclick }) {
    const [usersFounded, setUserFounded] = useState([]);

    const [searchInput, setSearchInput] = useState('');

    const [isSearching, setIsSearching] = useState(false);

    const httpClient = container.resolve(AxiosHttpClient);

    const translationService = container.resolve(TranslationService);
    const handleSearchInput = async (event: any) => {
        const inputValue = event.target.value;
        setSearchInput(inputValue);

        if (inputValue.length > 2) {
            setIsSearching(true);

            const users = await httpClient.findUsersByUsername(inputValue); // Używamy inputValue zamiast searchInput
            if (users.data.length > 0) {
                setUserFounded(users.data);
                setIsSearching(false);
            }
        }
    };

    const onChangeColor = (color) => {};

    return (
        <div className="popup-wrapper-friends">
            <div className="popup">
                <div className="popup-content">
                    <div className="users-search-navbar d-flex flex-row justify-content-between pb-2">
                        <input
                            data-bs-theme="dark"
                            id="findFriendInput"
                            className="form-control form-control-md"
                            type="text"
                            placeholder={TranslationService.translate(
                                'vr.friends_popup_find_users_to_invite',
                            )}
                            autoFocus
                            onInput={(e) => handleSearchInput(e)}
                        ></input>
                        <button
                            onClick={() => {
                                handleClose(), handleUnclick(5);
                            }}
                            className="close-button m-1"
                        >
                            <MdClose size="2em" />
                        </button>
                    </div>

                    <div className="founded-users">
                        {usersFounded.length === 0 ? (
                            <p className="text-center mt-5">
                                <span>
                                    <MdPersonSearch size="3em" />
                                    <br />
                                </span>
                                {TranslationService.translate(
                                    'vr.friends_popup_at_least_4',
                                )}
                            </p>
                        ) : (
                            ''
                        )}

                        {isSearching === true && (
                            <div className="m-5 w-100 d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        )}

                        {usersFounded.map((user, index) => {
                            return (
                                <User
                                    key={index}
                                    id={user.id}
                                    username={user.username}
                                    firstName={user.first_name}
                                    lastName={user.last_name}
                                    photoUrl={user.photo_url}
                                ></User>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
