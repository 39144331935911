import { Object3D } from 'three';
import { autoInjectable } from 'tsyringe';
import Resources from '../../Resources';
import Time from '../../Utils/Time';
import Renderer from '../Renderer';

@autoInjectable()
export default class VrObject3D extends Object3D {
    public vrUpdateLoop: any;

    public debug: boolean = true;

    public updateEvent: any;

    public constructor(
        public resources?: Resources,
        public time?: Time,
        public renderer?: Renderer,
    ) {
        super();

        this.updateEvent = this.time.on('tick', () => this.update(this.time));

        this.renderer.webGLRenderer.xr.addEventListener(
            'sessionstart',
            (event) => {
                this.xrSessionStart(event);
                this.vrUpdateLoop = this.time.on('tick', () =>
                    this.xrUpdate(this.time),
                );
            },
        );

        this.renderer.webGLRenderer.xr.addEventListener(
            'sessionend',
            (event) => {
                if (this.vrUpdateLoop) {
                    this.time.remove(this.vrUpdateLoop);
                }

                this.xrSessionEnd(event);
            },
        );
    }

    public update(_time?: Time): void {}

    public updateChilder(_time: Time): void {}

    public destroy() {
        this.time.remove(this.updateEvent);
    }
    public xrUpdate(_time?: Time): void {}
    public xrSessionStart(_event): void {}
    public xrSessionEnd(_event): void {}
}
