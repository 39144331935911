import VrObject3D from './Three/VrObject3D';
import FontJSON from '@assets/fonts/Roboto-Regular-msdf.json';
import FontImage from '@assets/fonts/Roboto-Regular.png';
import { Block, Text } from 'three-mesh-ui';
import { Color, sRGBEncoding, TextureLoader, VideoTexture } from 'three';
import IntersectionContainer from './Controllers/IntersectionContainer';
import { autoInjectable } from 'tsyringe';
import ColyseusClient from '../Network/ColyseusClient';
import VrScene from './Three/VrScene';
import Resources from '../Resources';
import envierments from '../../Environments/envierments';
import skybox2 from '@models/rooms/skyboxes/Private_Space.jpeg';
import skybox3 from '@models/rooms/skyboxes/1_opt.jpg';
import axios from 'axios';
import { Texture } from 'three/src/textures/Texture';
@autoInjectable()
export default class SkyboxChanger extends VrObject3D {
    public constructor(
        public scene: VrScene,
        public intersectionContainer?: IntersectionContainer,
        public colyseusClient?: ColyseusClient,
        public resources?: Resources,
    ) {
        super();
        // this.makePanel();
    }

    public makePanel() {
        const container: Block = new Block({
            width: 2,
            height: 0.3,
            justifyContent: 'center',
            contentDirection: 'row-reverse',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontSize: 0.07,
            padding: 0.02,
            borderRadius: 0.11,
            backgroundOpacity: 0.6,
        });

        this.add(container);

        const buttonOptions = {
            width: 0.4,
            height: 0.15,
            justifyContent: 'center',
            offset: 0.05,
            margin: 0.02,
            borderRadius: 0.075,
        };

        // Options for component.setupState().
        // It must contain a 'state' parameter, which you will refer to with component.setState( 'name-of-the-state' ).

        const hoveredStateAttributes = {
            state: 'hovered',
            attributes: {
                offset: 0.035,
                backgroundColor: new Color(0x999999),
                backgroundOpacity: 1,
                fontColor: new Color(0xffffff),
            },
        };

        const idleStateAttributes = {
            state: 'idle',
            attributes: {
                offset: 0.035,
                backgroundColor: new Color(0x666666),
                backgroundOpacity: 0.3,
                fontColor: new Color(0xffffff),
            },
        };

        // Buttons creation, with the options objects passed in parameters.

        const skybox1 = new Block(buttonOptions);
        const skybox2 = new Block(buttonOptions);
        const skybox3 = new Block(buttonOptions);

        skybox1.add(new Text({ content: 'Cosmos' }));

        skybox2.add(new Text({ content: 'Space station' }));

        skybox3.add(new Text({ content: 'Tesseract' }));

        const selectedAttributes = {
            offset: 0.02,
            backgroundColor: new Color(0x777777),
            fontColor: new Color(0x222222),
        };

        //@ts-ignore
        skybox1.setupState(hoveredStateAttributes);
        //@ts-ignore
        skybox1.setupState(idleStateAttributes);

        //@ts-ignore
        skybox1.setupState({
            state: 'selected',
            attributes: selectedAttributes,
            onSet: () => {
                this.colyseusClient.changeSkybox('skybox1');
            },
        });

        //@ts-ignore
        skybox2.setupState(hoveredStateAttributes);
        //@ts-ignore
        skybox2.setupState(idleStateAttributes);

        //@ts-ignore
        skybox2.setupState({
            state: 'selected',
            attributes: selectedAttributes,
            onSet: () => {
                this.colyseusClient.changeSkybox('skybox2');
            },
        });

        //@ts-ignore
        skybox3.setupState(hoveredStateAttributes);
        //@ts-ignore
        skybox3.setupState(idleStateAttributes);

        //@ts-ignore
        skybox3.setupState({
            state: 'selected',
            attributes: selectedAttributes,
            onSet: () => {
                this.colyseusClient.changeSkybox('skybox3');
            },
        });

        this.intersectionContainer.addObjectToIntersect(skybox1);
        this.intersectionContainer.addObjectToIntersect(skybox2);
        this.intersectionContainer.addObjectToIntersect(skybox3);
        // this.intersectionContainer.addObjectToIntersect(buttonPrevious);

        container.add(skybox1, skybox2, skybox3);
    }

    public checkFileType = async (url) => {
        try {
            const response = await axios.head(url);
            const contentType = response.headers['content-type'];

            if (contentType.startsWith('video/')) {
                return 'video';
            } else if (contentType.startsWith('image/')) {
                return 'image';
            } else {
                throw new Error('Nieobsługiwany typ pliku');
            }
        } catch (error) {
            console.error('Błąd podczas sprawdzania typu pliku:', error);
            throw error;
        }
    };

    public async setSkyboxFromName(fileId: string) {
        if (fileId === 'skybox1') {
            this.dispatchEvent({
                type: 'customSkyboxLoaded',
            });

            this.scene.background = this.resources.items.envmap;
            this.scene.background = this.resources.items.envmap;
            this.scene.environment = this.resources.items.envmap;

            //@ts-ignore
            this.scene.sphereBackground.material.map =
                this.resources.items.envmap;
        } else if (fileId === 'skybox2') {
            const textureLoader = new TextureLoader();

            const texture = await textureLoader.loadAsync(skybox2);
            // texture.encoding = sRGBEncoding;

            this.scene.background = texture;
            this.scene.background = texture;
            this.scene.environment = texture;

            //@ts-ignore
            this.scene.sphereBackground.material.map = texture;

            this.dispatchEvent({
                type: 'customSkyboxLoaded',
            });
        } else if (fileId === 'skybox3') {
            const textureLoader = new TextureLoader();

            const texture = await textureLoader.loadAsync(skybox3);
            // texture.encoding = sRGBEncoding;

            this.scene.background = texture;
            this.scene.background = texture;
            this.scene.environment = texture;

            //@ts-ignore
            this.scene.sphereBackground.material.map = texture;

            this.dispatchEvent({
                type: 'customSkyboxLoaded',
            });
        } else {
            const removeExistingVideos = () => {
                const existingVideos = document.querySelectorAll(
                    'video[data-skybox-video]',
                );
                existingVideos.forEach((video) => video.remove());
            };

            removeExistingVideos();

            const url = `${envierments.baseURL}file/serve/${fileId}`;

            try {
                const fileType = await this.checkFileType(url);
                const textureLoader = new TextureLoader();
                let texture: Texture;

                if (fileType === 'video') {
                    const video = document.createElement('video');
                    video.src = url;
                    video.loop = true;
                    video.muted = true;
                    video.playsInline = true;
                    video.crossOrigin = 'anonymous';
                    video.dataset.skyboxVideo = 'true';
                    video.play();

                    texture = new VideoTexture(video);
                    document.body.appendChild(video);
                } else {
                    texture = await textureLoader.loadAsync(url);
                    texture.encoding = sRGBEncoding;
                }

                if (this.scene.sphereBackground) {
                    if (fileType === 'video') {
                        //@ts-ignore

                        this.scene.sphereBackground.material.map = texture;
                        //@ts-ignore
                        this.scene.sphereBackground.material.emissiveMap = null;
                        //@ts-ignore
                        this.scene.sphereBackground.material.emissive =
                            new Color(0x000000);
                        //@ts-ignore
                        this.scene.sphereBackground.material.emissiveIntensity = 0;
                        //@ts-ignore
                        this.scene.sphereBackground.material.normalMap = null;
                        //@ts-ignore
                        this.scene.sphereBackground.material.envMap = null;
                    } else {
                        //@ts-ignore

                        this.scene.sphereBackground.material.map = texture;
                        //@ts-ignore
                        this.scene.sphereBackground.material.emissiveMap =
                            texture;
                        //@ts-ignore
                        this.scene.sphereBackground.material.emissive =
                            new Color(0xffffff);
                        //@ts-ignore
                        this.scene.sphereBackground.material.emissiveIntensity = 1;
                        //@ts-ignore
                        this.scene.sphereBackground.material.normalMap =
                            texture;
                        //@ts-ignore
                        this.scene.sphereBackground.material.envMap = texture;
                    }
                }

                this.dispatchEvent({
                    type: 'customSkyboxLoaded',
                });
            } catch (error) {
                console.error('Błąd podczas ładowania skyboxa:', error);
            }
        }
    }
}
