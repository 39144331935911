import {
    Object3D,
    Vector2,
    Euler,
    Quaternion,
    Raycaster,
    Camera,
    BoxGeometry,
    MeshBasicMaterial,
    Mesh,
} from 'three';

export class SimpleRotationControls {
    private object: Object3D | null = null;
    private domElement: HTMLElement;
    private camera: Camera;
    private isDragging = false;
    private previousMousePosition = new Vector2();
    private raycaster = new Raycaster();
    private hitbox: Mesh;
    public onRotationChange: ((rotation: Quaternion) => void) | null = null;
    public onRotationEnd: ((rotation: Quaternion) => void) | null = null;

    public constructor(domElement: HTMLElement, camera: Camera) {
        this.domElement = domElement;
        this.camera = camera;
        this.addEventListeners();
    }

    public attach(object: Object3D): void {
        this.object = object;
        this.createHitbox();
    }

    private createHitbox(): void {
        if (!this.object) return;
        const geometry = new BoxGeometry(2, 4, 2);
        const material = new MeshBasicMaterial({
            color: 0x00ff00,
            opacity: 0.3,
            transparent: true,
            visible: false,
        });
        this.hitbox = new Mesh(geometry, material);
        this.object.add(this.hitbox);
    }

    public setHitboxVisibility(visible: boolean): void {
        if (this.hitbox) {
            this.hitbox.visible = visible;
        }
    }

    private addEventListeners(): void {
        this.domElement.addEventListener('mousedown', this.onMouseDown);
        this.domElement.addEventListener('mousemove', this.onMouseMove);
        this.domElement.addEventListener('mouseup', this.onMouseUp);
    }

    private onMouseDown = (event: MouseEvent): void => {
        if (event.button !== 0) return;
        const intersects = this.checkIntersection(event);
        if (intersects.length > 0) {
            this.isDragging = true;
            this.previousMousePosition.set(event.clientX, event.clientY);
        }
    };

    private onMouseMove = (event: MouseEvent): void => {
        if (!this.isDragging || !this.object) return;

        const deltaX = event.clientX - this.previousMousePosition.x;
        const deltaY = event.clientY - this.previousMousePosition.y;

        const rotationX = deltaY * 0.01;
        const rotationY = deltaX * 0.01;

        this.object.rotateX(rotationX);
        this.object.rotateY(rotationY);

        if (this.onRotationChange) {
            this.onRotationChange(this.object.quaternion);
        }

        this.previousMousePosition.set(event.clientX, event.clientY);
    };

    private onMouseUp = (event: MouseEvent): void => {
        if (event.button !== 0) return;
        this.isDragging = false;
        if (this.onRotationEnd && this.object) {
            this.onRotationEnd(this.object.quaternion);
        }
    };

    private checkIntersection(event: MouseEvent): Object3D[] {
        const mouse = new Vector2(
            (event.clientX / this.domElement.clientWidth) * 2 - 1,
            -(event.clientY / this.domElement.clientHeight) * 2 + 1,
        );
        this.raycaster.setFromCamera(mouse, this.camera);
        //@ts-ignore
        return this.raycaster.intersectObject(this.hitbox, true);
    }

    public dispose(): void {
        this.domElement.removeEventListener('mousedown', this.onMouseDown);
        this.domElement.removeEventListener('mousemove', this.onMouseMove);
        this.domElement.removeEventListener('mouseup', this.onMouseUp);
        if (this.hitbox && this.object) {
            this.object.remove(this.hitbox);
        }
    }
}
