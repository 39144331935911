import React, { useState, useEffect } from 'react';
import { container } from 'tsyringe';
import TranslationService from '../../Translations/TranslationService';
import poland from '@assets/icons/poland.png';
import unitedStates from '@assets/icons/united-states.png';
import hamburgerIcon from '@assets/icons/icons8-menu-50.png';

const languageOptions = [
    { value: 'pl', label: 'Polish', flag: poland },
    { value: 'en', label: 'English', flag: unitedStates },
];

const SelectLanguage = ({ className = '', style = {}, forceRerender }) => {
    const translationService = container.resolve(TranslationService);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const defaultLanguage = 'en';

    const getInitialLanguage = () => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        return savedLanguage ? savedLanguage : defaultLanguage;
    };

    const [selectedLanguage, setSelectedLanguage] = useState(
        getInitialLanguage(),
    );

    const handleLanguageChange = async (value) => {
        const selectedValue = value;
        const selectedOption = languageOptions.find(
            (option) => option.value === selectedValue,
        );
        setIsDropdownOpen(false);
        setSelectedLanguage(value);
        translationService.setLanguage(value);
        forceRerender();
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            setSelectedLanguage(savedLanguage);
        }
    }, []);

    return (
        <div className={className} style={style}>
            <div
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="change-language"
            >
                <img src={hamburgerIcon} />
            </div>
            {isDropdownOpen && (
                <div
                    style={{
                        marginTop: '5px',
                        position: 'absolute',
                    }}
                    className="change-language--dropdown"
                >
                    {languageOptions.map((option) => (
                        <div
                            key={option.value}
                            onClick={() => handleLanguageChange(option.value)}
                            className="change-language--dropdown-item"
                            style={{ padding: '5px', cursor: 'pointer' }}
                        >
                            <img src={option.flag} />
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SelectLanguage;
