import { Client, Room } from 'colyseus.js';
import { EventDispatcher, Object3D, Quaternion, Vector3 } from 'three';
import { autoInjectable, singleton } from 'tsyringe';
import envierments from '../../Environments/envierments';
import { ICtxPath } from '../Components/Ui/SketchBoard';
import { checkRoomConnection } from './Decorators/CheckRoomConnection';
import { IImage } from '../react/sketchboards/types';

/*
 *
 * modelRotation direction is -1 for left 1 for right
 * */
export interface ModelViewerData {
    modelUrl?: string;
    modelVisibility?: boolean;
    isAnimationPlaying?: boolean;
    isModelRotating?: boolean;
    modelRotationDirection?: number;
    modelRotationSpeed?: number;
    modelScale?: number;
    on?: boolean;
}

@singleton()
@autoInjectable()
export default class ColyseusClient extends EventDispatcher {
    public client: Client;

    public room: Room;

    public isMultiplayer: boolean = false;

    public lobbyRoom: Room;

    public constructor() {
        super();
        this.client = new Client(envierments.colyseus);
    }

    public async joinLobbyRoom(userId) {
        this.lobbyRoom = await this.client.joinOrCreate('test', { userId });
    }

    public async joinRoom() {
        this.lobbyRoom = await this.client.joinOrCreate('lobby');
    }

    public send(data: any) {
        this.room.send(data);
    }

    public async joinById(roomId: number, options: any) {
        this.room = await this.client.joinById(roomId.toString(), options);
        this.setupMessageHandlers();
    }

    public async createRoom(options) {
        this.room = await this.client.create('my_room', options);
        this.setupMessageHandlers();
    }

    private setupMessageHandlers() {
        // this.room.onMessage('*', (type, message) => {
        //     console.log(`Received message of type: ${type}`, message);
        // });

        this.room.onMessage(
            'modelRotationUpdated',
            (data: { x: number; y: number; z: number; w: number }) => {
                this.dispatchEvent({
                    type: 'modelRotationUpdated',
                    quaternion: new Quaternion(data.x, data.y, data.z, data.w),
                });
            },
        );
    }

    public async changeSkybox(skybox: string) {
        await this.room.send('changeSkybox', skybox);
    }
    @checkRoomConnection
    public async teleport(vector: Vector3) {
        await this.room.send('teleport', {
            x: vector.x,
            y: vector.y,
            z: vector.z,
        });
    }

    @checkRoomConnection
    public async moveHead(quaternion: Quaternion) {
        this.room.send('updateHead', {
            w: quaternion.w,
            x: quaternion.x,
            y: quaternion.y,
            z: quaternion.z,
        });
    }

    @checkRoomConnection
    public async updateController(Vector: Vector3) {
        this.room.send('updateController', {
            x: Vector.x,
            y: Vector.y,
            z: Vector.z,
        });
    }

    @checkRoomConnection
    public async updateRightController(vector: Vector3) {
        this.room.send('updateRightController', {
            x: vector.x,
            y: vector.y,
            z: vector.z,
        });
    }

    @checkRoomConnection
    public async updateControllerSecondRotation(quaternion: Quaternion) {
        this.room.send(
            'updateControllerSecondRotation',
            JSON.stringify({
                w: quaternion.w,
                x: quaternion.x,
                y: quaternion.y,
                z: quaternion.z,
            }),
        );
    }

    public getPlayers() {
        return this.room.state.players;
    }

    public getAvailableRooms() {
        return this.client.getAvailableRooms('my_room');
    }

    public async roomExists(roomId: number): Promise<boolean> {
        const rooms = await this.client.getAvailableRooms('my_room');

        for (let room of rooms) {
            if (Number(room.roomId) === roomId) {
                return true;
            }
        }

        return false;
    }

    @checkRoomConnection
    public async updateControllerRotation(quaternion: Quaternion) {
        this.room.send(
            'updateControllerRotation',
            JSON.stringify({
                w: quaternion.w,
                x: quaternion.x,
                y: quaternion.y,
                z: quaternion.z,
            }),
        );
    }

    @checkRoomConnection
    public async updateModelRotationQuaternion(quaternion: Quaternion) {
        this.room.send('updateModelRotationQuaternion', {
            x: quaternion.x,
            y: quaternion.y,
            z: quaternion.z,
            w: quaternion.w,
        });
    }

    public async leaveRoom() {
        return this.room.leave();
    }

    public getAllConnectedClients() {
        this.client.getAvailableRooms('my_room').then((rooms) => {});
    }

    public async updateSketchBoard(ctxPaths: ICtxPath[]) {
        this.room.send('updateSketchBoard', ctxPaths);
    }

    public async addImageToSketchBoard(index: number, Image: IImage) {
        this.room.send('addImageToSketchBoard', {
            index,
            Image,
        });
    }

    public async updateSketchBoard2(ctxPaths: ICtxPath[]) {
        this.room.send('updateSketchBoard2', ctxPaths);
    }

    public async updateSketchBoard3(ctxPaths: ICtxPath[]) {
        this.room.send('updateSketchBoard3', ctxPaths);
    }

    public resetSketchBoard(sketchBoardName: string) {
        this.room.send('resetSketchBoard', sketchBoardName);
    }

    public async updateModelViewer(currentMesh: number) {
        //this.room.send('updateModelViewer', currentMesh);
    }

    public async changeModel(model_url: string) {
        this.room.send('changeModel', model_url);
    }

    public async updateModel3dViewer(modelViewerData: ModelViewerData) {
        this.room.send('updateModel3dViewer', modelViewerData);
    }
}
