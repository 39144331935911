import Loader from './Utils/Loader';
import EventEmitter from './Utils/EventEmitter';

import body from '@models/player/Body.gltf';
import hand from '@models/player/hand.gltf';
import head from '@models/player/head.gltf';
import robot2body from '@models/robots/robot2body.gltf';
import robot2head from '@models/robots/robot2head.gltf';
import robot4body from '@models/robots/robot4body.gltf';
import robot4head from '@models/robots/robot2_head.gltf';
import robotHands from '@models/robots/robot_hands.gltf';

import settingsIcon from '@assets/settings-xxl.png';
import coruseIcon from '@assets/icons/defaultCourseIcon.png';
import arrowBottom from '@assets/icons/arrowBottom.png';
import arrowTop from '@assets/icons/arrowTop.png';
import rightArrow from '@assets/icons/icon-name-scroll-right-white-full.png';
import leftArrow from '@assets/icons/icon-name-scroll-left-white-full.png';
import circleIcon from '@assets/icons/circle-outline-128.png';
import speakerIcon from '@assets/icons/icons8-speaker-96.png';

import chatbot from '@models/robots/chatbot.gltf';
import lobbyScene from '@scenes/mainLobby.gltf';
import classScene from '@scenes/classroom.gltf';
import schoolScene from '@scenes/schoolLobby.gltf';
import groupScene from '@scenes/groupScene.gltf';
import privateRoom from '@scenes/privateSpace.gltf';
import helvetiker from '@models/rooms/helvetiker-regular-font.json';
import roboto_regular from '@models/rooms/Roboto_Regular.json';
import sketchBoard from '@models/sketchboardModel.gltf';

import envmap from '@assets/sky.jpg';

import model3dPlatform from '@models/modelViewerPlatform.gltf';

import { singleton } from 'tsyringe';
import AxiosHttpClient from './Network/AxiosHttpClient';
@singleton()
export default class Resources extends EventEmitter {
    public loader: Loader;
    public items: { [key: string]: any } = {};

    public constructor(public httpClient: AxiosHttpClient) {
        super();
    }

    public start() {
        this.loader = new Loader(this.httpClient);
        this.items = {};

        this.loader.on('fileEnd', (_resource: any, _data: any) => {
            this.items[_resource.name] = _data;

            const { loaded, toLoad } = this.loader;
            const percent = Math.round((loaded / toLoad) * 100);
            this.trigger('progress', [percent]);
        });

        this.loader.on('end', () => {
            this.trigger('ready');
        });
        this.loader.load([
            { name: 'user', source: 'api/v2/user/', type: 'http' },
            { name: 'envmap', source: envmap, type: 'cube' },
            { name: 'body', source: body },
            { name: 'hand', source: hand },
            { name: 'head', source: head },
            { name: 'settingsIcon', source: settingsIcon },
            { name: 'defaultCourseIcon', source: coruseIcon },
            { name: 'arrowBottom', source: arrowBottom },
            { name: 'arrowTop', source: arrowTop },
            { name: 'leftArrow', source: leftArrow },
            { name: 'rightArrow', source: rightArrow },
            { name: 'robot2body', source: robot2body },
            { name: 'robot2head', source: robot2head },
            { name: 'robot4body', source: robot4body },
            { name: 'robot4head', source: robot4head },
            { name: 'robotHands', source: robotHands },
            {
                name: 'helvetiker',
                source: helvetiker,
                type: 'font',
            },
            {
                name: 'roboto_regular',
                source: roboto_regular,
                type: 'font',
            },
            { name: 'lobbyScene', source: lobbyScene },
            { name: 'classScene', source: classScene },
            { name: 'schoolScene', source: schoolScene },
            { name: 'groupScene', source: groupScene },
            { name: 'privateRoom', source: privateRoom },

            { name: 'circleIcon', source: circleIcon },
            { name: 'speakerIcon', source: speakerIcon },

            { name: 'chatbot', source: chatbot },
            { name: 'sketchBoard', source: sketchBoard },

            { name: 'model3dPlatform', source: model3dPlatform },
            {
                name: 'translations',
                source: (userData) => {
                    return `api/v2/translations/${userData.lang_id}`;
                },
                type: 'http',
                dependsOn: { resource: 'user' },
            },
            {
                name: 'translationsPL',
                source: `api/v2/translations/`,
                type: 'http',
            },
            {
                name: 'settings',
                source: '/api/v2/settings',
                type: 'http',
            },
            {
                name: 'events',
                source: '/api/v2/user/notifications',
                type: 'http',
            },
        ]);
    }
}
