import './main.css';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import ImageToDrag from './image-to-drag';
import ColyseusClient from '../../Network/ColyseusClient';
import { container } from 'tsyringe';
import { IImage } from './types';
import { MdClose, MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { RiImageAddFill } from 'react-icons/ri';
import { SketchBoard } from '../../Components/Ui/SketchBoard';
import TextToDrag from './text-to-drag';
import IconEraserWhite from '@assets/icons/icon-name-eraser.png';
import OnlyForTeacher from '../helpers/only-for-teacher';
import { TfiText } from 'react-icons/tfi';
import TranslationService from '../../Translations/TranslationService';

const SkechboardPanel = ({ show, handleClose }) => {
    const [currentCanvasIndex, setCurrentCanvasIndex] = useState(0);
    const sketchboardsRef = useRef<HTMLDivElement>(null);
    const canvasesRef = useRef<HTMLCanvasElement[]>([]);
    const canvasWrapperRef = useRef();
    const [file, setFile] = useState(null);
    const [text, setText] = useState(false);

    const colyseusClient = container.resolve(ColyseusClient);

    const showCanvas = (index: number) => {
        canvasesRef.current.forEach((canvas, i) => {
            canvas.style.display = i === index ? 'block' : 'none';
        });
    };

    const handleNext = () => {
        const nextIndex = (currentCanvasIndex + 1) % canvasesRef.current.length;
        setCurrentCanvasIndex(nextIndex);
        showCanvas(nextIndex);
    };

    const handlePrev = () => {
        const prevIndex =
            (currentCanvasIndex - 1 + canvasesRef.current.length) %
            canvasesRef.current.length;
        setCurrentCanvasIndex(prevIndex);
        showCanvas(prevIndex);
    };

    const colors = [
        '000000',
        '2a62c9',
        'ed7c31',
        '71ad47',
        'ffc001',
        'ff0000',
        '749cb8',
        'eraser',
    ];

    const [selectedColor, setSelectedColor] = useState('000000');

    const handleChangeColor = (color) => {
        const currentSketchboard = getCurrentSketchboard();
        setSelectedColor(color);

        //@ts-ignore
        currentSketchboard.colorButtons[color].setState('selected');
    };

    const getCurrentSketchboard = () => {
        switch (currentCanvasIndex) {
            case 0:
                return window.sketchboard1 as SketchBoard;
            case 1:
                return window.sketchboard2 as SketchBoard;
            case 2:
                return window.sketchboard3 as SketchBoard;
        }
    };

    const ColorToolbar = ({ colors }) => {
        return (
            <div className="sketchboard-colors-toolbar">
                {colors.map((color, key) => (
                    <button
                        key={key}
                        className={`sketchboard-color ${
                            selectedColor === color ? 'selected' : ''
                        }`}
                        onClick={() => handleChangeColor(color)}
                        style={{
                            backgroundColor: '#' + color,
                            border:
                                color === 'eraser' ? '1px solid #ffffff' : '',
                        }}
                    >
                        {color === 'eraser' && <img src={IconEraserWhite} />}
                    </button>
                ))}
            </div>
        );
    };

    useEffect(() => {
        if (
            getCurrentSketchboard() &&
            getCurrentSketchboard().canvasToolColor
        ) {
            setSelectedColor(
                getCurrentSketchboard().canvasToolColor.replace('#', ''),
            );
        }
    }, [currentCanvasIndex]);

    useEffect(() => {
        const observedElement = sketchboardsRef.current;

        if (!observedElement) return;

        const config = {
            attributes: true,
            childList: true,
            subtree: true,
            characterData: true,
        };

        const callback = (mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    onCanvasElementsChange();
                }
            }
        };

        const observer = new MutationObserver(callback);
        observer.observe(observedElement, config);

        return () => observer.disconnect();
    }, []);

    const onCanvasElementsChange = () => {
        if (sketchboardsRef.current) {
            const canvasElements =
                sketchboardsRef.current.querySelectorAll<HTMLCanvasElement>(
                    'canvas',
                );

            canvasesRef.current = Array.from(canvasElements);
            // setCurrentCanvasIndex(0);
            showCanvas(0);
        }
    };

    const optimizeImage = (img: HTMLImageElement): string => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const MAX_WIDTH = 800;
        const MAX_HEIGHT = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        return canvas.toDataURL('image/png');
    };

    const handleImageUpload = (
        e: ChangeEvent<HTMLInputElement>,
        setFile: (file: string) => void,
    ) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result as string;

            img.onload = () => {
                const optimizedDataUrl = optimizeImage(img);
                setFile(optimizedDataUrl);
                e.target.value = null;
            };
        };

        if (file) {
            reader.readAsDataURL(file);
        }

        e.target.value = '';
    };
    const onSuccessImage = async (event: IImage) => {
        await colyseusClient.addImageToSketchBoard(currentCanvasIndex, event);
        setFile(null);
    };

    const onSuccessText = async (event: IImage) => {
        await colyseusClient.addImageToSketchBoard(currentCanvasIndex, event);
        setText(false);
    };

    const handleTextAdd = () => {
        setText(true);
    };

    const handleReset = async () => {
        await getCurrentSketchboard().clearCanvasBoard();
    };

    const onCancelImage = () => {
        setFile(false);
    };

    const onCancelText = () => {
        setText(false);
    };

    return (
        <div
            className="sketchboard-panel-wrapper"
            ref={canvasWrapperRef}
            style={{
                display: show ? 'flex' : 'none',
            }}
        >
            <div className="d-flex flex-column sketchboard-panel">
                <OnlyForTeacher>
                    <div className="d-flex flex-row justify-content-between align-items-center mb-1 sketchboard-panel-toolbar">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <button
                                onClick={handlePrev}
                                className="close-button"
                            >
                                <MdNavigateBefore size="2em" />
                            </button>

                            <h4
                                style={{
                                    color: '#FFF',
                                    paddingBottom: 0,
                                    marginBottom: 0,
                                }}
                                className="sketchboard-panel-title"
                            >
                                SketchBoard {currentCanvasIndex + 1}
                            </h4>
                            <div className="buttons">
                                <ColorToolbar colors={colors}></ColorToolbar>
                                <div className="upload-image sketchboard-buttons ">
                                    <label htmlFor="formId">
                                        <input
                                            name=""
                                            type="file"
                                            onChange={(e) =>
                                                handleImageUpload(e, setFile)
                                            }
                                            id="formId"
                                            hidden
                                        />
                                        <RiImageAddFill
                                            size="1.5em"
                                            className="icon-sketchboard"
                                        />
                                    </label>
                                </div>
                                <button
                                    className="sketchboard-buttons"
                                    onClick={handleTextAdd}
                                >
                                    <TfiText
                                        size="1.5em"
                                        className="icon-sketchboard"
                                    />
                                </button>

                                <button
                                    className="sketchboard-buttons"
                                    onClick={handleReset}
                                >
                                    {TranslationService.translate(
                                        'vr.clear_sketchboard',
                                    )}
                                </button>

                                <button
                                    onClick={handleNext}
                                    className="close-button"
                                >
                                    <MdNavigateNext size="2em" />
                                </button>
                            </div>
                        </div>

                        <button className="close-button" onClick={handleClose}>
                            <MdClose size="2em" />
                        </button>
                    </div>
                </OnlyForTeacher>
                {file && (
                    <ImageToDrag
                        canvasWrapperRef={canvasWrapperRef}
                        sketchboardsRef={sketchboardsRef}
                        src={file}
                        slectedSketchboard={currentCanvasIndex}
                        onSuccess={(event) => onSuccessImage(event)}
                        onCancel={() => {
                            onCancelImage();
                        }}
                    />
                )}

                {text && (
                    <TextToDrag
                        canvasWrapperRef={canvasWrapperRef}
                        sketchboardsRef={sketchboardsRef}
                        selectedSketchboard={currentCanvasIndex}
                        onSuccess={(event) => onSuccessText(event)}
                        onCancle={() => {
                            onCancelText();
                        }}
                    />
                )}
                <div id="canvas-elements" ref={sketchboardsRef}></div>
            </div>
        </div>
    );
};

export default SkechboardPanel;
