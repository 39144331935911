import { container } from 'tsyringe';
import Resources from '../../Resources';
import { useEffect, useState } from 'react';

const OnlyForTeacher = ({ children }) => {
    const resources = container.resolve(Resources);
    const [isTeacher, setIsTeacher] = useState(false);

    useEffect(() => {
        const checkIfTeacher = () => {
            const userIsTeacher = resources.items.user.is_school_teacher;
            setIsTeacher(userIsTeacher);
        };

        checkIfTeacher();
    }, [resources]);

    if (!isTeacher) {
        return null;
    }

    return <>{children}</>;
};

export default OnlyForTeacher;
