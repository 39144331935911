import { EventDispatcher, Group, Mesh, Object3D } from 'three';
import { autoInjectable, singleton } from 'tsyringe';
import XrControllers from './XrControllers';
import { acceleratedRaycast, computeBoundsTree, MeshBVH } from 'three-mesh-bvh';
// import { ParallelMeshBVHWorker } from 'three-mesh-bvh/src/workers/ParallelMeshBVHWorker.js';
import { GenerateMeshBVHWorker } from 'three-mesh-bvh/src/workers/GenerateMeshBVHWorker.js';

@singleton()
export default class IntersectionContainer extends EventDispatcher {
    public objectsToIntersect: any[] = [];

    public xrControllers: XrControllers;

    public BVHWorker = new GenerateMeshBVHWorker();

    public constructor() {
        super();
    }

    public addObjectToIntersect(
        object: any,
        recursive: boolean = false,
        addStates: boolean = false,
    ) {
        if (object.geometry) {
            object.boundsTree = new MeshBVH(object.geometry);
            object.raycast = acceleratedRaycast;
            // (object as Mesh).receiveShadow = true;
            // (object as Mesh).castShadow = false;
        }

        //object.traverse((child: any) => {
        //if (child.geometry) {
        // child.boundsTree = new MeshBVH(child.geometry, {});
        // child.raycast = acceleratedRaycast;
        // (child as Mesh).receiveShadow = false;
        // (object as Mesh).castShadow = false;
        // }
        //});

        if (recursive) {
            if (object.children.length === 0) {
                return;
            }
            object.children.forEach((object) => {
                object.userData.isIntersected = false;
                this.objectsToIntersect.push(object);
            });

            return;
        }

        // if (object.geometry) {
        //     object.geometry.computeBoundsTree = computeBoundsTree;
        //     object.geometry.computeBoundsTree();
        // }
        //
        // object.traverse((child: any) => {
        //     if (child.geometry) {
        //         child.geometry.computeBoundsTree = computeBoundsTree;
        //         child.geometry.computeBoundsTree();
        //         console.log(child);
        //     }
        // });

        object.userData.isIntersected = false;
        this.objectsToIntersect.push(object);
    }

    public reset() {
        this.objectsToIntersect = [];
    }

    public remove(object: any) {
        this.objectsToIntersect = this.objectsToIntersect.filter(
            (item) => item !== object,
        );
    }
    public removeFromIntersect(object: any, recursive: boolean = false) {
        if (recursive) {
            this.objectsToIntersect.forEach((_object) => {
                object.children.forEach((child) => {
                    if (child.uuid === _object.uuid) {
                        this.objectsToIntersect.splice(
                            this.objectsToIntersect.indexOf(_object),
                            1,
                        );
                    }
                });
            });
            if (object.children.length === 0) {
                return;
            }
            this.objectsToIntersect.filter((_object, key) => {
                if (_object.uuid === object.uuid) {
                    delete this.objectsToIntersect[key];
                }
            });

            return;
        }

        delete this.objectsToIntersect[object];
    }

    public getUiObjects(): any {
        const objects = [];
        this.objectsToIntersect.forEach((object) => {
            if (object.isUI || object.userData.isUI) {
                objects.push(object);
            }
        });
        return objects;
    }
}
