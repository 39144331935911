import Application from '../../Application';
import VrScene from '../../Components/Three/VrScene';
import GroupScene from '../../Scenes/Group/GroupScene';
import Classroom from '../../Scenes/Classroom/ClassroomScene';

export const ListElement = ({
    isGroup,
    roomId,
    children,
    sceneName,
    clickLessonRoomType,
}) => {
    const app = window['app'] as Application;

    const clickHandler = () => {
        (app.scene as VrScene).changeScene(
            isGroup ? new GroupScene() : new Classroom(),
            Number(roomId),
            true,
            sceneName,
        );

        const elements = document.querySelectorAll('.menu-list');
        elements.forEach((element) => {
            element.classList.add('hidden');
        });
    };

    return (
        <button
            className={`menu-list-btn`}
            onClick={() => {
                clickHandler(), clickLessonRoomType(isGroup);
            }}
        >
            {children}
        </button>
    );
};
