import Application from './javascript/Application';
import jwtDecode from 'jwt-decode';
import envierments from './Environments/envierments';
import RedirectService from './javascript/RedirectService';
import { autoInjectable } from 'tsyringe';

declare global {
    interface Window {
        skipObtainToken: boolean;
        app: any;
    }
}

@autoInjectable()
export default class Bootstrap {
    private app: Application | null = null;
    private tokenFromLocalStorage: string = '';

    public constructor(public redirectService?: RedirectService) {
        this.initialize();
    }

    private initialize(): void {
        const urlParams = new URLSearchParams(window.location.search);
        const user = urlParams.get('user');
        const tokenFromUrl = urlParams.get('token');
        this.tokenFromLocalStorage = localStorage.getItem('token');
        window.skipObtainToken = false;

        if (window.location.hash === '#debug') {
            this.startApplication();
            return;
        }

        this.redirectService.initialize();

        if (tokenFromUrl) {
            if (localStorage.getItem('token')) {
                localStorage.removeItem('token');
            }
            localStorage.setItem('refresh-token', tokenFromUrl);
            window.location.search = '';
        } else if (this.isTokenValid()) {
            window.skipObtainToken = true;
            this.startApplication();
        } else if (user) {
            this.startApplication();
        } else {
            if (
                !localStorage.getItem('refresh-token') &&
                window.location.hash !== '#debug'
            ) {
                window.location.href = envierments.baseURL;
            }
        }

        if (localStorage.getItem('refresh-token')) {
            this.delayedAppStart();
        }
    }

    private isTokenValid() {
        if (!this.tokenFromLocalStorage) {
            return false;
        }

        const decodedToken = jwtDecode(this.tokenFromLocalStorage);

        //@ts-ignore
        return Date.now() < decodedToken.exp * 1000;
    }

    private startApplication(): void {
        this.app = new Application();
        this.app.init({
            $canvas: document.querySelector('.js-canvas') as HTMLCanvasElement,
            domContainer: document.querySelector(
                '.container',
            ) as HTMLDivElement,
        });

        this.showGame();
    }

    private delayedAppStart(): void {
        setTimeout(() => {
            this.app = new Application();
            this.app.init({
                $canvas: document.querySelector(
                    '.js-canvas',
                ) as HTMLCanvasElement,
                domContainer: document.querySelector(
                    '.container',
                ) as HTMLDivElement,
            });
            this.showGame();
        }, 2000);
    }

    private showGame(): void {
        document.querySelector('.game')?.classList.remove('hidden');
    }
}
