function EnsurePluginHandle(
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor,
) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
        return new Promise<void>(async (resolve, reject) => {
            while (this.pluginHandle === undefined) {
                await new Promise((res) => setTimeout(res, 100)); // Oczekiwanie 100 ms
            }

            originalMethod.apply(this, args).then(resolve).catch(reject);
        });
    };

    return descriptor;
}

export default EnsurePluginHandle;
