import { BehaviorSubject, Subject } from 'rxjs';
//@ts-ignore
import { Janus } from 'janus-gateway';
import environments from '../../../../Environments/envierments';
import { autoInjectable, singleton } from 'tsyringe';
import { EventDispatcher } from 'three';
import Resources from '../../../Resources';

export interface JanusTrack {
    track: MediaStreamTrack;
    type: 'audio' | 'video';
    mid?: string;
    stream: MediaStream;
}

export interface JanusEvents {
    localTracks: Subject<JanusTrack>;
    remoteTracks: Subject<JanusTrack>;
    trackRemoved: Subject<string>;
    connectionState: Subject<'connected' | 'disconnected'>;
    error: Subject<string>;
    speaking: Subject<boolean>;
    userSpeaking: Subject<{
        feedId: string | number;
        talking: boolean;
        audioLevel?: number;
    }>;
}

interface JanusHandle {
    handleId: string;
    plugin: any;
    stream?: MediaStream;
}

export interface PublishOptions {
    audioSend?: boolean;
    videoSend?: boolean;
    audio?: {
        deviceId?: string;
        mandatory?: {
            echoCancellation?: boolean;
            noiseSuppression?: boolean;
            autoGainControl?: boolean;
        };
    };
    video?:
        | {
              deviceId?: string;
              mandatory?: {
                  maxWidth?: number;
                  maxHeight?: number;
                  minFrameRate?: number;
              };
          }
        | 'screen';
}

const defaultOptions: PublishOptions = {
    audioSend: true,
    videoSend: false,
    audio: {
        mandatory: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
        },
    },
};

@singleton()
@autoInjectable()
export class JanusClient extends EventDispatcher {
    private connection: any = null;
    public publisherHandle: JanusHandle | null = null;
    public subscriberHandles: Map<string, JanusHandle> = new Map();
    private roomId: number | null = null;
    public displayName: string = '';
    private pendingPublishOptions: PublishOptions | null = null;

    public connected$ = new BehaviorSubject<boolean>(false);
    public error$ = new BehaviorSubject<string | null>(null);
    public publishing$ = new BehaviorSubject<boolean>(false);

    public _screenShareActive: boolean = false;

    private _screenStream: MediaStream | null = null;

    public userMapping: Map<string, { displayName: string; userId?: number }> = new Map();

    public events: JanusEvents = {
        localTracks: new Subject<JanusTrack>(),
        remoteTracks: new Subject<JanusTrack>(),
        trackRemoved: new Subject<string>(),
        connectionState: new Subject<'connected' | 'disconnected'>(),
        error: new Subject<string>(),
        speaking: new Subject<boolean>(),
        userSpeaking: new Subject<{
            feedId: string | number;
            talking: boolean;
            audioLevel?: number;
        }>(),
    };

    private serverUrl: string;

    public constructor(public resources?: Resources) {
        super();
        this.serverUrl = environments.janus;
    }

    public async connect(): Promise<void> {
        if (this.connection) {
            return;
        }

        return new Promise((resolve, reject) => {
            if (typeof Janus === 'undefined') {
                const error = 'Janus library not found!';
                this.error$.next(error);
                this.events.error.next(error);
                reject(new Error(error));
                return;
            }

            Janus.init({
                debug: false,
                callback: () => {
                    const janus = new Janus({
                        server: this.serverUrl,
                        success: () => {
                            this.connection = janus;
                            this.connected$.next(true);
                            this.events.connectionState.next('connected');
                            resolve();
                        },
                        error: (error: any) => {
                            console.error('Failed to connect to Janus server:', error);
                            this.error$.next(typeof error === 'string' ? error : JSON.stringify(error));
                            this.events.error.next(typeof error === 'string' ? error : JSON.stringify(error));
                            reject(error);
                        },
                        destroyed: () => {
                            this.connected$.next(false);
                            this.events.connectionState.next('disconnected');
                        },
                    });
                },
            });
        });
    }

    public async joinRoom(roomId: number, displayName: string, options: PublishOptions = {}): Promise<void> {
        if (!this.connection) {
            const error = 'No connection to Janus';
            this.events.error.next(error);
            throw new Error(error);
        }

        this.roomId = roomId;
        this.displayName = displayName;
        this.pendingPublishOptions = options;

        return new Promise((resolve, reject) => {
            this.connection.attach({
                plugin: 'janus.plugin.videoroom',
                opaqueId: `publisher-${Date.now()}`,
                success: (pluginHandle: any) => {
                    this.publisherHandle = {
                        handleId: pluginHandle.getId(),
                        plugin: pluginHandle,
                    };

                    const join = {
                        request: 'join',
                        room: roomId,
                        ptype: 'publisher',
                        display: displayName,
                        audiolevel_event: true,
                        audio_active_packets: 10,
                        audio_level_average: 50,
                    };

                    pluginHandle.send({
                        message: join,
                        success: () => {
                            this.userMapping.set(this.publisherHandle.handleId.toString(), {
                                displayName: this.displayName,
                                userId: this.resources?.items?.user?.id,
                            });

                            this.dispatchEvent({
                                type: 'joinedRoom',
                                detail: {
                                    roomId: roomId,
                                    feedId: this.publisherHandle.handleId,
                                    displayName: this.displayName,
                                    userId: this.resources?.items?.user?.id,
                                },
                            });

                            if (options.audioSend || options.videoSend) {
                                this.publishOwnFeed(options);
                            }
                            resolve();
                        },
                        error: (error: any) => {
                            reject(error);
                        },
                    });
                },
                error: (error: any) => {
                    this.error$.next(typeof error === 'string' ? error : JSON.stringify(error));
                    this.events.error.next(typeof error === 'string' ? error : JSON.stringify(error));
                    reject(error);
                },
                consentDialog: (on: boolean) => {},
                onmessage: (msg: any, jsep: any) => {
                    this.handlePublisherMessage(msg, jsep);

                    const event = msg['videoroom'];

                    if (msg['videoroom'] === 'talking' || msg['videoroom'] === 'stopped-talking') {
                        const isTalking = msg['videoroom'] === 'talking';
                        const feedId = msg['id'];
                        const audioLevel = msg['audio-level-dBov-avg'];

                        const userInfo = this.userMapping.get(feedId.toString());

                        this.dispatchEvent({
                            type: 'userSpeaking',
                            detail: {
                                feedId: feedId,
                                talking: isTalking,
                                audioLevel: audioLevel,
                                displayName: userInfo?.displayName,
                                userId: userInfo?.userId,
                            },
                        });

                        return;
                    }

                    if (event === 'joined') {
                        this.userMapping.set(msg.id.toString(), {
                            displayName: this.displayName,
                            userId: this.resources?.items?.user?.id,
                        });

                        if (msg.publishers && msg.publishers.length > 0) {
                            msg.publishers.forEach((publisher) => {
                                this.userMapping.set(publisher.id.toString(), {
                                    displayName: publisher.display,
                                    userId: undefined,
                                });
                            });
                        }
                    } else if (event === 'event') {
                        if (msg.publishers && msg.publishers.length > 0) {
                            msg.publishers.forEach((publisher) => {
                                this.userMapping.set(publisher.id.toString(), {
                                    displayName: publisher.display,
                                    userId: undefined,
                                });
                            });
                        }
                    }
                },
                onlocaltrack: (track: MediaStreamTrack, on: boolean) => {
                    if (on) {
                        const stream = new MediaStream();
                        stream.addTrack(track);
                        this.events.localTracks.next({
                            track,
                            type: track.kind as 'audio' | 'video',
                            stream,
                        });
                    } else {
                        this.events.trackRemoved.next(track.id);
                    }
                },
                oncleanup: () => {
                    this.publishing$.next(false);
                },
                webrtcState: (isConnected: boolean) => {
                    this.events.connectionState.next(isConnected ? 'connected' : 'disconnected');
                },
                iceState: (state: string) => {},
                mediaState: (medium: string, on: boolean) => {},
                slowLink: (uplink: boolean, lost: number) => {
                    console.warn(
                        `Publisher experiencing ${uplink ? 'uplink' : 'downlink'} issues, lost packets: ${lost}`,
                    );
                },
            });
        });
    }

    private handlePublisherMessage(msg: any, jsep: any): void {
        const event = msg['videoroom'];
        if (event === 'joined') {
            this.publishing$.next(true);

            if (msg.publishers && msg.publishers.length > 0) {
                this.subscribeToFeeds(msg.publishers);
            }
        } else if (event === 'event') {
            if (msg.publishers && msg.publishers.length > 0) {
                this.subscribeToFeeds(msg.publishers);
            }

            if (msg.leaving) {
                const leavingId = Array.isArray(msg.leaving) ? msg.leaving : [msg.leaving];

                leavingId.forEach((id) => {
                    const userInfo = this.userMapping.get(id.toString());

                    if (userInfo) {
                        this.dispatchEvent({
                            type: 'publisherLeft',
                            detail: {
                                feedId: id,
                                displayName: userInfo.displayName,
                                userId: userInfo.userId,
                            },
                        });

                        this.userMapping.delete(id.toString());
                    }
                });
            }

            if (msg.configured === 'ok') {
            }
        }

        if (jsep) {
            this.publisherHandle?.plugin.handleRemoteJsep({ jsep });
        }
    }

    /**
     * Publikuje media (audio i/lub wideo) do pokoju Janus.
     * @param options Opcje publikowania
     * @returns Promise rozwiązujący się po udanym opublikowaniu lub odrzucający z błędem
     */
    private publishOwnFeed(options: PublishOptions = {}): Promise<void> {
        return new Promise((resolve, reject) => {
            const handle = this.publisherHandle?.plugin;
            if (!handle) {
                const error = 'Cannot publish: no publisher handle available';
                console.error(error);
                this.error$.next(error);
                reject(new Error(error));
                return;
            }

            const publishOptions = { ...defaultOptions, ...options };

            const tracks: Array<{
                type: 'audio' | 'video' | 'data';
                capture: any;
            }> = [];

            if (publishOptions.audioSend) {
                tracks.push({
                    type: 'audio',
                    capture: publishOptions.audio || true,
                });
            }

            if (publishOptions.videoSend) {
                tracks.push({
                    type: 'video',
                    capture: publishOptions.video === 'screen' ? 'screen' : publishOptions.video || true,
                });
            }

            handle.createOffer({
                tracks: tracks,
                success: (jsep: any) => {
                    const publish = {
                        request: 'configure',
                        audio: publishOptions.audioSend,
                        video: publishOptions.videoSend,
                    };

                    handle.send({
                        message: publish,
                        jsep: jsep,
                        success: () => {
                            this.publishing$.next(true);

                            setTimeout(() => {
                                if (publishOptions.videoSend) {
                                    const videoTracks = this.publisherHandle?.stream?.getVideoTracks() || [];
                                    if (videoTracks.length === 0) {
                                        console.warn('No video tracks found after publishing');
                                    }
                                }

                                if (publishOptions.audioSend) {
                                    const audioTracks = this.publisherHandle?.stream?.getAudioTracks() || [];
                                    if (audioTracks.length === 0) {
                                        console.warn('No audio tracks found after publishing');
                                    }
                                }

                                resolve();
                            }, 500);
                        },
                        error: (error: any) => {
                            const errorMsg =
                                'Failed to publish: ' + (typeof error === 'string' ? error : JSON.stringify(error));
                            console.error(errorMsg);
                            this.error$.next(errorMsg);
                            reject(new Error(errorMsg));
                        },
                    });
                },
                error: (error: any) => {
                    const errorMsg =
                        'Failed to create WebRTC offer: ' + (typeof error === 'string' ? error : JSON.stringify(error));
                    console.error(errorMsg);
                    this.error$.next(errorMsg);
                    this.events.error.next(errorMsg);
                    reject(new Error(errorMsg));
                },
            });
        });
    }

    public subscribeToFeeds(publishers: any[]): void {
        if (!this.connection || !this.roomId) {
            console.error('Cannot subscribe: no connection or room ID');
            return;
        }

        publishers.forEach((publisher) => {
            const feedId = publisher.id;

            let pluginHandle = null;
            this.connection.attach({
                plugin: 'janus.plugin.videoroom',
                opaqueId: `subscriber-${feedId}-${Date.now()}`,
                success: (pluginHandle: any) => {
                    pluginHandle = pluginHandle;
                    this.subscriberHandles.set(feedId.toString(), {
                        handleId: pluginHandle.getId(),
                        plugin: pluginHandle,
                    });

                    const streams = [];

                    if (publisher.streams && publisher.streams.length > 0) {
                        publisher.streams.forEach((stream: any) => {
                            streams.push({
                                feed: feedId,
                                mid: stream.mid,
                            });
                        });
                    } else {
                        streams.push({ feed: feedId });
                    }

                    const subscribe = {
                        request: 'join',
                        room: this.roomId,
                        ptype: 'subscriber',
                        feed: feedId,
                        private_id: publisher.private_id,
                        streams: streams,
                    };

                    pluginHandle.send({
                        message: subscribe,
                        success: () => {},
                        error: (error: any) => {
                            console.error(`Error subscribing to feed ${feedId}:`, error);
                            this.error$.next(`Failed to subscribe to feed ${feedId}`);
                        },
                    });
                },
                error: (error: any) => {
                    console.error(`Error attaching subscriber plugin for feed ${feedId}:`, error);
                    this.error$.next(`Failed to attach subscriber plugin for feed ${feedId}`);
                },
                onmessage: (msg: any, jsep: any) => {
                    this.handleSubscriberMessage(feedId, msg, jsep, pluginHandle);
                    const event = msg['videoroom'];

                    if (event === 'event' && msg.leaving) {
                        const leavingId = Array.isArray(msg.leaving) ? msg.leaving : [msg.leaving];
                        leavingId.forEach((id) => {
                            const userInfo = this.userMapping.get(id.toString());

                            if (userInfo) {
                                this.dispatchEvent({
                                    type: 'publisherLeft',
                                    detail: {
                                        feedId: id,
                                        displayName: userInfo.displayName,
                                        userId: userInfo.userId,
                                    },
                                });

                                this.userMapping.delete(id.toString());
                            }
                        });
                    }

                    if (msg['videoroom'] === 'talking' || msg['videoroom'] === 'stopped-talking') {
                        const isTalking = msg['videoroom'] === 'talking';
                        const speakerId = msg['id'];
                        const audioLevel = msg['audio-level-dBov-avg'];

                        const userInfo = this.userMapping.get(speakerId.toString());

                        this.dispatchEvent({
                            type: 'userSpeaking',
                            detail: {
                                feedId: speakerId,
                                talking: isTalking,
                                audioLevel: audioLevel,
                                displayName: userInfo?.displayName,
                                userId: userInfo?.userId,
                            },
                        });

                        return;
                    }
                },
                onremotetrack: (track: MediaStreamTrack, mid: string, on: boolean, added: boolean, metadata) => {
                    if (added) {
                        const handle = this.subscriberHandles.get(feedId.toString());
                        if (handle) {
                            if (!handle.stream) {
                                handle.stream = new MediaStream();
                            }

                            handle.stream.addTrack(track);
                        }

                        const userInfo = this.userMapping.get(feedId.toString()) || {
                            displayName: null,
                            userId: null,
                        };

                        const singleTrackStream = new MediaStream();
                        singleTrackStream.addTrack(track);

                        this.events.remoteTracks.next({
                            track,
                            type: track.kind as 'audio' | 'video',
                            mid,
                            stream: singleTrackStream,
                        });

                        this.dispatchEvent({
                            type: 'publisherAdded',
                            detail: {
                                mid,
                                track,
                                stream: singleTrackStream,
                                kind: track.kind,
                                feedId,
                                displayName: userInfo.displayName,
                                userId: userInfo.userId,
                            },
                        });
                    } else {
                        this.events.trackRemoved.next(mid);

                        const userInfo = this.userMapping.get(feedId.toString()) || {
                            displayName: null,
                            userId: null,
                        };

                        this.dispatchEvent({
                            type: 'publisherRemoved',
                            detail: {
                                mid,
                                feedId,
                                displayName: userInfo.displayName,
                                userId: userInfo.userId,
                            },
                        });
                    }
                },
                oncleanup: () => {
                    console.log(`Subscriber PeerConnection for feed ${feedId} cleaned up`);
                },
            });
        });
    }

    private handleSubscriberMessage(feedId: string, msg: any, jsep: any, pluginHandle: any): void {
        const event = msg['videoroom'];
        if (event === 'attached') {
        }

        if (jsep) {
            const handle = this.subscriberHandles.get(feedId.toString())?.plugin;
            if (!handle) {
                return;
            }

            handle.createAnswer({
                jsep: jsep,
                media: { audioSend: false, videoSend: false },
                success: (jsep: any) => {
                    const body = { request: 'start', room: this.roomId };
                    handle.send({
                        message: body,
                        jsep: jsep,
                        success: () => {},
                        error: (error: any) => {
                            console.error(`Error starting feed ${feedId}:`, error);
                        },
                    });
                },
                error: (error: any) => {
                    console.error(`Error creating answer for feed ${feedId}:`, error);
                    this.error$.next(`Failed to create answer for feed ${feedId}`);
                },
            });
        }
    }

    public async startScreenShare(): Promise<void> {
        try {
            const isAudioActive = this.publisherHandle?.plugin?.isAudioMuted() === false;

            await new Promise<void>((resolve, reject) => {
                if (!this.publisherHandle || !this.publisherHandle.plugin) {
                    const error = 'No publisher handle available';
                    this.error$.next(error);
                    reject(new Error(error));
                    return;
                }

                const tracks = [
                    { type: 'audio', capture: true, recv: false },
                    {
                        type: 'screen',
                        capture: {
                            audio: true,
                            video: true,
                        },
                        recv: false,
                    },
                ];

                this.publisherHandle.plugin.createOffer({
                    tracks: tracks,
                    update: true,
                    success: (jsep: any) => {
                        const publish = {
                            request: 'configure',
                            audio: true,
                            video: true,
                        };

                        this.publisherHandle.plugin.send({
                            message: publish,
                            jsep: jsep,
                            success: () => {
                                this._screenShareActive = true;
                                this.publishing$.next(true);

                                if (this.publisherHandle?.plugin?.webrtcStuff?.myStream) {
                                    this.publisherHandle.stream = this.publisherHandle.plugin.webrtcStuff.myStream;
                                }

                                this.dispatchEvent({
                                    type: 'screenShareStarted',
                                    detail: { success: true },
                                });

                                resolve();
                            },
                            error: (error: any) => {
                                console.error('Error publishing screen:', error);
                                reject(new Error('Failed to publish screen: ' + error));
                            },
                        });
                    },
                    error: (error: any) => {
                        console.error('Error creating screen share offer:', error);
                        reject(new Error('Error creating offer: ' + error));
                    },
                });
            });

            if (this.publisherHandle?.stream) {
                const videoTracks = this.publisherHandle.stream.getVideoTracks();
                if (videoTracks && videoTracks.length > 0) {
                    videoTracks[0].addEventListener('ended', () => {
                        this.stopScreenShare().catch((e) => console.error('Error stopping screen share:', e));
                    });
                }
            }
        } catch (error) {
            console.error('Failed to start screen sharing:', error);

            if (error.name === 'NotAllowedError' || (error.message && error.message.includes('denied'))) {
                this.dispatchEvent({
                    type: 'screenShareStarted',
                    detail: { success: false, reason: 'permission-denied' },
                });
            } else {
                this.dispatchEvent({
                    type: 'screenShareStarted',
                    detail: { success: false, reason: 'technical-error' },
                });
            }

            this._screenShareActive = false;
            throw error;
        }
    }

    public async stopScreenShare(): Promise<void> {
        if (!this._screenShareActive) {
            return;
        }

        try {
            const isAudioActive = true;

            if (this.publisherHandle?.stream) {
                this.publisherHandle.stream.getVideoTracks().forEach((track) => {
                    track.stop();
                });
            }

            await new Promise<void>((resolve, reject) => {
                if (!this.publisherHandle || !this.publisherHandle.plugin) {
                    reject(new Error('No publisher handle'));
                    return;
                }

                const tracks = [{ type: 'audio', capture: isAudioActive, recv: false }];

                this.publisherHandle.plugin.createOffer({
                    tracks: tracks,
                    update: true,
                    success: (jsep: any) => {
                        const configure = {
                            request: 'configure',
                            audio: isAudioActive,
                            video: false,
                        };

                        this.publisherHandle.plugin.send({
                            message: configure,
                            jsep: jsep,
                            success: () => {
                                this._screenShareActive = false;

                                this.dispatchEvent({
                                    type: 'screenShareStopped',
                                    detail: { success: true },
                                });

                                resolve();
                            },
                            error: (error: any) => {
                                console.error('Error configuring audio-only:', error);
                                reject(error);
                            },
                        });
                    },
                    error: (error: any) => {
                        console.error('Error creating audio-only offer:', error);
                        reject(error);
                    },
                });
            });
        } catch (error) {
            console.error('Error stopping screen sharing:', error);

            try {
                const configure = {
                    request: 'configure',
                    audio: true,
                    video: false,
                };

                await new Promise<void>((resolve) => {
                    this.publisherHandle?.plugin.send({
                        message: configure,
                        success: () => {
                            resolve();
                        },
                        error: () => {
                            console.warn('Fallback: Error configuring audio-only');
                            resolve();
                        },
                    });
                });

                this._screenShareActive = false;

                this.dispatchEvent({
                    type: 'screenShareStopped',
                    detail: { success: true, withError: true },
                });
            } catch (retryError) {
                console.error('Retry failed:', retryError);
                this.dispatchEvent({
                    type: 'screenShareStopped',
                    detail: { success: false },
                });
                throw error;
            }
        }
    }

    public async leaveRoom(): Promise<void> {
        const currentRoomId = this.roomId;
        const currentPublisherHandleId = this.publisherHandle?.handleId;

        const dispatchLeaveEvent = () => {
            this.dispatchEvent({
                type: 'roomLeft',
                detail: {
                    previousRoomId: currentRoomId,
                    feedId: currentPublisherHandleId,
                },
            });
        };

        this.userMapping.clear();

        const leavePromises: Promise<void>[] = [];

        if (this.publisherHandle) {
            leavePromises.push(
                new Promise<void>((resolve) => {
                    const leave = { request: 'leave' };
                    try {
                        this.publisherHandle?.plugin.send({
                            message: leave,
                            success: () => {
                                console.log('Successfully sent leave request (publisher)');
                                resolve();
                            },
                            error: (e: any) => {
                                console.error('Error sending leave request (publisher):', e);
                                resolve();
                            },
                        });
                    } catch (e) {
                        console.error('Exception sending leave request (publisher):', e);
                        resolve();
                    }
                }),
            );

            leavePromises.push(
                new Promise<void>((resolve) => {
                    try {
                        this.publisherHandle?.plugin.detach({
                            success: () => {
                                console.log('Successfully detached publisher plugin');
                                resolve();
                            },
                            error: (e: any) => {
                                console.error('Error detaching publisher plugin:', e);
                                resolve();
                            },
                        });
                    } catch (e) {
                        console.error('Exception detaching publisher plugin:', e);
                        resolve();
                    }
                }),
            );
        }

        for (const [feedId, handle] of this.subscriberHandles.entries()) {
            leavePromises.push(
                new Promise<void>((resolve) => {
                    try {
                        handle.plugin.detach({
                            success: () => {
                                console.log(`Successfully detached subscriber plugin for feed ${feedId}`);
                                resolve();
                            },
                            error: (e: any) => {
                                console.error(`Error detaching subscriber plugin for feed ${feedId}:`, e);
                                resolve();
                            },
                        });
                    } catch (e) {
                        console.error(`Exception detaching subscriber plugin for feed ${feedId}:`, e);
                        resolve();
                    }
                }),
            );
        }

        this.publisherHandle = null;
        this.subscriberHandles.clear();
        this.publishing$.next(false);

        try {
            await Promise.all(leavePromises);
        } catch (e) {
            console.error('Error during room leave cleanup:', e);
        } finally {
            const oldRoomId = this.roomId;
            this.roomId = null;
            this.events.connectionState.next('disconnected');
            dispatchLeaveEvent();
        }
    }

    public disconnect(): void {
        if (this.connection) {
            try {
                this.connection.destroy({
                    success: () => {},
                    error: (e: any) => {
                        console.error('Error destroying Janus connection:', e);
                    },
                });
            } catch (e) {
                console.error('Exception destroying Janus connection:', e);
            }

            this.connection = null;
            this.connected$.next(false);
            this.publishing$.next(false);
            this.events.connectionState.next('disconnected');
        }
    }

    public async getUserDevices(): Promise<MediaDeviceInfo[]> {
        try {
            return await navigator.mediaDevices.enumerateDevices();
        } catch (error) {
            console.error('Error enumerating media devices:', error);
            this.error$.next('Failed to get media devices');
            return [];
        }
    }

    public isConnected(): boolean {
        return this.connected$.value;
    }

    public isPublishing(): boolean {
        return this.publishing$.value;
    }

    public getCurrentRoom(): number | null {
        return this.roomId;
    }
}
