import React, { useEffect, useState, useRef, useCallback } from 'react';
import { container } from 'tsyringe';
import { JanusClient } from './rx/JanusClient';
import { PublisherAudioPlayer } from './PublisherAudioPlayer';

interface MediaInfo {
    mid: string;
    stream: MediaStream;
    uniqueId: string;
}

interface StreamData {
    audio?: MediaInfo;
    video?: MediaInfo;
    feedId: string | number;
    userId?: number;
    username?: string;
    displayName?: string;
    isScreenSharing?: boolean;
    isLocalStream?: boolean;
    isTalking?: boolean;
    lastActive: number;
}

interface ScreenShareInfo {
    active: boolean;
    feedId: string | number | null;
}

interface UserInfo {
    displayName?: string;
    userId?: number;
}

interface DebugInfo {
    publishers: number;
    subscriberHandles: number;
    roomId?: number | null;
    events: string[];
    streamCount?: number;
}

interface JanusDisplayProps {
    className?: string;
}

const LOG_CONFIG = {
    CONNECTION: false,
    STREAMS: false,
    TRACKS: false,
    SCREEN_SHARE: true,
    USER_ACTIVITY: false,
    ROOM_EVENTS: true,
    VIDEO_PLAYER: false,
};

const logInfo = (category, message, ...args) => {
    if (LOG_CONFIG[category]) {
        console.log(`[${category}] ${message}`, ...args);
    }
};

const logError = (category, message, ...args) => {
    console.error(`[${category}] ${message}`, ...args);
};

const logWarn = (category, message, ...args) => {
    console.warn(`[${category}] ${message}`, ...args);
};

export const JanusDisplay: React.FC<JanusDisplayProps> = ({ className = '' }) => {
    const janusClient = container.resolve(JanusClient);
    const [streams, setStreams] = useState<Record<string, StreamData>>({});
    const [screenShareInfo, setScreenShareInfo] = useState<ScreenShareInfo>({
        active: false,
        feedId: null,
    });
    const [localScreenStream, setLocalScreenStream] = useState<MediaStream | null>(null);
    const [connectionStatus, setConnectionStatus] = useState<'initializing' | 'connected' | 'disconnected'>(
        'initializing',
    );
    const [debugInfo, setDebugInfo] = useState<DebugInfo>({
        publishers: 0,
        subscriberHandles: 0,
        events: [],
    });
    const [userInfoMap, setUserInfoMap] = useState<Map<string, UserInfo>>(new Map());
    const [localUserTalking, setLocalUserTalking] = useState(false);

    const streamsRef = useRef<Record<string, StreamData>>({});
    const userInfoMapRef = useRef<Map<string, UserInfo>>(new Map());
    const currentRoomRef = useRef<number | null>(janusClient.getCurrentRoom());
    const instanceIdRef = useRef(`janus-instance-${Date.now()}`);
    const cleanupIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const initializedRef = useRef(false);

    useEffect(() => {
        streamsRef.current = streams;
    }, [streams]);

    useEffect(() => {
        userInfoMapRef.current = userInfoMap;
    }, [userInfoMap]);

    const cleanupInactiveStreams = useCallback(() => {
        const now = Date.now();
        const INACTIVE_THRESHOLD = 5000;

        setStreams((prev) => {
            const updated = { ...prev };
            let hasChanges = false;

            Object.entries(updated).forEach(([key, userData]) => {
                if (userData.isLocalStream) return;

                if (!userData.lastActive || now - userData.lastActive > INACTIVE_THRESHOLD) {
                    logInfo('STREAMS', `Usuwanie nieaktywnego użytkownika: ${userData.displayName || key}`);
                    delete updated[key];
                    hasChanges = true;
                }
            });

            return hasChanges ? updated : prev;
        });
    }, []);

    const forceRoomSync = useCallback(async () => {
        if (!janusClient.isConnected() || !janusClient.getCurrentRoom()) {
            logInfo('ROOM_EVENTS', 'Client not connected or not in a room, cannot sync');
            return;
        }

        try {
            const roomId = janusClient.getCurrentRoom();
            logInfo('ROOM_EVENTS', `JanusDisplay: Forcing sync for room ${roomId}`);

            currentRoomRef.current = roomId;
            cleanupInactiveStreams();

            const localUserId = janusClient.resources?.items?.user?.id || 'local-user';
            const localStreamKey = `user-${localUserId}`;

            setStreams((prev) => {
                const updated = { ...prev };
                let localCount = 0;

                Object.entries(updated).forEach(([key, userData]) => {
                    if (userData.isLocalStream) {
                        localCount++;
                        if (key !== localStreamKey && localCount > 1) {
                            delete updated[key];
                            logInfo('STREAMS', `Usunięto duplikat lokalnego strumienia: ${key}`);
                        }
                    }
                });

                return localCount > 1 ? updated : prev;
            });
        } catch (err) {
            logError('ROOM_EVENTS', 'Error forcing room sync:', err);
        }
    }, [janusClient, cleanupInactiveStreams]);

    const setupCleanupInterval = useCallback(() => {
        if (cleanupIntervalRef.current) {
            clearInterval(cleanupIntervalRef.current);
        }

        cleanupIntervalRef.current = setInterval(() => {
            if (janusClient.isConnected() && janusClient.getCurrentRoom()) {
                setStreams((prev) => {
                    const updated = { ...prev };
                    let hasChanges = false;

                    Object.entries(updated).forEach(([key, userData]) => {
                        if (userData.isLocalStream) return;

                        const feedIdStr = userData.feedId.toString();
                        if (!janusClient.userMapping.has(feedIdStr)) {
                            logInfo(
                                'STREAMS',
                                `Usuwanie użytkownika, którego nie ma w userMapping: ${userData.displayName || key}`,
                            );
                            delete updated[key];
                            hasChanges = true;
                        }
                    });

                    return hasChanges ? updated : prev;
                });
            }
        }, 5000);

        return () => {
            if (cleanupIntervalRef.current) {
                clearInterval(cleanupIntervalRef.current);
                cleanupIntervalRef.current = null;
            }
        };
    }, [janusClient]);

    const setupEventHandlers = useCallback(() => {
        const onPublisherAdded = (event) => {
            const { mid, stream, kind, feedId, displayName, userId } = event.detail;

            logInfo('STREAMS', `Publisher added: feedId=${feedId}, kind=${kind}, mid=${mid}`);
            setDebugInfo((prev) => ({
                ...prev,
                events: [...prev.events.slice(-9), `Publisher added: ${displayName || feedId}, kind=${kind}`],
                publishers: prev.publishers + 1,
            }));

            if (!stream) {
                logError('STREAMS', 'Received publisher added event with no stream');
                return;
            }

            const userInfo = userInfoMapRef.current.get(feedId.toString()) || {};
            const finalDisplayName =
                displayName || userInfo.displayName || janusClient.userMapping?.get(feedId.toString())?.displayName;

            const finalUserId = userId || userInfo.userId || janusClient.userMapping?.get(feedId.toString())?.userId;

            const uniqueId = `${finalDisplayName}-${mid}`;
            const streamKey = finalUserId ? `user-${finalUserId}` : `feed-${feedId}`;

            const hasTracks =
                kind === 'audio' ? stream.getAudioTracks().length > 0 : stream.getVideoTracks().length > 0;

            if (!hasTracks) {
                logWarn('STREAMS', `Stream for ${kind} has no ${kind} tracks`);
            }

            setStreams((prev) => {
                const existingUser = prev[streamKey];

                if (existingUser) {
                    return {
                        ...prev,
                        [streamKey]: {
                            ...existingUser,
                            [kind]: {
                                mid,
                                stream,
                                uniqueId,
                            },
                            displayName: finalDisplayName,
                            userId: finalUserId,
                            lastActive: Date.now(),
                        },
                    };
                } else {
                    return {
                        ...prev,
                        [streamKey]: {
                            feedId,
                            userId: finalUserId,
                            username: finalDisplayName,
                            displayName: finalDisplayName,
                            isTalking: false,
                            lastActive: Date.now(),
                            [kind]: {
                                mid,
                                stream,
                                uniqueId,
                            },
                        },
                    };
                }
            });

            setUserInfoMap((prev) => {
                const newMap = new Map(prev);
                newMap.set(feedId.toString(), {
                    displayName: finalDisplayName,
                    userId: finalUserId,
                });
                return newMap;
            });
        };

        const onPublisherRemoved = (event) => {
            const { mid, feedId, userId, displayName } = event.detail;
            logInfo('STREAMS', 'Publisher removed:', { mid, feedId, displayName });

            setDebugInfo((prev) => ({
                ...prev,
                events: [...prev.events.slice(-9), `Publisher removed: ${displayName || feedId}`],
                publishers: Math.max(0, prev.publishers - 1),
            }));

            setStreams((prev) => {
                const updated = { ...prev };
                let removedAny = false;

                Object.entries(updated).forEach(([key, userData]) => {
                    if (userData.isLocalStream) return;

                    const matchesFeedId = userData.feedId.toString() === feedId.toString();
                    const matchesUserId = userId && userData.userId === userId;
                    const matchesDisplayName = displayName && userData.displayName === displayName;

                    if (matchesFeedId || matchesUserId || matchesDisplayName) {
                        delete updated[key];
                        removedAny = true;
                    }
                });

                return removedAny ? updated : prev;
            });
        };

        const onRoomLeft = (event) => {
            const { previousRoomId } = event.detail;
            logInfo('ROOM_EVENTS', `Room left event received: previous room ${previousRoomId}`);

            setStreams((prev) => {
                const updated = {};
                Object.entries(prev).forEach(([key, userData]) => {
                    if (userData.isLocalStream) {
                        updated[key] = userData;
                    }
                });
                return updated;
            });

            setDebugInfo((prev) => ({
                ...prev,
                events: [...prev.events.slice(-9), `Left room ${previousRoomId}`],
                publishers: 0,
                subscriberHandles: 0,
            }));

            setUserInfoMap(new Map());
        };

        const onPublisherInfo = (event) => {
            const { feedId, displayName, userId } = event.detail;
            logInfo('STREAMS', `Publisher info: feedId=${feedId}, displayName=${displayName || 'unknown'}`);

            setDebugInfo((prev) => ({
                ...prev,
                events: [...prev.events.slice(-9), `Publisher info: ${displayName || feedId}`],
            }));

            setUserInfoMap((prev) => {
                const newMap = new Map(prev);
                newMap.set(feedId.toString(), {
                    displayName,
                    userId,
                });
                return newMap;
            });

            setStreams((prev) => {
                const updatedStreams = { ...prev };
                let updated = false;

                Object.entries(updatedStreams).forEach(([key, userData]) => {
                    if (userData.feedId.toString() === feedId.toString()) {
                        updatedStreams[key] = {
                            ...userData,
                            displayName: displayName || userData.displayName,
                            userId: userId || userData.userId,
                            lastActive: Date.now(),
                        };
                        updated = true;
                    }
                });

                return updated ? updatedStreams : prev;
            });
        };

        const onUserSpeaking = (event) => {
            const { feedId, talking, displayName } = event.detail;
            logInfo('USER_ACTIVITY', `User ${displayName || feedId} ${talking ? 'talking' : 'silent'}`);

            setStreams((prev) => {
                const updatedStreams = { ...prev };
                let updated = false;

                const userId = event.detail.userId;
                if (userId) {
                    const streamKey = `user-${userId}`;
                    if (updatedStreams[streamKey]) {
                        updatedStreams[streamKey].isTalking = talking;
                        updatedStreams[streamKey].lastActive = Date.now();
                        updated = true;
                    }
                }

                if (!updated) {
                    Object.entries(updatedStreams).forEach(([key, userData]) => {
                        if (userData.feedId.toString() === feedId.toString()) {
                            updatedStreams[key].isTalking = talking;
                            updatedStreams[key].lastActive = Date.now();
                            updated = true;
                        }
                    });
                }

                if (!updated && displayName) {
                    Object.entries(updatedStreams).forEach(([key, userData]) => {
                        if (userData.displayName === displayName) {
                            updatedStreams[key].isTalking = talking;
                            updatedStreams[key].lastActive = Date.now();
                            updated = true;
                        }
                    });
                }

                return updated ? updatedStreams : prev;
            });
        };

        const onScreenShareStarted = (event) => {
            logInfo('SCREEN_SHARE', 'Screen share started:', event.detail);
            if (event.detail.success) {
                setDebugInfo((prev) => ({
                    ...prev,
                    events: [...prev.events.slice(-9), `Local screen share started`],
                }));

                setStreams((prev) => {
                    const localUserId = janusClient.resources?.items?.user?.id || 'local-user';
                    const streamKey = `user-${localUserId}`;

                    if (prev[streamKey]) {
                        return {
                            ...prev,
                            [streamKey]: {
                                ...prev[streamKey],
                                isScreenSharing: true,
                                lastActive: Date.now(),
                            },
                        };
                    }
                    return prev;
                });
            }
        };

        const onScreenShareStopped = (event) => {
            logInfo('SCREEN_SHARE', 'Screen share stopped:', event.detail);
            const { feedId } = event.detail;

            setDebugInfo((prev) => ({
                ...prev,
                events: [...prev.events.slice(-9), `Screen share stopped for feed ${feedId}`],
            }));

            setTimeout(() => {
                const videoElements = document.querySelectorAll('video');
                videoElements.forEach((video) => {
                    if (video.srcObject instanceof MediaStream) {
                        const videoTracks = video.srcObject.getVideoTracks();

                        if (videoTracks.some((track) => track.readyState === 'ended' || !track.enabled)) {
                            logInfo('SCREEN_SHARE', 'Cleaning up inactive video element', video.id);

                            try {
                                const allTracks = video.srcObject.getTracks();
                                allTracks.forEach((track) => track.stop());
                            } catch (e) {
                                console.warn('Error stopping media tracks:', e);
                            }

                            video.srcObject = null;
                            video.pause();

                            if (video.parentElement) {
                                try {
                                    if (
                                        video.parentElement.classList.contains('video-container') ||
                                        video.parentElement.classList.contains('video-wrapper')
                                    ) {
                                        video.parentElement.removeChild(video);
                                    }
                                } catch (e) {
                                    console.warn('Error removing video element:', e);
                                }
                            }
                        }
                    }
                });
            }, 500);

            setStreams((prev) => {
                const localUserId = janusClient.resources?.items?.user?.id || 'local-user';
                const streamKey = `user-${localUserId}`;
                const updatedStreams = { ...prev };

                if (updatedStreams[streamKey]) {
                    updatedStreams[streamKey] = {
                        ...updatedStreams[streamKey],
                        isScreenSharing: false,
                        lastActive: Date.now(),
                    };
                }

                Object.entries(updatedStreams).forEach(([key, userData]) => {
                    if (userData.feedId && userData.feedId.toString() === feedId?.toString()) {
                        updatedStreams[key] = {
                            ...userData,
                            isScreenSharing: false,
                            lastActive: Date.now(),
                        };
                    }
                });

                return updatedStreams;
            });

            setLocalScreenStream(null);
            setScreenShareInfo({
                active: false,
                feedId: null,
            });
        };

        const onConnectionChange = (state) => {
            logInfo('CONNECTION', `Connection state changed to: ${state}`);
            setConnectionStatus(state);

            if (state === 'disconnected') {
                setStreams({});
                setUserInfoMap(new Map());
            }
        };

        const onLocalTrack = (trackInfo) => {
            const { track, type, stream } = trackInfo;
            logInfo('TRACKS', `Local ${type} track added`, track?.id || 'unknown');

            const localUserId = janusClient.resources?.items?.user?.id || 'local-user';
            const localDisplayName = 'Ty';
            const uniqueId = `local-${type}-${Date.now()}`;
            const isScreenSharing = janusClient._screenShareActive;

            setStreams((prev) => {
                const streamKey = `user-${localUserId}`;

                if (prev[streamKey]) {
                    return {
                        ...prev,
                        [streamKey]: {
                            ...prev[streamKey],
                            [type]: {
                                mid: uniqueId,
                                stream,
                                uniqueId,
                            },
                            isLocalStream: true,
                            isScreenSharing: isScreenSharing,
                            isTalking: localUserTalking,
                            lastActive: Date.now(),
                        },
                    };
                } else {
                    return {
                        ...prev,
                        [streamKey]: {
                            feedId: janusClient.publisherHandle?.handleId || 'local',
                            userId: localUserId,
                            username: localDisplayName,
                            displayName: localDisplayName,
                            isLocalStream: true,
                            isScreenSharing: isScreenSharing,
                            isTalking: localUserTalking,
                            lastActive: Date.now(),
                            [type]: {
                                mid: uniqueId,
                                stream,
                                uniqueId,
                            },
                        },
                    };
                }
            });

            if (isScreenSharing && type === 'video') {
                setLocalScreenStream(stream);
                setScreenShareInfo({
                    active: true,
                    feedId: janusClient.publisherHandle?.handleId || 'local',
                });
            }
        };

        const onSpeaking = (speaking) => {
            logInfo('USER_ACTIVITY', `Local user ${speaking ? 'started' : 'stopped'} speaking`);
            setLocalUserTalking(speaking);

            setStreams((prev) => {
                const localUserId = janusClient.resources?.items?.user?.id || 'local-user';
                const streamKey = `user-${localUserId}`;

                if (prev[streamKey]) {
                    return {
                        ...prev,
                        [streamKey]: {
                            ...prev[streamKey],
                            isTalking: speaking,
                            lastActive: Date.now(),
                        },
                    };
                }
                return prev;
            });
        };

        janusClient.addEventListener('publisherAdded', onPublisherAdded);
        janusClient.addEventListener('publisherRemoved', onPublisherRemoved);
        janusClient.addEventListener('publisherLeft', onPublisherRemoved);
        janusClient.addEventListener('publisherInfo', onPublisherInfo);
        janusClient.addEventListener('userSpeaking', onUserSpeaking);
        janusClient.addEventListener('roomLeft', onRoomLeft);
        janusClient.addEventListener('screenShareStarted', onScreenShareStarted);
        janusClient.addEventListener('screenShareStopped', onScreenShareStopped);

        janusClient.events.connectionState.subscribe(onConnectionChange);
        janusClient.events.localTracks.subscribe(onLocalTrack);
        janusClient.events.speaking.subscribe(onSpeaking);

        setConnectionStatus(janusClient.isConnected() ? 'connected' : 'disconnected');

        if (janusClient.userMapping && janusClient.userMapping.size > 0) {
            setUserInfoMap(new Map(janusClient.userMapping));
        }

        if (janusClient.isConnected() && janusClient.publisherHandle) {
            const localUserId = janusClient.resources?.items?.user?.id || 'local-user';
            const localDisplayName = 'Ty';
            const streamKey = `user-${localUserId}`;

            setStreams((prev) => {
                if (!prev[streamKey]) {
                    return {
                        ...prev,
                        [streamKey]: {
                            feedId: janusClient.publisherHandle.handleId || 'local',
                            userId: localUserId,
                            username: localDisplayName,
                            displayName: localDisplayName,
                            isLocalStream: true,
                            isTalking: false,
                            isScreenSharing: janusClient._screenShareActive,
                            lastActive: Date.now(),
                        },
                    };
                }
                return prev;
            });
        }

        return () => {
            janusClient.removeEventListener('publisherAdded', onPublisherAdded);
            janusClient.removeEventListener('publisherRemoved', onPublisherRemoved);
            janusClient.removeEventListener('publisherLeft', onPublisherRemoved);
            janusClient.removeEventListener('publisherInfo', onPublisherInfo);
            janusClient.removeEventListener('userSpeaking', onUserSpeaking);
            janusClient.removeEventListener('roomLeft', onRoomLeft);
            janusClient.removeEventListener('screenShareStarted', onScreenShareStarted);
            janusClient.removeEventListener('screenShareStopped', onScreenShareStopped);

            try {
                janusClient.events.connectionState.unsubscribe();
                janusClient.events.localTracks.unsubscribe();
                janusClient.events.speaking.unsubscribe();
            } catch (e) {
                logWarn('CONNECTION', 'Error unsubscribing from events:', e);
            }
        };
    }, [janusClient, localUserTalking]);

    useEffect(() => {
        if (initializedRef.current) return;
        initializedRef.current = true;

        forceRoomSync();
        const cleanupFn = setupCleanupInterval();
        const removeListeners = setupEventHandlers();

        return () => {
            cleanupFn();
            removeListeners();
        };
    }, [forceRoomSync, setupCleanupInterval, setupEventHandlers]);

    const streamEntries = Object.entries(streams);

    return (
        <div id="janus-circular-display" className={`janus-display ${className} status-${connectionStatus}`}>
            <div
                className="janus-debug-info"
                style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    background: 'rgba(0,0,0,0.7)',
                    color: 'white',
                    padding: '10px',
                    zIndex: 1000,
                    maxWidth: '300px',
                    fontSize: '12px',
                    pointerEvents: 'none',
                }}
            >
                Janus Room: {janusClient.getCurrentRoom() || 'None'}
                <br />
                Connected: {janusClient.isConnected() ? 'Yes' : 'No'}
                <br />
                Streams: {Object.keys(streams).length}
                <br />
                Publishers: {Object.values(streams).filter((s) => !s.isLocalStream).length}
                <br />
                Recent Events:
                <br />
                <ul style={{ margin: '0', paddingLeft: '20px' }}>
                    {debugInfo.events.slice(-5).map((event, i) => (
                        <li key={i}>{event}</li>
                    ))}
                </ul>
            </div>

            <div className="remote-publishers">
                {streamEntries.map(([key, userData]) => (
                    <div
                        key={userData.userId ? `user-${userData.userId}` : userData.feedId.toString()}
                        className={`user-stream-container ${userData.isScreenSharing ? 'screen-sharing' : ''} ${
                            userData.isLocalStream ? 'local-stream' : ''
                        } ${userData.isTalking ? 'talking' : ''}`}
                        data-user-id={userData.userId}
                        data-username={userData.username}
                        data-feed-id={userData.feedId}
                        data-talking={userData.isTalking ? 'true' : 'false'}
                        data-local={userData.isLocalStream ? 'true' : 'false'}
                    >
                        <div className="publisher-avatar">
                            {userData.audio && (
                                <div
                                    className={`audio-container ${userData.isTalking ? 'speaking' : ''}`}
                                    style={{ display: userData.video ? 'none' : 'block' }}
                                >
                                    <PublisherAudioPlayer
                                        stream={userData.audio.stream}
                                        username={userData.username}
                                        displayName={userData.displayName}
                                        isLocal={userData.isLocalStream || false}
                                    />
                                </div>
                            )}
                            {/* Renderuj wideo, jeśli jest dostępne */}
                            {userData.video ? (
                                <div className="video-container" style={{ borderRadius: '50%' }}>
                                    <VideoPlayer
                                        stream={userData.video.stream}
                                        uniqueId={userData.video.uniqueId}
                                        isLocal={userData.isLocalStream || false}
                                    />
                                </div>
                            ) : !userData.audio ? (
                                <div className="avatar-placeholder"></div>
                            ) : null}{' '}
                        </div>

                        <div className="user-name">
                            {userData.isLocalStream ? 'Ty' : userData.displayName || userData.username || 'Nieznany'}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

interface VideoPlayerProps {
    stream: MediaStream;
    uniqueId: string;
    isLocal: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ stream, uniqueId, isLocal }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [error, setError] = useState<string | null>(null);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!videoRef.current || !stream) return;

        if (videoRef.current.srcObject) {
            try {
                const oldStream = videoRef.current.srcObject as MediaStream;
                oldStream.getTracks().forEach((track) => {
                    try {
                        track.stop();
                    } catch (e) {
                        console.warn('Error stopping track:', e);
                    }
                });
                videoRef.current.srcObject = null;
            } catch (e) {
                logWarn('VIDEO_PLAYER', 'Error cleaning up old stream:', e);
            }
        }

        try {
            videoRef.current.srcObject = stream;

            const isScreenShare = stream
                .getVideoTracks()
                .some(
                    (track) =>
                        track.label &&
                        (track.label.toLowerCase().includes('screen') || track.label.toLowerCase().includes('display')),
                );

            if (isScreenShare) {
                videoRef.current.classList.add('janus-screen-share');
            }

            videoRef.current
                .play()
                .then(() => {
                    logInfo('VIDEO_PLAYER', `Video ${uniqueId} playing successfully`);
                    setLoaded(true);
                    setError(null);
                })
                .catch((err) => {
                    logError('VIDEO_PLAYER', `Error playing video ${uniqueId}:`, err);
                    setError(err.message);

                    if (err.name === 'NotAllowedError') {
                        videoRef.current!.muted = true;
                        videoRef
                            .current!.play()
                            .then(() => {
                                logInfo('VIDEO_PLAYER', `Video ${uniqueId} playing with mute workaround`);
                                setLoaded(true);
                            })
                            .catch((e) => logError('VIDEO_PLAYER', 'Still failed after mute:', e));
                    }
                });
        } catch (e: any) {
            logError('VIDEO_PLAYER', `Error setting video source for ${uniqueId}:`, e);
            setError(e.message);
        }

        return () => {
            try {
                if (videoRef.current?.srcObject) {
                    const oldStream = videoRef.current.srcObject as MediaStream;
                    oldStream.getTracks().forEach((track) => {
                        try {
                            track.stop();
                        } catch (e) {
                            console.warn('Error stopping track on cleanup:', e);
                        }
                    });
                    videoRef.current.srcObject = null;
                    videoRef.current.pause();
                }
            } catch (e) {
                logWarn('VIDEO_PLAYER', 'Error in cleanup:', e);
            }
        };
    }, [stream, uniqueId]);

    useEffect(() => {
        if (!stream) return;

        const handleTrackEnded = () => {
            logInfo('VIDEO_PLAYER', `A track in video ${uniqueId} has ended`);
            if (videoRef.current) {
                try {
                    videoRef.current.classList.add('track-ended');
                    videoRef.current.dispatchEvent(new Event('ended'));
                } catch (e) {
                    console.warn('Error handling track ended:', e);
                }
            }
        };

        const tracks = stream.getTracks();
        tracks.forEach((track) => {
            track.addEventListener('ended', handleTrackEnded);
        });

        return () => {
            tracks.forEach((track) => {
                track.removeEventListener('ended', handleTrackEnded);
            });
        };
    }, [stream, uniqueId]);

    return (
        <div className="video-wrapper" style={{ width: '100%', height: '100%' }}>
            <video
                ref={videoRef}
                autoPlay
                playsInline
                muted={isLocal}
                id={`video-${uniqueId}`}
                className={`user-video ${isLocal ? 'local-video' : ''} ${loaded ? 'loaded' : 'loading'}`}
                data-uniqueid={uniqueId}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '50%',
                }}
            />
        </div>
    );
};

export default JanusDisplay;
