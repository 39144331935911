import {
    Color,
    DoubleSide,
    Mesh,
    MeshPhongMaterial,
    RingGeometry,
} from 'three';

export const pointer = () => {
    const geometry = new RingGeometry(0.17, 0.2, 32);
    const material = new MeshPhongMaterial({
        polygonOffset: true,
        polygonOffsetFactor: -1,
        color: new Color('white'),
        side: DoubleSide,
    });

    const ring = new Mesh(geometry, material);
    ring.rotation.x = Math.PI / 2;
    ring.position.y = -2.5;

    return ring;
};
