import React, {
    useState,
    useEffect,
    useRef,
    CanvasHTMLAttributes,
} from 'react';
import { FaCheck, FaTrashAlt } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';

const ImageToDrag = ({
    canvasWrapperRef,
    src,
    sketchboardsRef,
    slectedSketchboard,
    onSuccess,
    onCancel,
}) => {
    const [width, setWidth] = useState(400);
    const img = useRef(null);
    const [position, setPosition] = useState({
        x: window.screen.width / 2 - 200,
        y: window.screen.height / 2 - 150,
    });
    const isDragging = useRef(false);
    const [isSelected, setIsSelected] = useState(false);

    const dragStart = useRef({
        x: window.screen.width / 2 - 200,
        y: window.screen.height / 2 - 150,
    });

    const handler = (mouseDownEvent) => {
        const startWidth = width;
        const startPosition = {
            x: mouseDownEvent.pageX,
            y: mouseDownEvent.pageY,
        };

        const onMouseMove = (mouseMoveEvent) => {
            const newWidth =
                startWidth - startPosition.x + mouseMoveEvent.pageX;

            setWidth(newWidth);
        };

        function onMouseUp() {
            document.body.removeEventListener('mousemove', onMouseMove);
        }

        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onMouseUp, { once: true });
    };

    const handleMouseDown = (event) => {
        if (event.target.id !== 'draghandle') {
            setIsSelected(true);
            dragStart.current = {
                x: event.clientX - position.x,
                y: event.clientY - position.y,
            };
            isDragging.current = true;
        }
    };

    const handleMouseMove = (event) => {
        if (isDragging.current) {
            const newX = event.clientX - dragStart.current.x;
            const newY = event.clientY - dragStart.current.y;
            setPosition({ x: newX, y: newY });
        }
    };

    const handleMouseUp = () => {
        isDragging.current = false;
    };

    const handleDrawImage = (event) => {
        const canvasElements = sketchboardsRef.current as HTMLElement;
        const canvas = canvasElements.childNodes[
            slectedSketchboard
        ] as HTMLCanvasElement;

        if (!src && canvas) {
            return;
        }

        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        const offsetY = 110;
        const offsetX = 10;

        onSuccess({
            image: (img.current as HTMLImageElement).src,
            dx: position.x * scaleX - offsetX,
            dy: position.y * scaleY - offsetY,
            dWidth: width * scaleX,
            dHeight:
                img.current.naturalHeight *
                (width / img.current.naturalWidth) *
                scaleY,
        });
    };

    useEffect(() => {
        const canvas = canvasWrapperRef.current;

        if (canvas) {
            canvas.addEventListener('mousemove', handleMouseMove);
            canvas.addEventListener('pointerup', handleMouseUp);
            canvas.addEventListener('mouseleave', handleMouseUp);
        }

        return () => {
            if (canvas) {
                canvas.removeEventListener('mousemove', handleMouseMove);
                canvas.removeEventListener('pointerup', handleMouseUp);
                canvas.removeEventListener('mouseleave', handleMouseUp);
            }
        };
    }, []);

    if (src === null) {
        return;
    }

    const handleCancel = () => {
        onCancel();
    };

    return (
        <div
            className={`sketchboard-image ${isSelected ? 'selected' : ''}`}
            style={{
                position: 'absolute',
                left: `${position.x}px`,
                top: `${position.y}px`,
                width: `${width}px`,
                height: 'auto',
                cursor: isDragging.current ? 'grabbing' : 'grab',
            }}
            onMouseDown={handleMouseDown}
        >
            <div id="container" style={{ width: '100%', height: '100%' }}>
                <img
                    ref={img}
                    src={src}
                    alt="draggable"
                    style={{ width: '100%', height: 'auto' }}
                />
                <button
                    id="draghandle"
                    type="button"
                    onMouseDown={handler}
                ></button>
                <button className="place-image" onClick={handleDrawImage}>
                    <FaCheck />
                </button>

                <button className="delete-image" onClick={handleCancel}>
                    <FaTrashAlt />
                </button>
            </div>
        </div>
    );
};

export default ImageToDrag;
