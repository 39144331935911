import { of } from 'rxjs';
import { delay, first } from 'rxjs/operators';

declare let Janus: any;

export interface MediaState {
    audio: boolean;
    video: boolean;
}

type Handle = {
    /**
     * returns the unique handle identifier
     */
    getId(): string;

    /**
     * returns the unique package name of the attached plugin
     */
    getPlugin(): any;
    /**
     * sends a message(with or without a jsep to negotiate a PeerConnection) to the plugin
     * @param parameters
     */
    send(parameters: any): void;

    /**
     * asks the library to create a WebRTC compliant OFFER
     * @param callbacks
     */
    createOffer(callbacks): void;

    /**
     * asks the library to create a WebRTC compliant ANSWER
     * @param callbacks
     */
    createAnswer(callbacks): void;

    /**
     * asks the library to handle an incoming WebRTC compliant session description
     * @param callbacks
     */
    handleRemoteJsep(callbacks): void;

    /**
     * sends a DTMF tone on the PeerConnection
     * @param parameters
     */
    dtmf(parameters): void;

    /**
     * sends data through the Data Channel, if available
     * @param parameters
     */
    data(parameters): void;

    /**
     * gets a verbose description of the currently received stream bitrate
     */
    getBitrate(): any;

    /**
     * tells the library to close the PeerConnection; if the optional
     * sendRequest argument is set to true, then a hangup Janus API request is
     * sent to Janus as well(disabled by default, Janus can usually figure this
     * out via DTLS alerts and the like but it may be useful to enable it
     * sometimes)
     * @param sendRequestav
     */
    hangup(sendRequest): void;

    /**
     * detaches from the plugin and destroys the handle, tearing down the
     * related PeerConnection if it exists
     * @param parameters
     */
    detach(parameters?: any): void;

    /**
     * mutes audio
     */
    muteAudio(): void;

    /**
     * enables audio
     */
    unmuteAudio(): void;

    /**
     * mutes video
     */
    muteVideo(): void;

    /**
     * enables video
     */
    unmuteVideo(): void;

    /**
     * returns true when audio is muted, otherwise false
     */
    isAudioMuted(): boolean;

    /**
     * returns true when video is muted, otherwise false
     */
    isVideoMuted(): boolean;
};

/**
 * facade for janus video plugin
 */
export class SessionPlugin {
    private isChangingBitrate = false;

    private readonly peerType: string;

    public displayName: string;

    public stream: MediaStream;
    public display: string;

    /**
     * returns true when audio is muted, please note that it does it only for local peer
     */
    public get isAudioMuted(): boolean {
        return this.isLocalPeer && this.isReady && this.handle.isAudioMuted();
    }

    /**
     * returns true when video is muted, please note that it does it only for local peer
     */
    public get isVideoMuted(): boolean {
        return this.isLocalPeer && this.isReady && this.handle.isVideoMuted();
    }

    /**
     * returns true when plugin is local peer plugin
     */
    public get isLocalPeer() {
        return this.peerType === 'publisher';
    }

    /**
     * returns true when plugin is remote peer plugin
     */
    public get isRemotePeer() {
        return this.peerType === 'subscriber';
    }

    /**
     * determines if plugin is ready
     * plugin is ready if it has handle and assigned string
     */
    public get isReady(): boolean {
        return !!this.handle && !!this.stream;
    }

    /**
     * creates new instance of SessionPlugin class
     * @param handle plugin handle
     * @param name plugin friendly name. property name in session.feeds object
     * @param message request used to create plugin instance
     */
    public constructor(public handle: Handle, public name, message) {
        this.log(`create session plugin - ${name}`);
        this.displayName = message.display;
        this.peerType = message.ptype;
        message.close_pc = true;
    }

    public setDisplayName(username) {
        this.displayName = username;
    }

    public createOffer(offer) {
        this.log('Create offer');
        this.handle.createOffer(offer);
    }

    public createAnswer(answer) {
        this.handle.createAnswer(answer);
    }

    public handleRemoteJsep(jsep) {
        this.handle.handleRemoteJsep(jsep);
    }

    public send(message) {
        this.log(`sending message: ${message.message.request}`);
        this.handle.send(message);
    }

    public leave() {
        this.log('leaving room');
        this.send({ message: { request: 'unpublish' } });
        this.handle.detach();
    }

    public setMedia(state: MediaState) {
        const audioAction = {
            0: () => this.muteAudio(),
            1: () => this.unmuteAudio(),
        };
        const videoAction = {
            0: () => this.muteVideo(),
            1: () => this.unmuteVideo(),
        };

        audioAction[+state.audio]();
        videoAction[+state.video]();
    }

    public toggleAudio() {
        if (this.isAudioMuted) {
            this.unmuteAudio();
            return;
        }

        this.muteAudio();
    }

    public muteAudio() {
        this.log('mute audio');
        if (this.isLocalPeer) {
            this.handle.muteAudio();
        }
    }

    public unmuteAudio() {
        this.log('unmute audio');
        if (this.isLocalPeer) {
            this.handle.unmuteAudio();
        }
    }

    public toggleVideo() {
        if (this.isVideoMuted) {
            this.unmuteVideo();
            return;
        }

        this.muteVideo();
    }

    public muteVideo() {
        this.log('mute audio');
        if (this.isLocalPeer) {
            this.handle.muteVideo();
        }
    }

    public unmuteVideo() {
        this.log('unmute audio');
        if (this.isLocalPeer) {
            this.handle.unmuteVideo();
        }
    }

    // public reduceBitrateFor(seconds) {
    //     if (this.isChangingBitrate) {
    //         return;
    //     }
    //
    //     this.log(`reduce bitrate down ${(1 - constants.REDUCE_RATIO) * 100}%`);
    //     const bitrate = this.getNewBitrate(this.handle.getBitrate(), true);
    //     this.configureBitrate(bitrate);
    //
    //     this.isChangingBitrate = true;
    //     of(this.isChangingBitrate)
    //         .pipe(delay(seconds * 1000), first())
    //         .subscribe(() => {
    //             this.isChangingBitrate = false;
    //             this.increaseBitrate();
    //         });
    // }
    //
    // public increaseBitrate() {
    //     this.log(
    //         `increase bitrate up ${(constants.INCREASE_RATIO - 1) * 100}%`,
    //     );
    //     const bitrate = this.getNewBitrate(this.handle.getBitrate(), false);
    //     this.configureBitrate(bitrate);
    // }

    // private configureBitrate(bitrate: number) {
    //     const message = {
    //         ...messages.CONFIGURE_BITRATE,
    //         'video-bitrate-max': bitrate, // Reduce the bitrate
    //     };
    //     this.send({ message });
    // }
    //
    // private getNewBitrate(current: number, reduce: boolean) {
    //     const ratio = reduce
    //         ? constants.REDUCE_RATIO
    //         : constants.INCREASE_RATIO;
    //     const bitrate = current * ratio;
    //     if (bitrate < constants.MIN_BITRATE) {
    //         return constants.MIN_BITRATE;
    //     }
    //
    //     if (bitrate > constants.MAX_BITRATE) {
    //         return constants.MAX_BITRATE;
    //     }
    //
    //     return bitrate;
    // }

    private log(...params) {
        console.log(...params);
    }
}
